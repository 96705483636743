import React, { useEffect } from "react";
import {
  Button,
  Container,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
  Link as MUILink,
  Breadcrumbs,
} from "@mui/material";
import Page from "../../components/Page";
import { Link, useParams } from "react-router-dom";
import { ICity } from "../../core/types/locations.types";
import { selectCities } from "../../core/redux/features/locations/locationsSlice";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { fetchCities, fetchCitiesMore, toggleCityEnabled } from "../../core/redux/features/locations/locationThunks";
import { BASE_STATUS } from "../../core/types/shared.types";

export default function Cities() {
  const { countryCode, stateCode } = useParams();

  const [skip, setSkip] = React.useState(20);
  const dispatch = useAppDispatch();
  const cities = useAppSelector(selectCities);

  useEffect(() => {
    dispatch(fetchCities({ countryCode: countryCode as string, stateCode: stateCode as string }));
  }, [countryCode, stateCode, dispatch]);

  const handleToggleEnabled = (countryCode: string, stateCode: string, cityName: string, enabled: boolean) => {
    dispatch(toggleCityEnabled({ countryCode, stateCode, cityName, enabled }));
  };

  const handleLoadMore = () => {
    setSkip(skip + 20);
    dispatch(fetchCitiesMore({ countryCode: countryCode as string, stateCode: stateCode as string, skip, limit: 20 }));
  };

  return (
    <Page title="Manage Cities">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <MUILink component={Link} underline="hover" color="inherit" to="/dashboard/app">
              Dashboard
            </MUILink>
            <MUILink component={Link} underline="hover" color="inherit" to={"/dashboard/location"}>
              Locations
            </MUILink>
            <MUILink component={Link} underline="hover" color="inherit" to={`/dashboard/location/state/${countryCode}`}>
              {countryCode}
            </MUILink>
            <Typography color="text.primary">cities</Typography>
          </Breadcrumbs>
        </Stack>
        <Paper sx={{ backgroundColor: "#e0f2f1" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: "10px 20px" }}>
            <Typography variant="h4">
              Cities ({cities.data.length}/{cities.count})
            </Typography>
          </Stack>
          <Divider />
          <Stack mt={3}>
            {cities.data.map((city, index) => (
              <CityItem
                key={city._id}
                city={city}
                index={index}
                handleToggleEnabled={handleToggleEnabled}
                status={cities.status}
              />
            ))}
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ padding: "10px 20px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLoadMore}
              disabled={cities.data.length === cities.count}
            >
              load more
            </Button>
          </Stack>
        </Paper>
      </Container>
    </Page>
  );
}

export function CityItem(props: {
  city: ICity;
  index: number;
  status: BASE_STATUS;
  handleToggleEnabled: (countryCode: string, stateCode: string, cityName: string, enabled: boolean) => void;
}) {
  const { city, index, handleToggleEnabled } = props;

  return (
    <Stack
      key={city._id}
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      sx={{
        backgroundColor: index % 2 ? "#e0f2f1" : "#b2dfdb",
        width: "100%",
      }}
    >
      <Stack direction="row" alignItems={"center"} gap="20px" p={"2px 10px"}>
        <Typography variant="body2">{index + 1}</Typography>

        <Typography variant="body1">{city.name}</Typography>
      </Stack>
      <Stack direction="row" alignItems={"center"} gap="20px" p={"2px 10px"}>
        <Typography variant="body1">{city.stateCode}</Typography>
        <FormControlLabel
          disabled={props.status === "loading" ? true : false}
          control={
            <Switch
              checked={city.enabled}
              onChange={() => handleToggleEnabled(city.countryCode, city.stateCode, city.name, !city.enabled)}
            />
          }
          label={city.enabled ? "Disable" : "Enable"}
        />
      </Stack>
    </Stack>
  );
}
