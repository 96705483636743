import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useAppDispatch } from "../../../core/redux/hooks";
import {
  checkMfa,
  generateRegularOTP,
  generateTOTP,
  login,
  verifyRegularOTP,
} from "../../../core/redux/features/auth/authThunk";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [open, setOpen] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    otp: Yup.string(),
  });

  const defaultValues = {
    email: "",
    password: "",
    otp: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: {
    password: string;
    email: string;
    otp: string;
  }) => {
    if (data.otp) {
      await dispatch(
        verifyRegularOTP({ email: data.email, token: data.otp })
      ).then(async (res) => {
        if (res.payload.message === "Verification successful") {
          const res: any = await dispatch(
            login({ email: data.email, password: data.password })
          );
          if (
            res.meta.requestStatus === "fulfilled" &&
            res.payload.body.isStaff
          ) {
            navigate("/dashboard/app", { replace: true });
          }
        }
      });
    } else {
      await dispatch(checkMfa({ email: data.email })).then(async (res) => {
        if (res.payload.message === true) {
          await dispatch(generateRegularOTP({ email: data.email })).then(() =>
            setOpen(true)
          );
        } else {
          const res: any = await dispatch(
            login({ email: data.email, password: data.password })
          );
          if (
            res.meta.requestStatus === "fulfilled" &&
            res.payload.body.isStaff
          ) {
            navigate("/dashboard/app", { replace: true });
          }
        }
      });
    }

    // const res: any = await dispatch(login(data));
    // if (res.meta.requestStatus === "fulfilled" && res.payload.body.isStaff) {
    //   navigate("/dashboard/app", { replace: true });
    // }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} mb={2}>
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>

      {qrCode !== "" ? <img alt="QRCode" src={qrCode} /> : null}

      {open && <RHFTextField name="otp" label="OTP" />}
    </FormProvider>
  );
}
