/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";

type Props = {
  value: string;
  setValue: (data: any) => void;
};

const RichTextEditor = (props: Props) => {
  const editorRef: any = useRef();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, ClassicEditor }: any = editorRef.current || {};

  React.useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, //Added .CKEditor
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditorLoaded(true);
  }, []);

  return (
    <>
      {editorLoaded ? (
        <CKEditor
          editor={ClassicEditor}
          data={props.value}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            props.setValue(data);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RichTextEditor;
