/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Button,
  Rating,
} from "@mui/material";
import { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  CreateProvider,
  UpdateProvider,
} from "../../../core/redux/features/payment/paymentThunks";
import { useAppDispatch } from "../../../core/redux/hooks";
import {
  PaymentFormType,
  PaymentType,
} from "../../../core/types/payment.types";

interface PaymentFormProps extends Partial<PaymentType> {
  handleClose?: () => void;
}

const PaymentForm: React.FC<PaymentFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm<PaymentFormType>({ mode: "all" });

  useEffect(() => {
    setValue("availableCards", props?.availableCards || undefined);
    setValue("mobileApp", props?.mobileApp || undefined);
    setValue("trustPilotRating", props?.trustPilotRating || undefined);
    props?.availableCountriesUrl &&
      setValue("availableCountriesUrl", props?.availableCountriesUrl);
    setValue("bonus", props?.bonus || undefined);
    setValue("bonusUrl", props?.bonusUrl || undefined);
    setValue("clicks", props?.clicks || 0);
    setValue("feeUrl", props?.feeUrl || undefined);
    setValue("numberOfCountries", props?.numberOfCountries || undefined);
    setValue("numberOfCurrencies", props?.numberOfCurrencies || undefined);
    props?.position && setValue("position", props?.position);
    setValue("websiteUrl", props?.websiteUrl || "");
    setValue("description", props?.description || "");
    setValue("logo", props?.logo || "");
    setValue("title", props?.title || "");
    setValue("fee", props?.fee || "");
  }, [props]);

  const onSubmit: SubmitHandler<PaymentFormType> = (data) => {
    if (props.handleClose) {
      props.handleClose();
      dispatch(
        UpdateProvider({
          body: {
            ...data,
            position: data.position,
            clicks: Number(data.clicks),
            numberOfCurrencies: Number(data.numberOfCurrencies),
            numberOfCountries: Number(data.numberOfCountries),
          },
          id: String(props._id),
        })
      );
      reset();
    } else {
      dispatch(
        CreateProvider({
          ...data,
          providerType: "payment",
          position: Number(data.position),
          clicks: Number(data.clicks),
          numberOfCurrencies: Number(data.numberOfCurrencies),
          numberOfCountries: Number(data.numberOfCountries),
        })
      );
      reset();
    }
  };

  return (
    <Stack
      width="100%"
      component="form"
      spacing={2}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack width="100%" spacing={2} direction="row">
        <TextField
          size="small"
          label={
            errors.title?.message ? errors.title?.message : "Payment Title"
          }
          error={!!errors.title?.message}
          {...register("title", { required: "Title is reqired" })}
          fullWidth
        />
        <TextField
          size="small"
          label={
            errors.websiteUrl?.message
              ? errors.websiteUrl?.message
              : "Website Url"
          }
          error={!!errors.websiteUrl?.message}
          {...register("websiteUrl", { required: "Website Url is reqired" })}
          fullWidth
          type="url"
        />
      </Stack>

      <TextField
        size="small"
        label={errors.logo?.message ? errors.logo?.message : "Logo Url"}
        error={!!errors.logo?.message}
        {...register("logo", { required: "Logo Url is reqired" })}
        fullWidth
        type="url"
      />

      <Stack width="100%" spacing={2} direction="row">
        <TextField
          label={errors.fee?.message ? errors.fee?.message : "Fee"}
          error={!!errors.fee?.message}
          size="small"
          fullWidth
          {...register("fee", { required: "Fee is reqired" })}
        />
        <TextField
          size="small"
          label="Fee Url"
          {...register("feeUrl")}
          fullWidth
          type="url"
        />
      </Stack>

      <Stack width="100%" spacing={2} direction="row">
        <TextField
          size="small"
          label="Bonus"
          {...register("bonus")}
          fullWidth
        />
        <TextField
          size="small"
          label="Bonus Url"
          {...register("bonusUrl")}
          fullWidth
          type="url"
        />
      </Stack>

      <Stack width="100%" spacing={2} direction="row" alignItems="center">
        <TextField
          size="small"
          label="TrustPilot Rating"
          {...register("trustPilotRating.rating")}
          fullWidth
        />
        <Controller
          name="trustPilotRating.rating"
          control={control}
          render={({ field }) => (
            <Rating
              {...field}
              defaultValue={props?.trustPilotRating?.rating || 0}
              precision={0.1}
              color="red"
              size="large"
              readOnly
            />
          )}
        />

        <TextField
          size="small"
          label="TrustPilot Rating Url"
          {...register("trustPilotRating.url")}
          fullWidth
          type="url"
        />
      </Stack>

      <Stack width="100%" spacing={2} direction="row">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              {...register("mobileApp.exist")}
              defaultChecked={props?.mobileApp?.exist || false}
            />
          }
          label="Exist"
          labelPlacement="start"
        />
        <TextField
          size="small"
          label="GooglePlay URL"
          {...register("mobileApp.androidUrl")}
          type="url"
          fullWidth
        />
        <TextField
          size="small"
          label="AppStore URL"
          {...register("mobileApp.iosUrl")}
          type="url"
          fullWidth
        />
        <TextField
          size="small"
          label="AppGallery URL"
          {...register("mobileApp.huaWeiUrl")}
          type="url"
          fullWidth
        />
      </Stack>

      <Stack width="100%" spacing={2} direction="row">
        <TextField
          label="Available Countries Url"
          size="small"
          {...register("availableCountriesUrl")}
          fullWidth
          type="url"
        />
        <Stack direction="row">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                {...register("availableCards.maestro")}
                defaultChecked={props?.availableCards?.maestro || false}
              />
            }
            label="Maestro"
            labelPlacement="start"
            sx={{ width: "100%" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                {...register("availableCards.masterCard")}
                defaultChecked={props?.availableCards?.masterCard || false}
              />
            }
            label="MasterCard"
            labelPlacement="start"
            sx={{ width: "100%" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                {...register("availableCards.visa")}
                defaultChecked={props?.availableCards?.visa || false}
              />
            }
            label="Visa"
            labelPlacement="start"
            sx={{ width: "100%" }}
          />
        </Stack>
      </Stack>

      <Stack width="100%" spacing={2} direction="row">
        <TextField
          size="small"
          label="Position"
          {...register("position")}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          size="small"
          label="Clicks"
          {...register("clicks")}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          size="small"
          label="Number Of Currencies"
          {...register("numberOfCurrencies")}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          size="small"
          label="Number Of Countries"
          {...register("numberOfCountries")}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>

      <TextField
        label={
          errors.description?.message
            ? errors.description?.message
            : "Description"
        }
        error={!!errors.description?.message}
        {...register("description", { required: "Description is reqired" })}
        fullWidth
        multiline
        rows={6}
      />
      <Button variant="contained" sx={{ bgcolor: "#239f5e" }} type="submit">
        {props._id ? "Save" : "Create"}
      </Button>
    </Stack>
  );
};

export default PaymentForm;
