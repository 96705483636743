/* eslint-disable react-hooks/exhaustive-deps */
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { ChangeEvent, SetStateAction, useState } from "react";
import { Link, Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
} from "@mui/material";
// components
import Page from "../../components/Page";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import Iconify from "../../components/Iconify";
import SearchNotFound from "../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../sections/@dashboard/user";
// mock
import { fToNow } from "../../utils/formatTime";
import { useAppSelector } from "../../core/redux/hooks";
import {
  fetchAllUsers,
  selectAllUsers,
  selectUsersCount,
} from "../../core/redux/features/users/userSlice";
import { UserType } from "../../core/types/user.types";
import { Box } from "@mui/system";
import { useFetchMore } from "../../hooks/useFetchMore";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "country", label: "Country", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "lastLogin", label: "Last Visit", alignRight: false },
  { id: "createdAt", label: "Registered", alignRight: false },
  { id: "isVerrified", label: "Verified", alignRight: false },
  { id: "registered ", label: "registered", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(
  a: { [x: string]: number },
  b: { [x: string]: number },
  orderBy: string | number
) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: any[],
  comparator: { (a: any, b: any): number; (arg0: any, arg1: any): any },
  query: string
) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: number[], b: number[]) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el: any[]) => el[0]);
}

export default function User() {
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState<string[]>([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const users = useAppSelector(selectAllUsers);
  const usersCount = useAppSelector(selectUsersCount);
  const { handleFetchMore } = useFetchMore({
    limit: 20,
    skipConstant: 20,
    fetchAction: fetchAllUsers,
    isEmpty: users.length > 0,
  });

  const handleRequestSort = (event: any, property: SetStateAction<string>) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: { target: { checked: any } }) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: ChangeEvent<HTMLInputElement>, name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] | ((prevState: never[]) => never[]) = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="User">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Email Options
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.map((row: UserType) => {
                    const {
                      _id,
                      name,
                      avatarUrl,
                      isVerrified,
                      lastLogin,
                      country,
                      email,
                      createdAt,
                      authType,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, name)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={name} src={avatarUrl} />
                            <Link
                              to={`/dashboard/user/${_id}`}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography color="#212B36" variant="subtitle2">
                                {name}
                              </Typography>
                            </Link>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{country}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">
                          {lastLogin ? fToNow(lastLogin) : "never"}
                        </TableCell>
                        <TableCell align="left">
                          {createdAt ? fToNow(createdAt) : "never"}
                        </TableCell>

                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(!isVerrified && "error") || "success"}
                          >
                            {sentenceCase(isVerrified ? "Yes" : "No")}
                          </Label>
                        </TableCell>

                        <TableCell align="left">{authType}</TableCell>
                        <TableCell align="right">
                          <UserMoreMenu
                            userInfo={{
                              id: row._id,
                              email: row.email,
                              name: row.name,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
            height="70px"
          >
            <Button
              variant="contained"
              onClick={handleFetchMore}
              sx={{ textTransform: "none" }}
            >
              More ({`${users.length} of ${usersCount}`})
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
