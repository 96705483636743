import { createSlice } from "@reduxjs/toolkit";
import { BASE_STATUS } from "../../../types/shared.types";
import {
  DashboardStatsP1,
  DashboardStatsP2,
  JobByCompany,
  JobCategoriesStats,
  LocationStats,
} from "../../../types/stats.types";
import { RootState } from "../../store";
import {
  fetchDashboard1Stats,
  fetchDashboard2Stats,
  fetchJobByCompaniesStats,
  fetchJobCategoriesStats,
  fetchUserLocationStats,
  fetchYearlyUserStats,
  fetchUserApplyStats,
  fetchApplyByJobTypeStats,
  fetchViewStatsByCountryAndJobType,
  fetchYearlyUserStatsByCountry,
  fetchUserEvents,
} from "./statsThunks";

interface InitState {
  status: BASE_STATUS;
  dashboardP1: DashboardStatsP1;
  dashboardP2: DashboardStatsP2;
  userAnalytics: Array<{
    date: string;
    logins: number;
    signups: number;
    _id: string;
  }>;
  userAnalyticsByCountry: Array<{
    date: string;
    day: string;
    month: string;
    year: string;
    countryAnalytics: { country: string; count: number }[];
    _id: string;
  }>;
  userLocations: LocationStats;
  userApplyStats: {
    _id: string;
    country: string;
    data: { count: number; date: string }[];
  }[];
  userEvents: {
    allApplications: { count: number; date: string }[];
    allViews: { count: number; date: string }[];
  };
  generalViewStats: any[];
  applyStatsByJobType: { _id: string; jobtype: string; count: number }[];
  jobCategories: Array<JobCategoriesStats>;
  jobsByCompanies: Array<JobByCompany>;
}

const initState: InitState = {
  status: "idle",
  dashboardP1: {
    _id: "",
    totalCompanies: 0,
    totalJobs: 0,
    totalUsers: 0,
    verifiedUsers: 0,
    updatedAt: 0,
    createdAt: 0,
  },
  dashboardP2: {
    signUps: {
      daily: {
        current: 0,
        last: 0,
        change: "",
      },
      weekly: {
        current: 0,
        last: 0,
        change: "",
      },
      monthly: {
        current: 0,
        last: 0,
        change: "",
      },
    },
    logins: {
      daily: {
        current: 0,
        last: 0,
        change: "",
      },
      weekly: {
        current: 0,
        last: 0,
        change: "",
      },
      monthly: {
        current: 0,
        last: 0,
        change: "",
      },
    },
  },
  userAnalytics: [],
  userAnalyticsByCountry: [
    {
      date: "",
      day: "",
      month: "",
      year: "",
      countryAnalytics: [{ country: "", count: 0 }],
      _id: "",
    },
  ],
  userLocations: {
    totalJobs: [],
    totalUsers: [],
  },

  userApplyStats: [],
  userEvents: { allApplications: [], allViews: [] },
  generalViewStats: [],
  applyStatsByJobType: [],
  jobCategories: [],
  jobsByCompanies: [],
};

const statsSlice = createSlice({
  name: "stats",
  initialState: initState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDashboard1Stats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDashboard1Stats.fulfilled, (state, action) => {
        state.status = "success";
        state.dashboardP1 = action.payload.body;
      })
      .addCase(fetchDashboard1Stats.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchDashboard2Stats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDashboard2Stats.fulfilled, (state, action) => {
        state.status = "success";
        state.dashboardP2 = action.payload.body;
      })
      .addCase(fetchDashboard2Stats.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchYearlyUserStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchYearlyUserStats.fulfilled, (state, action) => {
        state.status = "success";
        state.userAnalytics = action.payload.body;
      })
      .addCase(fetchYearlyUserStats.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchYearlyUserStatsByCountry.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchYearlyUserStatsByCountry.fulfilled, (state, action) => {
        state.status = "success";
        state.userAnalyticsByCountry = action.payload.body;
      })
      .addCase(fetchYearlyUserStatsByCountry.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchUserLocationStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserLocationStats.fulfilled, (state, action) => {
        state.status = "success";
        state.userLocations = action.payload.body;
      })
      .addCase(fetchUserLocationStats.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchUserApplyStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserApplyStats.fulfilled, (state, action) => {
        state.status = "success";
        state.userApplyStats = action.payload.body;
      })
      .addCase(fetchUserApplyStats.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchUserEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserEvents.fulfilled, (state, action) => {
        state.status = "success";
        state.userEvents = action.payload.body;
      })
      .addCase(fetchUserEvents.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchViewStatsByCountryAndJobType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchViewStatsByCountryAndJobType.fulfilled, (state, action) => {
        state.status = "success";
        state.generalViewStats = action.payload.body;
      })
      .addCase(fetchViewStatsByCountryAndJobType.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchApplyByJobTypeStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchApplyByJobTypeStats.fulfilled, (state, action) => {
        state.status = "success";
        state.applyStatsByJobType = action.payload.body;
      })
      .addCase(fetchApplyByJobTypeStats.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchJobCategoriesStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobCategoriesStats.fulfilled, (state, action) => {
        state.status = "success";
        state.jobCategories = action.payload.body;
      })
      .addCase(fetchJobCategoriesStats.rejected, (state) => {
        state.status = "fail";
      });

    builder
      .addCase(fetchJobByCompaniesStats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobByCompaniesStats.fulfilled, (state, action) => {
        state.status = "success";
        state.jobsByCompanies = action.payload.body;
      })
      .addCase(fetchJobByCompaniesStats.rejected, (state) => {
        state.status = "fail";
      });
  },
});

export default statsSlice.reducer;

export const selectStatsStatus = (state: RootState) => state.stats.status;
export const selectStatsDashboard1 = (state: RootState) =>
  state.stats.dashboardP1;
export const selectStatsDashboard2 = (state: RootState) =>
  state.stats.dashboardP2;
export const selectUserAnalytics = (state: RootState) =>
  state.stats.userAnalytics;
export const selectUserAnalyticsByCountry = (state: RootState) =>
  state.stats.userAnalyticsByCountry;
export const selectUserLocationStats = (state: RootState) =>
  state.stats.userLocations;
export const selectUserApplyStats = (state: RootState) =>
  state.stats.userApplyStats;
export const selectUserEvents = (state: RootState) => state.stats.userEvents;
export const selectJobTypeApplyStats = (state: RootState) =>
  state.stats.applyStatsByJobType;
export const selectJobTypeViewStats = (state: RootState) =>
  state.stats.generalViewStats;
export const selectJobCategoriesStats = (state: RootState) =>
  state.stats.jobCategories;
export const selectJobsByCompanies = (state: RootState) =>
  state.stats.jobsByCompanies;
