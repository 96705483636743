/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { CategorieFaqType, FETCH_STATUS } from "../../../types/knowlegeBade";
import { getJoinCategory, getJoinEmployment, getJoinLevels, getJoinoffices } from "./joinThunks";

interface InitState {
  category: {
    statusFetch: "idle" | FETCH_STATUS;
    data: CategorieFaqType[];
  };
  employment: {
    statusFetch: "idle" | FETCH_STATUS;
    data: CategorieFaqType[];
  };
  levels: {
    statusFetch: "idle" | FETCH_STATUS;
    data: CategorieFaqType[];
  };
  offices: {
    statusFetch: "idle" | FETCH_STATUS;
    data: CategorieFaqType[];
  };
}

const initState: InitState = {
  category: {
    statusFetch: "idle",
    data: [],
  },
  employment: {
    statusFetch: "idle",
    data: [],
  },
  levels: {
    statusFetch: "idle",
    data: [],
  },
  offices: {
    statusFetch: "idle",
    data: [],
  },
};

const joinSlice = createSlice({
  name: "join",
  initialState: initState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getJoinCategory.pending, (state) => {
        state.category.statusFetch = "FETCH_LOADING";
      })
      .addCase(getJoinCategory.fulfilled, (state, action) => {
        state.category.statusFetch = "FETCH_SUCCESS";
        state.category.data = action.payload.body;
      })
      .addCase(getJoinCategory.rejected, (state) => {
        state.category.statusFetch = "FETCH_FAIL";
      });

    builder
      .addCase(getJoinEmployment.pending, (state) => {
        state.employment.statusFetch = "FETCH_LOADING";
      })
      .addCase(getJoinEmployment.fulfilled, (state, action) => {
        state.employment.statusFetch = "FETCH_SUCCESS";
        state.employment.data = action.payload.body;
      })
      .addCase(getJoinEmployment.rejected, (state) => {
        state.employment.statusFetch = "FETCH_FAIL";
      });

    builder
      .addCase(getJoinLevels.pending, (state) => {
        state.levels.statusFetch = "FETCH_LOADING";
      })
      .addCase(getJoinLevels.fulfilled, (state, action) => {
        state.levels.statusFetch = "FETCH_SUCCESS";
        state.levels.data = action.payload.body;
      })
      .addCase(getJoinLevels.rejected, (state) => {
        state.levels.statusFetch = "FETCH_FAIL";
      });

    builder
      .addCase(getJoinoffices.pending, (state) => {
        state.offices.statusFetch = "FETCH_LOADING";
      })
      .addCase(getJoinoffices.fulfilled, (state, action) => {
        state.offices.statusFetch = "FETCH_SUCCESS";
        state.offices.data = action.payload.body;
      })
      .addCase(getJoinoffices.rejected, (state) => {
        state.offices.statusFetch = "FETCH_FAIL";
      });
  },
});

export default joinSlice.reducer;

export const fetchJoinCategory = (state: RootState) => state.join.category;
export const fetchJoinEmployment = (state: RootState) => state.join.employment;
export const fetchJoinLevels = (state: RootState) => state.join.levels;
export const fetchJoinOffices = (state: RootState) => state.join.offices;
