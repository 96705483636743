import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";
import { User } from "../../../types/auth.types";

export const checkMfa = createAsyncThunk(
  "auth/checkMfa",
  async (payload: { email: string }) => {
    const res = await request.post("/api/v1/auth/check-mfa", payload);
    return res.data;
  }
);

export const generateRegularOTP = createAsyncThunk(
  "auth/generateTOTP",
  async (payload: { email: string }) => {
    const res = await request.post("/api/v1/auth/mfa/email-otp", payload);
    return res.data;
  }
);

export const verifyRegularOTP = createAsyncThunk(
  "auth/generateTOTP",
  async (payload: { email: string; token: string }) => {
    const res = await request.post(
      "/api/v1/auth/mfa/verify/email-otp",
      payload
    );
    return res.data;
  }
);

export const generateTOTP = createAsyncThunk(
  "auth/generateTOTP",
  async (payload: { email: string }) => {
    const res = await request.post("/api/v1/auth/mfa/generate", payload);
    return res.data;
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (payload: {
    email: string;
    password: string;
  }): Promise<{ access_token: string; body: User }> => {
    const res = await request.post("/api/v1/auth/login", payload);
    return res.data;
  }
);

export const getAdminData = createAsyncThunk(
  "auth/getAdminData",
  async (): Promise<{ access_token: string; body: User }> => {
    const res = await request.get("/api/v1/auth/user-data");

    return res.data;
  }
);
