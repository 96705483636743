import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import User from "./pages/user";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import JobCenter from "./pages/JobCenter";
import DashboardApp from "./pages/DashboardApp";
import UserProfile from "./pages/user/Userprofile";
import Company from "./pages/Company";
import Payment from "./pages/Payment";
import ViewCompany from "./pages/ViewCompanyPage";
import ViewJobs from "./pages/ViewJobs";
import NewProvider from "./pages/Payment/NewProvider";
import Cities from "./pages/Locations/Cities";
import States from "./pages/Locations/States";
import Locations from "./pages/Locations/Index";
import KnowledgeBase from "./pages/KnowledgeBase";
import Utilities from "./pages/Utilities";
import Messega from "./pages/Messega";
import Analytics from "./pages/Analytics";
import AnalyticsSlug from "./pages/Analytics/slug";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "app", element: <DashboardApp /> },
        { path: "user", element: <User /> },
        { path: "user/:id", element: <UserProfile /> },
        { path: "job-center", element: <JobCenter /> },
        { path: "job-center/:id", element: <ViewJobs /> },
        { path: "company", element: <Company /> },
        { path: "company/:slug", element: <ViewCompany /> },
        { path: "payment", element: <Payment /> },
        { path: "new/payment", element: <NewProvider /> },
        { path: "knowledge", element: <KnowledgeBase /> },
        { path: "utils", element: <Utilities /> },
        { path: "messega", element: <Messega /> },
        { path: "analytics", element: <Analytics /> },
        { path: "analytics/:slug", element: <AnalyticsSlug /> },
        {
          path: "location",
          children: [
            { path: "", element: <Locations /> },
            { path: "state/:countryCode", element: <States /> },
            { path: "city/:countryCode/:stateCode", element: <Cities /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/login" /> },
        { path: "login", element: <Login /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
