/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  deleteCategoryFaq,
  deleteFaq,
  getCategoryFaq,
  patchCategoryFaq,
  patchFaq,
  postCategoryFaq,
  postFaq,
} from "./knowledgeThunks";
import { CategorieFaqType, DELETE_STATUS, FETCH_STATUS, PATCH_STATUS, POST_STATUS } from "../../../types/knowlegeBade";
import { toastError, toastSuccess } from "../../../../components/CustomToastify";

interface InitState {
  category: {
    statusFetch: "idle" | FETCH_STATUS;
    statusPatch: "idle" | PATCH_STATUS;
    statusDelete: "idle" | DELETE_STATUS;
    statusPost: "idle" | POST_STATUS;
    data: CategorieFaqType[];
  };
}

const initState: InitState = {
  category: {
    statusFetch: "idle",
    statusPatch: "idle",
    statusDelete: "idle",
    statusPost: "idle",
    data: [],
  },
};

const knowledgeSlice = createSlice({
  name: "knowledge",
  initialState: initState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCategoryFaq.pending, (state) => {
        state.category.statusFetch = "FETCH_LOADING";
      })
      .addCase(getCategoryFaq.fulfilled, (state, action) => {
        state.category.statusFetch = "FETCH_SUCCESS";
        state.category.data = action.payload.body;
      })
      .addCase(getCategoryFaq.rejected, (state) => {
        state.category.statusFetch = "FETCH_FAIL";
      });

    builder
      .addCase(postCategoryFaq.pending, (state) => {
        state.category.statusPost = "POST_LOADING";
      })
      .addCase(postCategoryFaq.fulfilled, (state, action) => {
        state.category.statusPost = "POST_SUCCESS";
        state.category.data.push(action.payload.body);
        toastSuccess("the category was created successfully");
      })
      .addCase(postCategoryFaq.rejected, (state) => {
        state.category.statusPost = "POST_FAIL";
        toastError("error when creating a category");
      });

    builder
      .addCase(patchCategoryFaq.pending, (state) => {
        state.category.statusPatch = "PATCH_LOADING";
      })
      .addCase(patchCategoryFaq.fulfilled, (state, action) => {
        state.category.statusPatch = "PATCH_SUCCESS";
        state.category.data = state.category.data.map((i) => {
          if (i._id === action.payload.body._id) {
            return (i = action.payload.body);
          }
          return i;
        });
        toastSuccess("Category changed successfully");
      })
      .addCase(patchCategoryFaq.rejected, (state) => {
        state.category.statusPatch = "PATCH_FAIL";
        toastError("error when updating the category");
      });

    builder
      .addCase(deleteCategoryFaq.pending, (state) => {
        state.category.statusDelete = "DELETE_LOADING";
      })
      .addCase(deleteCategoryFaq.fulfilled, (state, action) => {
        state.category.statusDelete = "DELETE_SUCCESS";
        state.category.data = state.category.data.filter((i) => i._id !== action.payload.body);
        toastSuccess("the category was successfully deleted");
      })
      .addCase(deleteCategoryFaq.rejected, (state) => {
        state.category.statusDelete = "DELETE_FAIL";
        toastError("error when deleting a category");
      });

    //   Faq
    builder
      .addCase(postFaq.fulfilled, (state, { payload, meta }) => {
        state.category.data = state.category.data.map((i) => {
          if (i._id === meta.arg.category) {
            return (i = { ...i, faqs: [...i.faqs, payload.body] });
          }
          return i;
        });

        toastSuccess("the Faq was created successfully");
      })
      .addCase(postFaq.rejected, (state) => {
        state.category.statusPost = "POST_FAIL";
        toastError("error when creating a Faq");
      });

    builder
      .addCase(patchFaq.fulfilled, (state, action) => {
        state.category.data = state.category.data.map((i) => {
          const data = { ...i, faqs: i.faqs.filter((faq) => faq._id !== action.meta.arg.id) };
          return (i = data);
        });
        state.category.data = state.category.data.map((i) => {
          if (i._id === action.meta.arg.data.category) {
            return (i = { ...i, faqs: [...i.faqs, action.payload.body] });
          }
          return i;
        });
        toastSuccess("Category changed successfully");
      })
      .addCase(patchFaq.rejected, () => {
        toastError("error when updating the category");
      });

    builder
      .addCase(deleteFaq.fulfilled, (state, action) => {
        state.category.data = state.category.data.map((i) => {
          const data = { ...i, faqs: i.faqs.filter((faq) => faq._id !== action.payload.body) };
          return (i = data);
        });

        toastSuccess("the Faq was successfully deleted");
      })
      .addCase(deleteFaq.rejected, () => {
        toastError("error when deleting a Faq");
      });
  },
});

export default knowledgeSlice.reducer;

export const knowledgeCategory = (state: RootState) => state.knowledge.category;
