import Typography from "@mui/material/Typography";
import { Link, Avatar, Rating, Stack, Paper, Box } from "@mui/material";
import { CompanyData } from "../../../core/types/company.types";
import parse from "html-react-parser";
import { v4 as uuidv4 } from "uuid";
import { CompanyStaticsType } from "../../../pages/ViewCompanyPage";

interface Props {
  company: CompanyData;
  jobs: number;
  companyStats?: CompanyStaticsType;
}

const ViewPage = (props: Props) => (
  <Stack gap="20px" p="30px 20px" component={Paper}>
    <Stack direction="row" gap="20px" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap="20px">
        <img
          style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "contain" }}
          src={props.company.logo}
          alt="logo"
        />
        <Stack>
          <Typography gutterBottom fontSize="20px" fontWeight="600" color="rgb(35, 159, 94)" mb="7px">
            {props.company.name}
          </Typography>
          <Stack>
            <Stack direction="row" gap="5px">
              <Typography fontSize="15px" color="rgb(35, 159, 94)" fontWeight="500">
                Number of jobs =
              </Typography>
              <Typography color="text.secondary">{props.jobs}</Typography>
            </Stack>
            {props?.companyStats?.events.length !== 0 &&
              props?.companyStats?.events.map((i) => (
                <Stack direction="row" gap="5px">
                  <Typography fontSize="15px" color="rgb(35, 159, 94)" fontWeight="500">
                    "{i._id}" events =
                  </Typography>
                  <Typography color="text.secondary">{i.count}</Typography>
                </Stack>
              ))}
            {props?.companyStats?.sources.length !== 0 &&
              props?.companyStats?.sources.map((i) => (
                <Stack direction="row" gap="5px">
                  <Typography fontSize="15px" color="rgb(35, 159, 94)" fontWeight="500">
                    clicks from {i._id} page =
                  </Typography>
                  <Typography color="text.secondary">{i.count}</Typography>
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Stack>

      <Box>
        {props.company?.reviews?.length &&
          props.company.reviews.map((r) => (
            <Box key={uuidv4()} mb={"10px"} display="flex" gap={"10px"} alignItems="center">
              <Link href={r.sourceUrl} target="_blank">
                <Avatar src={r.logoUrl} />
              </Link>
              <Box>
                <Typography component={"a"} href={r.sourceUrl} sx={{ ":hover": { textDecoration: "underline" } }}>
                  {r.providerName}
                </Typography>
                <Typography fontSize={"13px"}>
                  {r.scale === "stars" ? (
                    <Box component={"span"} display="flex" alignItems={"center"}>
                      {r.rating}
                      <Rating value={r.rating} readOnly max={r.outOf} sx={{ color: "#239F5E", fontSize: "14px" }} />(
                      {r.numOfRespondents})
                    </Box>
                  ) : r.scale === "numeric" ? (
                    <Typography fontSize={"13px"}>
                      {r.rating}/{r.outOf} ({r.numOfRespondents})
                    </Typography>
                  ) : (
                    <Typography fontSize={"13px"}>
                      {r.rating}% ({r.numOfRespondents})
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Stack>
    <Typography>
      {props.company.description[0] === "<" ? parse(props.company.description) : props.company.description}
    </Typography>
  </Stack>
);

export default ViewPage;
