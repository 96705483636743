/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import Page from "../components/Page";
import {
  FullScreenDialog,
  EnhancedTable,
} from "../sections/@dashboard/company";
import { useFetchMore } from "../hooks/useFetchMore";
import {
  fetchCompanies,
  fetchCompaniesWithEvents,
} from "../core/redux/features/companies/companyThunks";
import { useAppDispatch, useAppSelector } from "../core/redux/hooks";
import {
  CompanyArray,
  selectCompanies,
  serachDataCompany,
} from "../core/redux/features/companies/companiesSlice";
import { useEffect, useState } from "react";
import { selectCountry } from "../core/redux/features/minor/minorSlice";
import { fetchCountries } from "../core/redux/features/minor/minorThunks";

export default function Company() {
  const fetchedCompanies = useAppSelector(selectCompanies);
  const [companies, setCompanies] = useState<CompanyArray>(fetchedCompanies);
  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectCountry);
  const { handleFetchMore } = useFetchMore({
    limit: 20,
    skipConstant: 20,
    // fetchAction: fetchCompanies,
    fetchAction: fetchCompaniesWithEvents,
    isEmpty: companies.length > 0,
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && countries.data.length === 0) {
      dispatch(fetchCountries());
    }
  }, []);

  useEffect(() => {
    const cachedCompaniesWithEvents = localStorage.getItem(
      "cachedCompaniesWithEvents"
    );

    if (fetchedCompanies.length > 0) {
      setCompanies(fetchedCompanies);
    }

    if (cachedCompaniesWithEvents && !fetchedCompanies.length) {
      setCompanies(JSON.parse(cachedCompaniesWithEvents));
    }
  }, [fetchedCompanies]);

  const companySearchData = useAppSelector(serachDataCompany);

  return (
    <Page title="Company">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4">Companies</Typography>
          <FullScreenDialog isEdit={false} />
        </Stack>
        {companies.length ? (
          <EnhancedTable
            rows={companies}
            searchRows={companySearchData.serachData}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Page>
  );
}
