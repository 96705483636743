import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  TextField,
  Grow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import { useAppDispatch } from "../../../core/redux/hooks";
import {
  approvePendingJobById,
  deleteJobById,
  deleteNewJobById,
  deletePendingJobById,
  rejectPendingJobById,
} from "../../../core/redux/features/jobs/jobThunks";
import { toastError } from "../../../components/CustomToastify";
import RichTextRHF from "../../../components/hook-form/RichTextRHF";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "#F9FAFB",
  boxShadow:
    "0px 3px 1px -2px rgba(145, 158, 171, 0.2), 0px 2px 2px 0px rgba(145, 158, 171, 0.14), 0px 1px 5px 0px rgba(145, 158, 171, 0.12)",
  p: 4,
  // height: "230px",
  borderRadius: "5px",
};

const rejectionReasons = [
  {
    id: 1,
    reason: "Inappropriate Content",
    templateBasedText:
      "We value a professional and inclusive environment. Please revise your ad to remove any offensive or inappropriate content.",
  },
  {
    id: 2,
    reason: "Discriminatory Language",
    templateBasedText:
      "DoScouting prohibits job ads with discriminatory language. Please ensure your ad is fair and open to all qualified candidates.",
  },
  {
    id: 3,
    reason: "Misleading Information",
    templateBasedText:
      "Transparency is key. Ensure your ad accurately reflects the job duties, qualifications, and compensation.",
  },
  {
    id: 4,
    reason: "Illegal Activities",
    templateBasedText:
      "DoScouting does not allow job ads promoting illegal activities. Please revise your ad to focus on legitimate opportunities.",
  },
  {
    id: 5,
    reason: "Incomplete Information",
    templateBasedText:
      "A complete job ad attracts better candidates. Please provide all necessary details about the position and company.",
  },
  {
    id: 6,
    reason: "Repetitive Content",
    templateBasedText:
      "For clarity, avoid excessive repetition of keywords or phrases in your job ad.",
  },
  {
    id: 7,
    reason: "Unprofessional Presentation",
    templateBasedText:
      "A professional presentation creates a good first impression. Proofread your ad and ensure a clear and concise format.",
  },
  {
    id: 8,
    reason: "Violates Terms of Service",
    templateBasedText:
      "Please review our Terms of Service for specific guidelines. If you have questions, contact our support team.",
  },
  {
    id: 9,
    reason: "Irrelevant to Platform",
    templateBasedText:
      "DoScouting facilitates the connection between high-caliber talent and leading businesses. Please ensure your job advertisement aligns with our platform's core objective",
  },
  {
    id: 10,
    reason: "Expired or Outdated",
    templateBasedText:
      "For the best results, update your job ad to reflect current requirements and deadlines.",
  },
  {
    id: 11,
    reason: "Safety Concerns",
    templateBasedText:
      "The safety of our users is paramount. Please revise your ad to remove any content that could raise safety concerns.",
  },
  {
    id: 12,
    reason: "Confidential Information",
    templateBasedText:
      "Do not include confidential information in your job ad. Respect the privacy of individuals and companies.",
  },
  {
    id: 13,
    reason: "Negative Reviews or Feedback",
    templateBasedText:
      "Consider addressing any negative feedback you may have received to improve your employer brand and attract top talent.",
  },
  {
    id: 14,
    reason: "Low Quality or Scam",
    templateBasedText:
      "DoScouting strives for quality job postings. Ensure your ad is genuine and provides a valuable opportunity for candidates.",
  },
];

export default function DeleteModal(props: {
  slug: string;
  id: string;
  pending?: boolean;
  rejected?: boolean;
  newIndicator?: boolean;
}) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [rejection, setRejection] = React.useState({
    reason: "",
    additionalInfo: "",
    templateBasedText: "",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [target, setTarget] = React.useState("");

  const handleInfoChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRejection((prev) => {
      return { ...prev, additionalInfo: e.target.value };
    });
  };

  const handleReasonChange = (e: SelectChangeEvent<string>) => {
    const tempText = rejectionReasons.find(
      (item) => item.reason === e.target.value
    )?.templateBasedText;
    if (tempText) {
      setRejection((prev) => {
        return { ...prev, reason: e.target.value, templateBasedText: tempText };
      });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (target === props.slug) {
      if (props.pending) {
        dispatch(rejectPendingJobById({ id: props.id, rejection }));
      }

      if (props.rejected) {
        dispatch(deletePendingJobById(props.id));
      }

      if (!props.rejected && !props.pending && !props.newIndicator) {
        dispatch(deleteJobById(props.id));
      }

      if (!props.rejected && !props.pending && !props.newIndicator) {
        dispatch(deleteJobById(props.id));
      }

      if (props.newIndicator) {
        dispatch(deleteNewJobById(props.id));
      }

      setOpen(false);
    } else {
      toastError("Parameters didn't match");
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        color="error"
        variant="outlined"
        startIcon={<DeleteIcon />}
        sx={{ width: "33%", fontSize: "65%" }}
      >
        {props.pending ? "Reject" : "Delete"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              pt="4px"
            >
              <Box display={"flex"} gap="4px">
                <Grow in={true} {...{ timeout: 800 }}>
                  <Typography fontSize="17px" fontWeight="600">
                    JOB: {props.slug}
                  </Typography>
                </Grow>
                <Grow in={true} {...{ timeout: 800 }}>
                  <Box mt={-1.1}>
                    <IconButton onClick={() => copy(props.slug)}>
                      <CopyAll />
                    </IconButton>
                  </Box>
                </Grow>
              </Box>
              <Box mt={1.9}>
                <form style={FormStyle} onSubmit={handleSubmit}>
                  <TextField
                    label="Сopy and paste the job name here"
                    variant="outlined"
                    style={{ width: "430px" }}
                    value={target}
                    onChange={(e) => setTarget(e.target.value)}
                    required
                  />

                  {props.pending ? (
                    <>
                      <FormControl fullWidth>
                        <InputLabel>Rejection Reason</InputLabel>
                        <Select
                          label="Rejection Reason"
                          value={rejection.reason}
                          onChange={handleReasonChange}
                        >
                          {rejectionReasons.map((reason) => (
                            <MenuItem key={reason.id} value={reason.reason}>
                              {reason.reason}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl fullWidth>
                        <TextField
                          label="Additional Information"
                          multiline
                          minRows={5}
                          value={rejection.additionalInfo}
                          onChange={handleInfoChange}
                        />
                      </FormControl>
                    </>
                  ) : null}

                  <Button
                    sx={{
                      width: "430px",
                      bgcolor: "red",
                      "&:hover": {
                        background: "#f00",
                      },
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Reject
                  </Button>
                </form>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export function ApproveModal(props: { slug: string; id: string; body: any }) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [target, setTarget] = React.useState("");

  const handleSubmit = (e: any) => {
    const data = {
      title: props.body.title,
      jobUrl: props.body.jobUrl,
      description: props.body.description,
      compensation: props.body.compensation,
      jobOverview: props.body.jobOverview,
      jobRoles: props.body.jobRoles,
      company: props.body.company._id,
      jobType: props.body.jobType._id,
      category: props.body.category._id,
      jobFrequency: props.body.jobFrequency._id,
      experience: props.body.experience.map((exp: any) => exp._id),
      skills: props.body.skills.map((exp: any) => exp._id),
      isRemote: false,
      country: props.body.country,
      state: props.body.state,
      city: props.body.city,
    };
    e.preventDefault();
    if (target === props.slug) {
      dispatch(approvePendingJobById({ body: data, id: props.id })).then(() => {
        dispatch(deletePendingJobById(props.id));
      });
      setOpen(false);
    } else {
      toastError("Parameters didn't match");
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        color="success"
        variant="outlined"
        startIcon={<DeleteIcon />}
        sx={{ width: "33%", fontSize: "65%" }}
      >
        Approve
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              pt="4px"
            >
              <Box display={"flex"} gap="4px">
                <Grow in={true} {...{ timeout: 800 }}>
                  <Typography fontSize="17px" fontWeight="600">
                    JOB: {props.slug}
                  </Typography>
                </Grow>
                <Grow in={true} {...{ timeout: 800 }}>
                  <Box mt={-1.1}>
                    <IconButton onClick={() => copy(props.slug)}>
                      <CopyAll />
                    </IconButton>
                  </Box>
                </Grow>
              </Box>
              <Box mt={1.9}>
                <form style={FormStyle} onSubmit={handleSubmit}>
                  <TextField
                    label="Сopy and paste the company names here"
                    variant="outlined"
                    style={{ width: "430px" }}
                    value={target}
                    onChange={(e) => setTarget(e.target.value)}
                    required
                  />
                  <Button
                    sx={{
                      width: "430px",
                      bgcolor: "green",
                      "&:hover": {
                        background: "#f00",
                      },
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Approve
                  </Button>
                </form>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export const FormStyle: any = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "22px",
};
