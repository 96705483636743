import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  deleteCategory,
  deleteExperience,
  deleteJobFrequency,
  deleteJobType,
  deleteSkill,
  getCategory,
  getExperience,
  getJobFrequency,
  getJobType,
  getSkill,
  patchCategory,
  patchExperience,
  patchJobFrequency,
  patchJobType,
  patchSkill,
  postCategory,
  postExperience,
  postJobFrequency,
  postJobType,
  postSkill,
} from "./utilitiesThuks";
import { toastSuccess, toastError } from "../../../../components/CustomToastify";
import { FETCH_STATUS } from "../../../types/knowlegeBade";
import {
  categoryUtilsType,
  jobTypeType,
  experienceType,
  jobFrequencyType,
  skillUtilsType,
} from "../../../types/Utilities.types";

interface InitState {
  category: {
    statusFetch: "idle" | FETCH_STATUS;
    data: categoryUtilsType[];
  };
  jobType: {
    statusFetch: "idle" | FETCH_STATUS;
    data: jobTypeType[];
  };
  experience: {
    statusFetch: "idle" | FETCH_STATUS;
    data: experienceType[];
  };
  jobFrequency: {
    statusFetch: "idle" | FETCH_STATUS;
    data: jobFrequencyType[];
  };
  skills: {
    statusFetch: "idle" | FETCH_STATUS;
    data: skillUtilsType[];
  };
}

const initStateUtils: InitState = {
  category: {
    statusFetch: "idle",
    data: [],
  },
  jobType: {
    statusFetch: "idle",
    data: [],
  },
  experience: {
    statusFetch: "idle",
    data: [],
  },
  jobFrequency: {
    statusFetch: "idle",
    data: [],
  },
  skills: {
    statusFetch: "idle",
    data: [],
  },
};

const utilitiesSlice = createSlice({
  name: "utilities",
  initialState: initStateUtils,
  reducers: {},

  extraReducers(builder) {
    ///////////////////////Experience>>>>
    builder
      .addCase(getExperience.pending, (state) => {
        state.experience.statusFetch = "FETCH_LOADING";
      })
      .addCase(getExperience.fulfilled, (state, action) => {
        state.experience.statusFetch = "FETCH_SUCCESS";
        state.experience.data = action.payload.body;
      })
      .addCase(getExperience.rejected, (state) => {
        state.experience.statusFetch = "FETCH_FAIL";
      });
    builder
      .addCase(postExperience.fulfilled, (state, action) => {
        state.experience.data = [...state.experience.data, action.payload.body];
        toastSuccess("Experience successfully created✅");
      })
      .addCase(postExperience.rejected, () => {
        toastError("error when creating a Experience❌");
      });
    builder
      .addCase(patchExperience.fulfilled, (state, action) => {
        state.experience.data = state.experience.data.filter((i) => i._id !== action.payload.body._id);
        state.experience.data = [...state.experience.data, action.payload.body];
        toastSuccess("Experience successfully updated✅");
      })
      .addCase(patchExperience.rejected, () => {
        toastError("error updating the Experience❌");
      });
    builder
      .addCase(deleteExperience.fulfilled, (state, action) => {
        state.experience.data = state.experience.data.filter((i) => i._id !== action.payload.body);
        toastSuccess("the Experience was successfully deleted✅");
      })
      .addCase(deleteExperience.rejected, () => {
        toastError("error when deleting a Experience❌");
      });
    ///////////////////////Experience End
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////JobFrequency>>>>
    builder
      .addCase(getJobFrequency.pending, (state) => {
        state.jobFrequency.statusFetch = "FETCH_LOADING";
      })
      .addCase(getJobFrequency.fulfilled, (state, action) => {
        state.jobFrequency.statusFetch = "FETCH_SUCCESS";
        state.jobFrequency.data = action.payload.body;
      })
      .addCase(getJobFrequency.rejected, (state) => {
        state.jobFrequency.statusFetch = "FETCH_FAIL";
      });
    // POST
    builder
      .addCase(postJobFrequency.fulfilled, (state, action) => {
        state.jobFrequency.data = [...state.jobFrequency.data, action.payload.body];
        toastSuccess("JobFrequency successfully created✅");
      })
      .addCase(postJobFrequency.rejected, () => {
        toastError("error when creating a JobFrequency❌");
      });
    // PATCH
    builder
      .addCase(patchJobFrequency.fulfilled, (state, action) => {
        state.jobFrequency.data = state.jobFrequency.data.filter((i) => i._id !== action.payload.body._id);
        state.jobFrequency.data = [...state.jobFrequency.data, action.payload.body];
        toastSuccess("JobFrequency successfully updated✅");
      })
      .addCase(patchJobFrequency.rejected, () => {
        toastError("error updating the JobFrequency❌");
      });
    // DELETE
    builder
      .addCase(deleteJobFrequency.fulfilled, (state, action) => {
        state.jobFrequency.data = state.jobFrequency.data.filter((i) => i._id !== action.payload.body);
        toastSuccess("the JobFrequency was successfully deleted✅");
      })
      .addCase(deleteJobFrequency.rejected, () => {
        toastError("error when deleting a JobFrequency❌");
      });
    ///////////////////////JobFrequency End
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////JobType>>>>
    builder
      .addCase(getJobType.pending, (state) => {
        state.jobType.statusFetch = "FETCH_LOADING";
      })
      .addCase(getJobType.fulfilled, (state, action) => {
        state.jobType.statusFetch = "FETCH_SUCCESS";
        state.jobType.data = action.payload.body;
      })
      .addCase(getJobType.rejected, (state) => {
        state.jobType.statusFetch = "FETCH_FAIL";
      });
    // POST
    builder
      .addCase(postJobType.fulfilled, (state, action) => {
        state.jobType.data = [...state.jobType.data, action.payload.body];
        toastSuccess("JobType successfully created✅");
      })
      .addCase(postJobType.rejected, () => {
        toastError("error when creating a JobType❌");
      });
    // PATCH
    builder
      .addCase(patchJobType.fulfilled, (state, action) => {
        state.jobType.data = state.jobType.data.filter((i) => i._id !== action.payload.body._id);
        state.jobType.data = [...state.jobType.data, action.payload.body];
        toastSuccess("JobType successfully updated✅");
      })
      .addCase(patchJobType.rejected, () => {
        toastError("error updating the JobType❌");
      });
    // DELETE
    builder
      .addCase(deleteJobType.fulfilled, (state, action) => {
        state.jobType.data = state.jobType.data.filter((i) => i._id !== action.payload.body);
        toastSuccess("the JobType was successfully deleted✅");
      })
      .addCase(deleteJobType.rejected, () => {
        toastError("error when deleting a JobType❌");
      });
    ///////////////////////JobType End
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////Category>>>>
    builder
      .addCase(getCategory.pending, (state) => {
        state.category.statusFetch = "FETCH_LOADING";
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.category.statusFetch = "FETCH_SUCCESS";
        state.category.data = action.payload.body;
      })
      .addCase(getCategory.rejected, (state) => {
        state.category.statusFetch = "FETCH_FAIL";
      });
    // POST
    builder
      .addCase(postCategory.fulfilled, (state, action) => {
        state.category.data = [...state.category.data, action.payload.body];
        toastSuccess("Category successfully created✅");
      })
      .addCase(postCategory.rejected, () => {
        toastError("error when creating a Category❌");
      });
    // PATCH
    builder
      .addCase(patchCategory.fulfilled, (state, action) => {
        state.category.data = state.category.data.filter((i) => i._id !== action.payload.body._id);
        state.category.data = [...state.category.data, action.payload.body];
        toastSuccess("Category successfully updated✅");
      })
      .addCase(patchCategory.rejected, () => {
        toastError("error updating the Category❌");
      });
    // DELETE
    builder
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.category.data = state.category.data.filter((i) => i._id !== action.payload.body);
        toastSuccess("the Category was successfully deleted✅");
      })
      .addCase(deleteCategory.rejected, () => {
        toastError("error when deleting a Category❌");
      });
    ///////////////////////Category End
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////skill>>>>
    builder
      .addCase(getSkill.pending, (state) => {
        state.skills.statusFetch = "FETCH_LOADING";
      })
      .addCase(getSkill.fulfilled, (state, action) => {
        state.skills.statusFetch = "FETCH_SUCCESS";
        state.skills.data = action.payload.body;
      })
      .addCase(getSkill.rejected, (state) => {
        state.skills.statusFetch = "FETCH_FAIL";
      });
    // POST
    builder
      .addCase(postSkill.fulfilled, (state, action) => {
        state.skills.data = [...state.skills.data, action.payload.body];
        toastSuccess("skill successfully created✅");
      })
      .addCase(postSkill.rejected, () => {
        toastError("error when creating a skill❌");
      });
    // PATCH
    builder
      .addCase(patchSkill.fulfilled, (state, action) => {
        state.skills.data = state.skills.data.filter((i) => i._id !== action.payload.body._id);
        state.skills.data = [...state.skills.data, action.payload.body];
        toastSuccess("skill successfully updated✅");
      })
      .addCase(patchSkill.rejected, () => {
        toastError("error updating the skill❌");
      });
    // DELETE
    builder
      .addCase(deleteSkill.fulfilled, (state, action) => {
        state.skills.data = state.skills.data.filter((i) => i._id !== action.payload.body);
        toastSuccess("the skill was successfully deleted✅");
      })
      .addCase(deleteSkill.rejected, () => {
        toastError("error when deleting a skill❌");
      });
    ///////////////////////skill End
  },
});

export default utilitiesSlice.reducer;

export const selectUtilsExperience = (state: RootState) => state.utilities.experience;
export const selectUtilsJobFrequency = (state: RootState) => state.utilities.jobFrequency;
export const selectUtilsJobType = (state: RootState) => state.utilities.jobType;
export const selectUtilsCategory = (state: RootState) => state.utilities.category;
export const selectUtilsSkills = (state: RootState) => state.utilities.skills;
