import { createSlice } from "@reduxjs/toolkit";
import { LOGIN_STATUS, FETCH_USER_STATUS, User } from "../../../types/auth.types";
import { RootState } from "../../store";
import { getAdminData, login } from "./authThunk";

interface InitState {
  status: "idle" | LOGIN_STATUS | FETCH_USER_STATUS;
  data: User;
}

const initState: InitState = {
  status: "idle",
  data: {
    email: "",
    name: "",
    secondName: "",
    _id: "",
    isStaff: false,
    avatarUrl: "",
    city: "",
    country: "",
    isVerrified: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    logOut: () => {
      localStorage.clear();
      window.location.href = "/login";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, (state) => {
        state.status = "LOGIN_LOADING";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "LOGIN_SUCCESS";
        state.data = action.payload.body;
        localStorage.setItem("token", action.payload.access_token);
      })
      .addCase(login.rejected, (state) => {
        state.status = "LOGIN_FAIL";
      });

    builder
      .addCase(getAdminData.pending, (state, action) => {
        state.status = "FETCH_LOADING";
      })
      .addCase(getAdminData.fulfilled, (state, action) => {
        state.status = "FETCH_SUCCESS";
        state.data = action.payload.body;
      })
      .addCase(getAdminData.rejected, (state, action) => {
        state.status = "FETCH_FAIL";
        window.location.href = "/login";
      });
  },
});

export default authSlice.reducer;

export const { logOut } = authSlice.actions;

export const selectAdminData = (state: RootState) => state.auth.data;
export const selectAuthStatus = (state: RootState) => state.auth.status;
