import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";
import { UserType } from "../../../types/user.types";
import { RootState } from "../../store";

interface InitState {
  status: "idle" | "success" | "loading" | "fail";
  data: Array<UserType>;
  count: number;
}

const initState: InitState = {
  status: "idle",
  data: [],
  count: 0,
};

export const fetchAllUsers = createAsyncThunk(
  "users/fetchAllUsers",
  async (query: { limit: number; skip: number }) => {
    const { data } = await request.get(
      `/api/v1/user?skip=${query.skip}&limit=${query.limit}`
    );
    return data;
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (req: { email: string }) => {
    const { data } = await request.delete(`/api/v1/user/delete/${req.email}`);
    return data;
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: initState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = "success";
        state.data = [...state.data, ...action.payload.body.data];
        state.count = action.payload.body.count;
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.status = "fail";
      });
    builder
      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = "success";
        state.data = [...state.data, ...action.payload.body.data];
        state.count = action.payload.body.count;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.status = "fail";
      });
  },
});

export default userSlice.reducer;
export const selectAllUsers = (state: RootState) => state.users.data;
export const selectUsersCount = (state: RootState) => state.users.count;
