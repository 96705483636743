/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";
// @mui
import { Badge, IconButton, Avatar, Typography, Menu, MenuItem, Stack, Tooltip, CircularProgress } from "@mui/material";
// utils
// components
import Iconify from "../../components/Iconify";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { fetchAllMessages, messagesUnread } from "../../core/redux/features/messeages/messeagesSlice";
import { fetchMessagesUnread, messageType, patchMessages } from "../../core/redux/features/messeages/messeagesThunks";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Link } from "react-router-dom";
import { PATCH_STATUS } from "../../core/types/knowlegeBade";

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const dispatch = useAppDispatch();
  const { data, status, count } = useAppSelector(messagesUnread);
  const messaga = useAppSelector(fetchAllMessages);

  useEffect(() => {
    if (status === "idle") dispatch(fetchMessagesUnread({ limit: 12, skip: 0 }));
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReadMessage = (id: string) => {
    dispatch(patchMessages([id]));
  };
  const handleDeletedMessage = (id: string) => {
    dispatch(patchMessages([id]));
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleClick}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={count} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxWidth: { xs: 300, md: 400, lg: 500 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {data.slice(0, 5).map((i) => (
          <NotificationItem
            {...i}
            handleReadMessage={handleReadMessage}
            handleDeletedMessage={handleDeletedMessage}
            statusPatch={messaga.statusPatch}
          />
        ))}
        <Link to="messega">
          <MenuItem onClick={handleClose} sx={{ textAlign: "center", justifyContent: "center", mt: 1, width: "100%" }}>
            View All
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
}

interface NotificationItemProps extends Partial<messageType> {
  handleReadMessage: (id: string) => void;
  handleDeletedMessage: (id: string) => void;
  statusPatch: "idle" | PATCH_STATUS;
}

function NotificationItem(props: NotificationItemProps) {
  return (
    <Stack
      p="10px 20px 10px 10px"
      borderBottom="1px solid #b3b3b3"
      direction="row"
      alignItems="center"
      spacing={2}
      justifyContent="space-between"
    >
      <Tooltip title={props.message} placement="left">
        <Stack direction="row">
          <Avatar sx={{ bgcolor: "background.neutral" }}>
            <img alt={props._id} src="/static/icons/ic_notification_chat.svg" />
          </Avatar>

          <Stack spacing={-0.2}>
            <Typography variant="subtitle1">
              {props.name} {props.secondName}
            </Typography>
            <Typography variant="body2">{props.email}</Typography>
          </Stack>
        </Stack>
      </Tooltip>

      {!props.isRead && (
        <Tooltip title="Click to mark as read" placement="left">
          <IconButton
            onClick={() => props.handleReadMessage(String(props._id))}
            disabled={props.statusPatch === "PATCH_LOADING" ? true : false}
          >
            {props.statusPatch === "PATCH_LOADING" ? (
              <CircularProgress size="20px" />
            ) : (
              <DoneAllIcon fontSize="small" color="success" />
            )}
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}
