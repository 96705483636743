/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { DELETE_STATUS, FETCH_STATUS, PATCH_STATUS } from "../../../types/knowlegeBade";
import { deleteMessages, fetchMessages, fetchMessagesUnread, messageType, patchMessages } from "./messeagesThunks";

interface InitState {
  messages: {
    statusFetch: "idle" | FETCH_STATUS;
    statusPatch: "idle" | PATCH_STATUS;
    statusDelete: "idle" | DELETE_STATUS;
    data: messageType[];
    count: number;
  };
  messagesUnread: {
    status: "idle" | FETCH_STATUS;
    data: messageType[];
    count: number;
  };
}

const initState: InitState = {
  messages: {
    statusFetch: "idle",
    statusPatch: "idle",
    statusDelete: "idle",
    data: [],
    count: 0,
  },
  messagesUnread: {
    status: "idle",
    data: [],
    count: 0,
  },
};

const messagesSlice = createSlice({
  name: "messages",
  initialState: initState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMessages.pending, (state) => {
        state.messages.statusFetch = "FETCH_LOADING";
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.messages.statusFetch = "FETCH_SUCCESS";
        state.messages.data = [...state.messages.data, ...action.payload.body];
        state.messages.count = action.payload.count;
      })
      .addCase(fetchMessages.rejected, (state) => {
        state.messages.statusFetch = "FETCH_FAIL";
      });

    builder
      .addCase(fetchMessagesUnread.pending, (state) => {
        state.messagesUnread.status = "FETCH_LOADING";
      })
      .addCase(fetchMessagesUnread.fulfilled, (state, action) => {
        state.messagesUnread.status = "FETCH_SUCCESS";
        state.messagesUnread.data = [...state.messagesUnread.data, ...action.payload.body];
        state.messagesUnread.count = action.payload.count;
      })
      .addCase(fetchMessagesUnread.rejected, (state) => {
        state.messagesUnread.status = "FETCH_FAIL";
      });

    builder
      .addCase(patchMessages.pending, (state) => {
        state.messages.statusPatch = "PATCH_LOADING";
      })
      .addCase(patchMessages.fulfilled, (state, action) => {
        state.messagesUnread.data.map((i, idx) => {
          action.meta.arg.filter((id) => {
            if (id === i._id) {
              // state.messagesUnread.data.splice(idx, 1);
              state.messagesUnread.count = state.messagesUnread.count - 1;
            } else return i;
          });
        });

        state.messages.data.map((i, idx) => {
          action.meta.arg.filter((id) => {
            if (id === i._id) {
              state.messages.data.splice(idx, 1);
              state.messages.data.unshift({ ...i, isRead: true });
            } else return i;
          });
        });
        state.messages.statusPatch = "PATCH_SUCCESS";
      })
      .addCase(patchMessages.rejected, (state) => {
        state.messages.statusPatch = "PATCH_FAIL";
      });

    builder
      .addCase(deleteMessages.pending, (state) => {
        state.messages.statusDelete = "DELETE_LOADING";
      })
      .addCase(deleteMessages.fulfilled, (state, action) => {
        state.messages.data = state.messages.data.filter((i) => i._id !== action.meta.arg);
        state.messagesUnread.data = state.messagesUnread.data.filter((i) => i._id !== action.meta.arg);
        state.messages.statusDelete = "DELETE_SUCCESS";
      })
      .addCase(deleteMessages.rejected, (state) => {
        state.messages.statusDelete = "DELETE_FAIL";
      });
  },
});

export default messagesSlice.reducer;

export const fetchAllMessages = (state: RootState) => state.messages.messages;
export const messagesUnread = (state: RootState) => state.messages.messagesUnread;
