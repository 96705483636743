import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";
import { categoryUtilsType, experienceType, jobFrequencyType, jobTypeType } from "../../../types/Utilities.types";

// Experience>>>>
export const getExperience = createAsyncThunk(
  "utilities/getExperience",
  async (): Promise<{ body: experienceType[] }> => {
    const res = await request.get("/api/v1/experience");
    return res.data;
  }
);
export const postExperience = createAsyncThunk(
  "utilities/postExperience",
  async (data: { title: string }): Promise<{ body: experienceType }> => {
    const res = await request.post("/api/v1/experience", data);
    return res.data;
  }
);
export const patchExperience = createAsyncThunk(
  "utilities/patchExperience",
  async ({ title, id }: { title: string; id: string }): Promise<{ body: experienceType }> => {
    const res = await request.patch(`/api/v1/experience/${id}`, { title });
    return res.data;
  }
);
export const deleteExperience = createAsyncThunk(
  "utilities/deleteExperience",
  async (id: string): Promise<{ body: string }> => {
    const res = await request.delete(`/api/v1/experience/${id}`);
    return res.data;
  }
);
// Experience End
//
//
// JobFrequency>>>>
export const getJobFrequency = createAsyncThunk(
  "utilities/getJobFrequency",
  async (): Promise<{ body: jobFrequencyType[] }> => {
    const res = await request.get("/api/v1/job-frequency");
    return res.data;
  }
);
export const postJobFrequency = createAsyncThunk(
  "utilities/postJobFrequency",
  async (data: { title: string }): Promise<{ body: jobFrequencyType }> => {
    const res = await request.post("/api/v1/job-frequency", data);
    return res.data;
  }
);
export const patchJobFrequency = createAsyncThunk(
  "utilities/patchJobFrequency",
  async ({ title, id }: { title: string; id: string }): Promise<{ body: jobFrequencyType }> => {
    const res = await request.patch(`/api/v1/job-frequency/${id}`, { title });
    return res.data;
  }
);
export const deleteJobFrequency = createAsyncThunk(
  "utilities/deleteJobFrequency",
  async (id: string): Promise<{ body: string }> => {
    const res = await request.delete(`/api/v1/job-frequency/${id}`);
    return res.data;
  }
);
// JobFrequency End
//
//
// JobType>>>>
export const getJobType = createAsyncThunk("utilities/getJobType", async (): Promise<{ body: jobTypeType[] }> => {
  const res = await request.get("/api/v1/job-type");
  return res.data;
});
export const postJobType = createAsyncThunk(
  "utilities/postJobType",
  async (data: { title: string }): Promise<{ body: jobTypeType }> => {
    const res = await request.post("/api/v1/job-type", data);
    return res.data;
  }
);
export const patchJobType = createAsyncThunk(
  "utilities/patchJobType",
  async ({ title, id }: { title: string; id: string }): Promise<{ body: jobTypeType }> => {
    const res = await request.patch(`/api/v1/job-type/${id}`, { title });
    return res.data;
  }
);
export const deleteJobType = createAsyncThunk(
  "utilities/deleteJobType",
  async (id: string): Promise<{ body: string }> => {
    const res = await request.delete(`/api/v1/job-type/${id}`);
    return res.data;
  }
);
// JobType End
//
//
// Category>>>>
export const getCategory = createAsyncThunk(
  "utilities/getCategory",
  async (): Promise<{ body: categoryUtilsType[] }> => {
    const res = await request.get("/api/v1/category");
    return res.data;
  }
);
export const postCategory = createAsyncThunk(
  "utilities/postCategory",
  async (data: { title: string }): Promise<{ body: categoryUtilsType }> => {
    const res = await request.post("/api/v1/category", data);
    return res.data;
  }
);
export const patchCategory = createAsyncThunk(
  "utilities/patchCategory",
  async ({ title, id }: { title: string; id: string }): Promise<{ body: categoryUtilsType }> => {
    const res = await request.patch(`/api/v1/category/${id}`, { title });
    return res.data;
  }
);
export const deleteCategory = createAsyncThunk(
  "utilities/deleteCategory",
  async (id: string): Promise<{ body: string }> => {
    const res = await request.delete(`/api/v1/category/${id}`);
    return res.data;
  }
);
// Category End
//
//
// Skill>>>>
export const getSkill = createAsyncThunk("utilities/getSkill", async (): Promise<{ body: any }> => {
  const res = await request.get("/api/v1/skill");
  return res.data;
});
export const postSkill = createAsyncThunk(
  "utilities/postSkill",
  async (data: { title: string }): Promise<{ body: any }> => {
    const res = await request.post("/api/v1/skill", data);
    return res.data;
  }
);
export const patchSkill = createAsyncThunk(
  "utilities/patchSkill",
  async ({ title, id }: { title: string; id: string }): Promise<{ body: any }> => {
    const res = await request.patch(`/api/v1/skill/${id}`, { title });
    return res.data;
  }
);
export const deleteSkill = createAsyncThunk("utilities/deleteSkill", async (id: string): Promise<{ body: string }> => {
  const res = await request.delete(`/api/v1/skill/${id}`);
  return res.data;
});
// Skill End
