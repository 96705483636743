import { createSlice } from "@reduxjs/toolkit";
import { toastError, toastSuccess } from "../../../../components/CustomToastify";
import { ICity, ICountry, IState } from "../../../types/locations.types";
import { BASE_STATUS } from "../../../types/shared.types";
import { RootState } from "../../store";
import {
  fetchCountries,
  fetchStates,
  fetchCities,
  toggleCountryEnabled,
  toggleStateEnabled,
  toggleCityEnabled,
  fetchStatesMore,
  fetchConfirmedCountries,
  fetchConfirmedStates,
  fetchConfirmedCities,
} from "./locationThunks";

interface InitState {
  countries: {
    data: ICountry[];
    status: BASE_STATUS;
    count: number;
  };
  states: {
    data: IState[];
    status: BASE_STATUS;
    count: number;
  };
  cities: {
    data: ICity[];
    status: BASE_STATUS;
    count: number;
  };
  confirmed: {
    countries: {
      data: ICountry[];
      status: BASE_STATUS;
      count: number;
    };
    states: {
      data: IState[];
      status: BASE_STATUS;
      count: number;
    };
    cities: {
      data: ICity[];
      status: BASE_STATUS;
      count: number;
    };
  };
}

const initState: InitState = {
  countries: {
    data: [],
    status: "idle",
    count: 0,
  },
  states: {
    data: [],
    status: "idle",
    count: 0,
  },
  cities: {
    data: [],
    status: "idle",
    count: 0,
  },
  confirmed: {
    countries: {
      data: [],
      status: "idle",
      count: 0,
    },
    states: {
      data: [],
      status: "idle",
      count: 0,
    },
    cities: {
      data: [],
      status: "idle",
      count: 0,
    },
  },
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    //   fetchCountries
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.countries.status = "loading";
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries.status = "success";
        state.countries.data = action.payload.body.countries;
        state.countries.count = action.payload.body.count;
      })
      .addCase(fetchCountries.rejected, (state) => {
        state.countries.status = "fail";
      });
    //   fetchStates
    builder
      .addCase(fetchStates.pending, (state) => {
        state.states.status = "loading";
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.states.status = "success";
        state.states.data = action.payload.body.states;
        state.states.count = action.payload.body.count;
      })
      .addCase(fetchStates.rejected, (state) => {
        state.states.status = "fail";
      })
      .addCase(fetchStatesMore.pending, (state) => {
        state.states.status = "loading";
      })
      .addCase(fetchStatesMore.fulfilled, (state, action) => {
        state.states.status = "success";
        state.states.data = [...state.states.data, ...action.payload.body.states];
      })
      .addCase(fetchStatesMore.rejected, (state) => {
        state.states.status = "fail";
      });

    //fetchCities
    builder
      .addCase(fetchCities.pending, (state) => {
        state.cities.status = "loading";
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities.status = "success";
        state.cities.data = action.payload.body.cities;
        state.cities.count = action.payload.body.count;
      })
      .addCase(fetchCities.rejected, (state) => {
        state.cities.status = "fail";
      });

    // toggleCountryEnabled
    builder
      .addCase(toggleCountryEnabled.pending, (state) => {
        state.countries.status = "loading";
      })
      .addCase(toggleCountryEnabled.fulfilled, (state, action) => {
        state.countries.status = "success";
        state.countries.data = state.countries.data.map((country) => {
          if (country.isoCode === action.payload.body.isoCode) {
            return action.payload.body;
          }
          return country;
        });
        toastSuccess("Success");
      })
      .addCase(toggleCountryEnabled.rejected, (state) => {
        state.countries.status = "fail";
        toastError("Error");
      });

    // toggle states
    builder
      .addCase(toggleStateEnabled.pending, (state) => {
        state.states.status = "loading";
      })
      .addCase(toggleStateEnabled.fulfilled, (state, action) => {
        state.states.status = "success";
        state.states.data = state.states.data.map((state) => {
          if (state.isoCode === action.payload.body.isoCode) {
            return action.payload.body;
          }
          return state;
        });
        toastSuccess("Success");
      })
      .addCase(toggleStateEnabled.rejected, (state) => {
        state.states.status = "fail";
        toastError("Error");
      });

    // toggle cities
    builder
      .addCase(toggleCityEnabled.pending, (state) => {
        state.cities.status = "loading";
      })
      .addCase(toggleCityEnabled.fulfilled, (state, action) => {
        state.cities.status = "success";
        state.cities.data = state.cities.data.map((city) => {
          if (city.name === action.payload.body.name) {
            return action.payload.body;
          }
          return city;
        });
        toastSuccess("Success");
      })
      .addCase(toggleCityEnabled.rejected, (state) => {
        state.cities.status = "fail";
        toastError("Error");
      });

    //   fetchConfirmedCountries
    builder
      .addCase(fetchConfirmedCountries.pending, (state) => {
        state.confirmed.countries.status = "loading";
      })
      .addCase(fetchConfirmedCountries.fulfilled, (state, action) => {
        state.confirmed.countries.status = "success";
        state.confirmed.countries.data = action.payload.body.countries;
        state.confirmed.countries.count = action.payload.body.count;
      })
      .addCase(fetchConfirmedCountries.rejected, (state) => {
        state.confirmed.countries.status = "fail";
      });

    builder
      .addCase(fetchConfirmedStates.pending, (state) => {
        state.confirmed.states.status = "loading";
      })
      .addCase(fetchConfirmedStates.fulfilled, (state, action) => {
        state.confirmed.states.status = "success";
        state.confirmed.states.data = action.payload.body.states;
        state.confirmed.states.count = action.payload.body.count;
      })
      .addCase(fetchConfirmedStates.rejected, (state) => {
        state.confirmed.states.status = "fail";
      });

    builder
      .addCase(fetchConfirmedCities.pending, (state) => {
        state.confirmed.cities.status = "loading";
      })
      .addCase(fetchConfirmedCities.fulfilled, (state, action) => {
        state.confirmed.cities.status = "success";
        state.confirmed.cities.data = action.payload.body.cities;
        state.confirmed.cities.count = action.payload.body.count;
      })
      .addCase(fetchConfirmedCities.rejected, (state) => {
        state.confirmed.cities.status = "fail";
      });
  },
});

export default locationsSlice.reducer;

export const selectCountries = (state: RootState) => state.location.countries;
export const selectStates = (state: RootState) => state.location.states;
export const selectCities = (state: RootState) => state.location.cities;
export const selectConfirmedLocatin = (state: RootState) => state.location.confirmed;
