export const locationBuilder = (location: {
  state: string | undefined;
  city: string | undefined;
}) => {
  if (location.state && location.city) {
    return `${location.city}, ${location.state}`;
  } else if (location.city && !location.state) {
    return `${location.city}`;
  }
};

export const newLocationBuilder = ({
  country,
  state,
}: {
  country: string | string[];
  state: string;
}) => {
  let locationString = "";
  if (typeof country === "string") {
    if (country === "all") {
      locationString = "Global";
    }

    if (country !== "all" && (state === "all" || state === "")) {
      locationString = country;
    }

    if (country !== "all" && state !== "all" && state !== "") {
      locationString = `${country}, ${state}`;
    }
  }

  if (typeof country === "object" && country.length === 1) {
    if (country[0] === "all") {
      locationString = "Global";
    }

    if (country[0] !== "all" && (state === "all" || state === "")) {
      locationString = country[0];
    }

    if (country[0] !== "all" && state !== "all" && state !== "") {
      locationString = `${country[0]}, ${state}`;
    }
  }

  if (typeof country === "object" && country.length > 1) {
    // for (let index = 0; index < country.length; index++) {
    //   const element = country[index];
    //   if (index === country.length - 1) {
    //     locationString = locationString.concat(`${element}`);
    //   } else if (element === "") {
    //   } else {
    //     locationString = locationString.concat(`${element}, `);
    //   }
    // }
    locationString = country.filter((element) => element !== "").join(", ");
  }

  return locationString;
};
