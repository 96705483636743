import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { Box, Card, CardHeader, Stack, MenuItem, Select } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
// components
import { BaseOptionChart } from "../../../components/chart";
import { useAppDispatch } from "../../../core/redux/hooks";

// ----------------------------------------------------------------------

AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  normalHeight: PropTypes.bool,
  fetchData: PropTypes.func | undefined,
  chartData: PropTypes.array.isRequired,
  periods: PropTypes.bool,
  defaultProjection: PropTypes.string | undefined,
};

export default function AppConversionRates({
  title,
  subheader,
  chartData,
  normalHeight,
  periods,
  fetchData,
  defaultProjection,
  ...other
}) {
  const dispatch = useAppDispatch();
  const chartLabels = chartData.map((i) => i.label);
  const chartSeries = chartData.map((i) => i.value);
  const [projection, setProjection] = useState(
    defaultProjection ? defaultProjection : "30-days"
  );

  const handleProjection = (e) => {
    setProjection(e.target.value);
  };

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => "",
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "70%",
        borderRadius: 2,
      },
    },
    legend: {
      show: false, // Set this to false to hide the legend
    },
    xaxis: {
      categories: chartLabels,
    },
  });

  useEffect(() => {
    if (fetchData) {
      dispatch(fetchData(projection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projection]);

  useEffect(() => {
    if (defaultProjection) {
      setProjection(defaultProjection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultProjection]);

  return (
    <Card {...other}>
      {periods ? (
        <Stack direction="row" justifyContent="space-between">
          <CardHeader title={title} subheader={subheader} />

          <Box sx={{ padding: "12px" }}>
            <Select value={projection} onChange={handleProjection}>
              <MenuItem value={"all"}>In months</MenuItem>
              <MenuItem value={"90-days"}>last 90 days</MenuItem>
              <MenuItem value={"30-days"}>last 30 days</MenuItem>
              <MenuItem value={"14-days"}>last 14 days</MenuItem>
              <MenuItem value={"7-days"}>last 7 days</MenuItem>
            </Select>
          </Box>
        </Stack>
      ) : (
        <CardHeader title={title} subheader={subheader} />
      )}

      <Box
        sx={{
          mx: 3,
          overflowY: normalHeight ? "hidden" : "scroll",
          maxHeight: 364,
        }}
        dir="ltr"
      >
        <ReactApexChart
          type="bar"
          series={[{ data: chartSeries }]}
          options={chartOptions}
          height={normalHeight ? 364 : "2000%"}
        />
      </Box>
    </Card>
  );
}
