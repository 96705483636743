import { Tooltip, IconButton, Box } from "@mui/material";
import React, { ReactNode, Dispatch, SetStateAction } from "react";

interface IconButtonHiddenProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  icon: ReactNode;
  title: string;
  colorError?: true;
  disabled?: boolean;
}

const IconButtonHidden: React.FC<IconButtonHiddenProps> = ({ disabled, open, setOpen, icon, title, colorError }) => (
  <Tooltip title={title} placement="right">
    <Box width={50}>
      <IconButton
        sx={{
          transition: "all 500ms",
          borderRadius: 0,
          borderBottom: open ? (colorError ? "2px solid red" : "2px solid #239f5e") : "2px solid #239f5f00",
        }}
        disabled={disabled}
        color={open ? (colorError ? "error" : "primary") : "default"}
        onClick={() => setOpen(!open)}
      >
        {icon}
      </IconButton>
    </Box>
  </Tooltip>
);

export default IconButtonHidden;
