/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import {
  toastError,
  toastSuccess,
} from "../../../../components/CustomToastify";
import { CompanyData } from "../../../types/company.types";
import { RootState } from "../../store";
import {
  fetchCompanies,
  deleteCompany,
  createCompany,
  updateCompany,
  fetchNewCompanies,
  addBlogPost,
  fetchCompaniesWithEvents,
  updateRank,
} from "./companyThunks";
import {
  createCompanyReview,
  deleteReview,
  updateReview,
} from "./reviewThunks";

export type CompanyArray = Array<CompanyData>;

interface CompanyState {
  status: "idle" | "loading" | "success" | "fail";
  serach: {
    serachData: CompanyArray;
    serachStatus: "idle" | "loading" | "success" | "fail";
  };
  data: CompanyArray;
  newCompanies: CompanyArray;
}

const initState: CompanyState = {
  status: "idle",
  data: [],
  newCompanies: [],
  serach: {
    serachData: [],
    serachStatus: "idle",
  },
};

const companySlice = createSlice({
  name: "company",
  initialState: initState,
  reducers: {
    SearchCompany(state, action: { payload: string }) {
      state.serach.serachData = [];
      state.serach.serachStatus = "loading";
      state.data.map((i) => {
        if (
          i.name
            .toLocaleLowerCase()
            .lastIndexOf(action.payload.toLocaleLowerCase().trim()) + 1
        ) {
          return state.serach.serachData.push(i);
        }
        return i;
      });
      state.serach.serachStatus = "success";
      if (state.serach.serachData.length) {
        toastSuccess(`The company found ${state.serach.serachData.length}`);
      } else {
        toastError("No company found");
      }
    },
    ResetSearchCompany(state) {
      state.serach.serachData = [];
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchCompanies.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.data = action.payload.body;
        state.status = "success";
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = "fail";
        toastError("Something went wrong ❌");
      });

    builder
      .addCase(fetchCompaniesWithEvents.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCompaniesWithEvents.fulfilled, (state, action) => {
        state.data = action.payload.body;
        state.status = "success";
        localStorage.setItem(
          "cachedCompaniesWithEvents",
          JSON.stringify(action.payload.body)
        );
      })
      .addCase(fetchCompaniesWithEvents.rejected, (state, action) => {
        state.status = "fail";
        toastError("Something went wrong ❌");
      });

    builder
      .addCase(fetchNewCompanies.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchNewCompanies.fulfilled, (state, action) => {
        state.newCompanies = action.payload.body;
        state.status = "success";
      })
      .addCase(fetchNewCompanies.rejected, (state, action) => {
        state.status = "fail";
        toastError("Something went wrong ❌");
      });

    builder
      .addCase(deleteCompany.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteCompany.fulfilled, (state: any, action) => {
        state.status = "success";
        toastSuccess("Company deleted ✅");
        state.data = state.data.filter(
          (item: any) => item._id !== action.payload.body
        );
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.status = "fail";
      });
    builder
      .addCase(createCompany.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createCompany.fulfilled, (state: any, action) => {
        state.data = [...state.data, action.payload.body];
        state.status = "success";
        toastSuccess("Company created ✅");
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.status = "fail";
        toastError("Something went wrong ❌");
      });
    builder
      .addCase(updateCompany.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateCompany.fulfilled, (state: any, action) => {
        state.status = "success";
        toastSuccess("Company edited ✅");

        state.data = state.data.map((item: any) => {
          if (item._id === action.payload.body._id) {
            item = action.payload.body;
          }
          return item;
        });
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.status = "fail";
        toastError("Something went wrong ❌");
      });

    builder
      .addCase(updateRank.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateRank.fulfilled, (state: any, action) => {
        state.status = "success";
        toastSuccess("Company rank updated ✅");

        state.data = state.data.map((item: any) => {
          if (item._id === action.payload.body._id) {
            item = action.payload.body;
          }
          return item;
        });
      })
      .addCase(updateRank.rejected, (state, action) => {
        state.status = "fail";
        toastError("Something went wrong ❌");
      });

    //reviews
    builder
      .addCase(createCompanyReview.pending, (state) => {
        state.status = "loading";
      })

      .addCase(createCompanyReview.fulfilled, (state, action) => {
        state.status = "success";
        toastSuccess("Review Created ✅. It will show up soon");
        state.data = state.data.map((c) => {
          if (c._id === action.payload.body._id) {
            return (c = action.payload.body);
          }
          return c;
        });
      })
      .addCase(createCompanyReview.rejected, (state) => {
        state.status = "fail";
        toastError("Something went wrong ❌");
      });

    builder
      .addCase(updateReview.pending, (state) => {
        state.status = "loading";
      })

      .addCase(updateReview.fulfilled, (state, action) => {
        state.status = "success";
        toastSuccess("Review updated ✅. It will show up soon");

        state.data = state.data.map((c) => {
          if (c._id === action.payload.body._id) {
            return (c = action.payload.body);
          }
          return c;
        });
      })
      .addCase(updateReview.rejected, (state) => {
        state.status = "fail";
        toastError("Something went wrong ❌");
      });

    builder
      .addCase(deleteReview.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteReview.fulfilled, (state, action) => {
        state.status = "success";
        toastSuccess("Review deleted✅ It'll disappear soon");

        state.data = state.data.map((c) => {
          if (c._id === action.payload.body._id) {
            return (c = action.payload.body);
          }
          return c;
        });
      })
      .addCase(deleteReview.rejected, (state) => {
        state.status = "fail";
        toastError("Something went wrong ❌");
      });
  },
});

export default companySlice.reducer;
export const selectCompanies = (state: RootState) => state.company.data;
export const selectNewCompanies = (state: RootState) =>
  state.company.newCompanies;
export const selectCompanyBySlug = (
  state: RootState,
  slug: string | undefined | string[]
) => state.company?.data?.find((c) => c.slug === slug);

export const selectCompanyById = (
  state: RootState,
  id: string | undefined | string[]
) => state.company?.data?.find((c) => c._id === id);

export const selectCompanyStatus = (state: RootState) => state.company.status;

export const serachDataCompany = (state: RootState) => state.company.serach;

export const { SearchCompany, ResetSearchCompany } = companySlice.actions;
