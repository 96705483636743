import { createSlice } from "@reduxjs/toolkit";
import {
  InsuranceType,
  InvestingType,
  LoanType,
  PaymentType,
} from "../../../types/payment.types";
import { RootState } from "../../store";
import {
  CreateProvider,
  DeleteProvider,
  fetchProvider,
  UpdateProvider,
} from "./paymentThunks";

interface InitState {
  providerStatus: "idle" | "success" | "loading" | "fail";
  providerData: Array<PaymentType | InsuranceType | LoanType | InvestingType>;
}

const initState: InitState = {
  providerStatus: "idle",
  providerData: [],
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: initState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProvider.pending, (state) => {
        state.providerStatus = "loading";
      })
      .addCase(fetchProvider.fulfilled, (state, action) => {
        state.providerStatus = "success";
        state.providerData = action.payload.body;
      })
      .addCase(fetchProvider.rejected, (state) => {
        state.providerStatus = "fail";
      });

    builder
      .addCase(CreateProvider.pending, (state) => {
        state.providerStatus = "loading";
      })
      .addCase(CreateProvider.fulfilled, (state, action) => {
        state.providerStatus = "success";
        state.providerData = [action.payload.body, ...state.providerData];
      })
      .addCase(CreateProvider.rejected, (state) => {
        state.providerStatus = "fail";
      });

    builder
      .addCase(UpdateProvider.pending, (state) => {
        state.providerStatus = "loading";
      })
      .addCase(UpdateProvider.fulfilled, (state, action) => {
        state.providerStatus = "success";
        state.providerData = state.providerData.filter(
          (i) => i._id !== action.payload.body._id
        );
        state.providerData = [action.payload.body, ...state.providerData];
      })
      .addCase(UpdateProvider.rejected, (state) => {
        state.providerStatus = "fail";
      });

    builder
      .addCase(DeleteProvider.pending, (state) => {
        state.providerStatus = "loading";
      })
      .addCase(DeleteProvider.fulfilled, (state, action) => {
        state.providerStatus = "success";
        state.providerData = state.providerData.filter(
          (i) => i._id !== action.payload.body
        );
      })
      .addCase(DeleteProvider.rejected, (state) => {
        state.providerStatus = "fail";
      });
  },
});

export default paymentSlice.reducer;
export const selectAllProviders = (state: RootState) => state.payment;
