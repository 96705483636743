// material
import { Avatar, Box, Card, Typography, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";

// ----------------------------------------------------------------------

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function WrapCards() {
  return (
    <Card sx={{ height: "auto", marginBottom: "10px", width: "auto" }}>
      {" "}
      {/* // 69% */}
      <Box p="23px " display={"flex"} flexDirection="column" gap={3}>
        <Box>
          <Avatar
            style={{ width: "90px", height: "90px", objectFit: "cover" }}
            src="https://company.gamehag.com/img/title-logo.png"
          />
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" p="0px 10px">
          <Typography color="rgb(35, 159, 94)" variant="h5">
            Gamer
          </Typography>
          <Checkbox {...label} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
        </Stack>
        <Box display={"flex"} justifyContent="space-between" mb="10px">
          <Box display={"flex"} gap="25px">
            <Typography fontWeight="700">gamehag</Typography>
            <ul>
              <li style={{ color: "rgb(35, 159, 94)", fontSize: "14px" }}>
                USA , Star Valley Ranch
              </li>
            </ul>
          </Box>
          <Box display={"flex"} gap="25px">
            <Typography fontWeight="700">Posted 10 days ago</Typography>
            <ul>
              <li style={{ color: "rgb(35, 159, 94)", fontSize: "14px" }}>0 Applicants</li>
            </ul>
          </Box>
        </Box>
        <Box display={"flex"} gap="80px">
          <Box display={"flex"} flexDirection={"column"}>
            <Typography textAlign={"center"} fontSize="13px" color={"rgb(35, 159, 94)"}>
              Compensation
            </Typography>
            <Typography fontSize="14px" fontWeight="500">
              $1 - $10 per task
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography textAlign={"center"} fontSize="13px" color={"rgb(35, 159, 94)"}>
              Job Type
            </Typography>
            <Typography fontSize="14px" fontWeight="500">
              On-demand
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography textAlign={"center"} fontSize="13px" color={"rgb(35, 159, 94)"}>
              Experience
            </Typography>
            <Typography fontSize="14px" fontWeight="500">
              No Experience
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography textAlign={"center"} fontSize="13px" color={"rgb(35, 159, 94)"}>
              Job Frequency
            </Typography>
            <Typography fontSize="14px" fontWeight="500">
              10-20 hours a week
            </Typography>
          </Box>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap="40px" mb={2}>
          <Box mt="15px" display={"flex"} flexDirection={"column"} gap="13px">
            <Typography fontWeight="600">Job Overview</Typography>
            <Typography>
              Play games you're playing every day and get more than entertainment from them. Collect
              soul gems by performing quests and interacting with the community. Exchange them for
              game keys, CS: GO, Riot Points, and other valuable rewards.
            </Typography>
          </Box>
          <Box mt="15px" display={"flex"} flexDirection={"column"} gap="13px">
            <Typography fontWeight="600">Job Description</Typography>
            <Typography>
              Play games you're playing every day and get more than entertainment from them. Collect
              soul gems by performing quests and interacting with the community. Exchange them for
              game keys, CS: GO, Riot Points, and other valuable rewards.
            </Typography>
          </Box>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap="15px" mb={2}>
          <Typography fontWeight="600">Roles and Responsibilities</Typography>
          <ul
            style={{
              paddingLeft: "15px",
              color: "rgb(35, 159, 94)",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <li>Playing games</li>
            <li>Using mobile apps</li>
            <li>Providing opinion</li>
            <li>Collecting rewards</li>
            <li>Performing quests</li>
            <li>Interacting with the community</li>
            <li>Creating content (optional)</li>
          </ul>
        </Box>

        <Box>
          <Typography fontWeight="600" mb={2}>
            Required Skills
          </Typography>
          <Box display="flex" gap="10px" mb={1.6}>
            <Typography bgcolor="rgba(0, 0, 0, 0.08)" p={1} borderRadius="5px">
              Computer Proficiency
            </Typography>
            <Typography bgcolor="rgba(0, 0, 0, 0.08)" p={1} borderRadius="5px">
              Foreign Languages
            </Typography>
            <Typography bgcolor="rgba(0, 0, 0, 0.08)" p={1} borderRadius="5px">
              Opinion Provision
            </Typography>
          </Box>
          <Box display="flex" gap="10px">
            <Typography bgcolor="rgba(0, 0, 0, 0.08)" p={1} borderRadius="5px">
              Communication Skills
            </Typography>
            <Typography bgcolor="rgba(0, 0, 0, 0.08)" p={1} borderRadius="5px">
              Self-motivation
            </Typography>
            <Typography bgcolor="rgba(0, 0, 0, 0.08)" p={1} borderRadius="5px">
              Testing Skills
            </Typography>
            <Typography bgcolor="rgba(0, 0, 0, 0.08)" p={1} borderRadius="5px">
              Gaming
            </Typography>
          </Box>
        </Box>

        <Button sx={{ bgcolor: "rgb(35, 159, 94)", color: "#fff" }}>Apply for this Job</Button>
      </Box>
    </Card>
  );
}
