import React from "react";
import { Container, Stack, Typography, Box } from "@mui/material";
import Page from "../components/Page";
import { AllCards, WrapCards, MyLoader } from "../sections/@dashboard/viewJobs";

export default function ViewJobs() {
  return (
    <Page title="Job View">
      <Container maxWidth="xl" sx={{ mt: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4">Showing all jobs</Typography>
          <Typography variant="h6" color="rgb(35, 159, 94)">
            Show My Jobs
          </Typography>
        </Stack>
        <Box
          display="flex"
          // justifyContent={"space-between"}
          gap={{ sm: 10, lg: 31 }}
          flexDirection={{ xs: "column", sm: "column", md: "column", lg: "row" }}
          justifyContent={"center"}
          alignItems={{ xs: "center", sm: "center", md: "center", lg: "flex-start" }}
        >
          <Box
            display={"flex"}
            flexDirection="column"
            alignItems={{ xs: "center", sm: "center", md: "center", lg: "flex-start" }}
            gap={2.7}
            order={{ xs: 2, sm: 2, md: 2, lg: 0 }}
          >
            {AllJobs.map((i) => (
              <AllCards
                key={i.id}
                title={i.title}
                discription={i.discription}
                compeNstation={i.compeNstation}
                jobType={i.jobType}
              />
            ))}
          </Box>
          <WrapCards />
        </Box>
        <MyLoader />
      </Container>
    </Page>
  );
}

const AllJobs = [
  {
    id: Math.random(),
    title: "Gamer",
    discription: "gamehag",
    compeNstation: "$1 - $10 per task",
    jobType: "On-demand",
  },
  {
    id: Math.random(),
    title: "Gamer v1",
    discription: "gamehag",
    compeNstation: "$1 - $10 per task",
    jobType: "On-demand",
  },
  {
    id: Math.random(),

    title: "Gamer",
    discription: "gamehag",
    compeNstation: "$1 - $10 per task",
    jobType: "On-demand",
  },
  {
    id: Math.random(),

    title: "Gamer",
    discription: "gamehag",
    compeNstation: "$1 - $10 per task",
    jobType: "On-demand",
  },
  {
    id: Math.random(),

    title: "Gamer",
    discription: "gamehag",
    compeNstation: "$1 - $10 per task",
    jobType: "On-demand",
  },
  {
    id: Math.random(),

    title: "Gamer",
    discription: "gamehag",
    compeNstation: "$1 - $10 per task",
    jobType: "On-demand",
  },
  // {
  //   title: "Gamer",
  //   discription: "gamehag",
  //   compeNstation: "$1 - $10 per task",
  //   jobType: "On-demand",
  // },
];
