import * as React from "react";
import Box from "@mui/material/Box";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

type Props = {
  onSubmit: () => void;
  children: React.ReactNode;
};

const ConfirmPopper: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const handleClick = (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleSubmit = () => {
    props.onSubmit();
    setOpen(false);
  };
  return (
    <Box sx={{ width: 500 }}>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ width: "250px", padding: "20px 10px", bgcolor: "coral", color: "white" }}>
              <Typography variant="h5" mb={"15px"}>
                Are you sure ?
              </Typography>
              <Box display={"flex"} alignItems="center" justifyContent={"space-between"}>
                <Button onClick={() => setOpen(false)} variant="outlined">
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                  Confirm
                </Button>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Button onClick={handleClick("auto")}>{props.children}</Button>
    </Box>
  );
};

export default ConfirmPopper;
