import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";

export const fetchCompanies = createAsyncThunk(
  "company/fetchCompanies",
  async () => {
    const res = await request.get(`api/v1/company`);
    return res.data;
  }
);

export const fetchCompaniesWithEvents = createAsyncThunk(
  "company/fetchCompaniesWithEvents",
  async () => {
    const res = await request.get(`api/v1/company/with-events`);
    return res.data;
  }
);

export const fetchNewCompanies = createAsyncThunk(
  "company/fetchNewCompanies",
  async () => {
    const res = await request.get(`api/v1/company/new/global`);
    // console.log(res);
    return res.data;
  }
);

export const deleteCompany = createAsyncThunk(
  "company/deleteCompany",
  async (id: string) => {
    const res = await request.delete(`api/v1/company/${id}`);
    return res.data;
  }
);

export const createCompany = createAsyncThunk(
  "company/createCompany",
  async (data: {
    name: string;
    logo: string;
    companyUrl: string;
    country: string;
    revenue: { ammount: number; currency: string };
    description: string;
    facebook?: string;
    twitter?: string;
    linkedin?: string;
    instagram?: string;
    youtube?: string;
    tiktok?: string;
    reddit?: string;
    discord?: string;
    whatsapp?: string;
    telegram?: string;
  }) => {
    const res = await request.post(`api/v1/company`, data);
    return res.data;
  }
);

export const updateCompany = createAsyncThunk(
  "company/putCompany",
  async (data: {
    body: {
      name: string;
      logo: string;
      companyUrl: string;
      country: string;
      revenue: { ammount: number; currency: string };
      description: string;
      facebook?: string;
      twitter?: string;
      linkedin?: string;
      instagram?: string;
      youtube?: string;
      tiktok?: string;
      reddit?: string;
      discord?: string;
      whatsapp?: string;
      telegram?: string;
    };
    id: string;
  }) => {
    const res = await request.patch(`api/v1/company/${data.id}`, data.body);
    return res.data;
  }
);

export const updateRank = createAsyncThunk(
  "company/updateRank",
  async ({ rank, companyId }: { rank: number; companyId: string }) => {
    const res = await request.patch(
      `api/v1/company/rank/update?rank=${rank}&companyId=${companyId}`
    );
    return res.data;
  }
);

/*
------------------------------Company Blog Posts-----------------------------
*/
export const addBlogPost = createAsyncThunk(
  "company/addBlogPost",
  async (req: { link: string; companyId: string }) => {
    const res = await request.post(
      `/api/v1/company/blog-post/add?link=${req.link}&companyId=${req.companyId}`
    );
    return res.data;
  }
);

export const removeBlogPost = createAsyncThunk(
  "company/removeBlogPost",
  async (req: { link: string; companyId: string }) => {
    const res = await request.post(
      `/api/v1/company/blog-post/?link=${req.link}&companyId=${req.companyId}`
    );
    return res.data;
  }
);
