import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, Select, MenuItem } from "@mui/material";
// components
import { BaseOptionChart } from "../../../components/chart";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../core/redux/hooks";
import { selectUserAnalyticsByCountry } from "../../../core/redux/features/stats/statsSlice";

// ----------------------------------------------------------------------

export default function UsersByCountryChart() {
  const userAnalyticsByCountry = useAppSelector(selectUserAnalyticsByCountry);

  const combinedData: {
    [country: string]: { count: number; date: string }[];
  }[] = [];

  userAnalyticsByCountry.forEach((item) => {
    item.countryAnalytics.forEach((countryData) => {
      const existingCountry = combinedData.find(
        (entry) => Object.keys(entry)[0] === countryData.country
      );

      if (existingCountry) {
        existingCountry[countryData.country].push({
          count: countryData.count,
          date: item.date,
        });
      } else {
        const newEntry = {
          [countryData.country]: [
            { count: countryData.count, date: item.date },
          ],
        };
        combinedData.push(newEntry);
      }
    });
  });

  const countries = combinedData.map((entry) => Object.keys(entry)[0]);

  const [projection, setProjection] = useState("yearly");
  const [activeCountry, setActiveCountry] = useState<string[]>([countries[0]]);
  const [chartLabelsState, setChartLabelsState] = useState<any>();
  const [chartDataState, setChartDataState] = useState<any>();
  const [percentageDifference, setPercentageDifference] = useState([0]);

  const countryData = combinedData.reduce((acc, entry) => {
    const country = Object.keys(entry)[0];
    if (activeCountry.includes(country)) {
      acc[country] = entry[country];
    }
    return acc;
  }, {});

  useEffect(() => {
    if (userAnalyticsByCountry.length !== 0 && countries.length !== 0) {
      setActiveCountry([countries[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnalyticsByCountry]);

  useEffect(() => {
    if (projection === "yearly") {
      const labels: string[] = [];
      const data = activeCountry.map((selectedCountry) => {
        labels.push(...countryData[selectedCountry].map((i) => i.date));
        return {
          name: selectedCountry,
          type: "area",
          fill: "gradient",
          data: countryData[selectedCountry].map((i) => i.count),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    } else if (projection === "90-days") {
      const labels: string[] = [];
      const data = activeCountry.map((selectedCountry) => {
        labels.push(
          ...countryData[selectedCountry].map((i) => i.date).slice(-90)
        );
        return {
          name: selectedCountry,
          type: "area",
          fill: "gradient",
          data: countryData[selectedCountry].map((i) => i.count).slice(-90),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    } else if (projection === "30-days") {
      const labels: string[] = [];
      const data = activeCountry.map((selectedCountry) => {
        labels.push(
          ...countryData[selectedCountry].map((i) => i.date).slice(-30)
        );
        return {
          name: selectedCountry,
          type: "area",
          fill: "gradient",
          data: countryData[selectedCountry].map((i) => i.count).slice(-30),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    } else if (projection === "14-days") {
      const labels: string[] = [];
      const data = activeCountry.map((selectedCountry) => {
        labels.push(
          ...countryData[selectedCountry].map((i) => i.date).slice(-14)
        );
        return {
          name: selectedCountry,
          type: "area",
          fill: "gradient",
          data: countryData[selectedCountry].map((i) => i.count).slice(-14),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    } else if (projection === "7-days") {
      const labels: string[] = [];
      const data = activeCountry.map((selectedCountry) => {
        labels.push(
          ...countryData[selectedCountry].map((i) => i.date).slice(7)
        );
        return {
          name: selectedCountry,
          type: "area",
          fill: "gradient",
          data: countryData[selectedCountry].map((i) => i.count).slice(7),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projection, activeCountry]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-based in JavaScript
    const lastMonth = currentDate.getMonth(); // Months are 0-based in JavaScript
    const currentYear = currentDate.getFullYear();

    const currentMonthItems = activeCountry.map((selectedCountry) =>
      countryData[selectedCountry].filter((item) => {
        const itemDate = new Date(item.date);
        return (
          itemDate.getMonth() + 1 === currentMonth &&
          itemDate.getFullYear() === currentYear
        );
      })
    );

    const lastMonthItems = activeCountry.map((selectedCountry) =>
      countryData[selectedCountry].filter((item) => {
        const itemDate = new Date(item.date);
        return (
          itemDate.getMonth() + 1 === lastMonth &&
          itemDate.getFullYear() === currentYear
        );
      })
    );

    const currentReducedCountValue = currentMonthItems.map((item) =>
      item.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
      }, 0)
    );

    const lastReducedCountValue = lastMonthItems.map((item) =>
      item.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
      }, 0)
    );

    const reducedPercentageDifference = [];

    for (let index = 0; index < activeCountry.length; index++) {
      const current = currentReducedCountValue[index];
      const last = lastReducedCountValue[index];

      const result = ((current - last) / last) * 100;
      reducedPercentageDifference.push(result);
    }

    setPercentageDifference(reducedPercentageDifference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCountry]);

  const chartOptions: any = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartDataState?.map((i: any) => i.fill) },
    labels: chartLabelsState,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} users`;
          }
          return y;
        },
      },
    },
  });

  const handleProjection = (e: any) => {
    setProjection(e.target.value);
  };

  const handleActiveCountry = (e: any) => {
    setActiveCountry(e.target.value);
  };

  return (
    <Card>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        padding="10px"
      >
        {/* <CardHeader
          subheader={`(${dashboard2.signUps.monthly.change}) than last month `}
          title={"Users By Country"}
        /> */}

        {percentageDifference.length === 1 && (
          <CardHeader
            subheader={`(${percentageDifference[0]}%) since last month `}
            title={"Signups By Country"}
          />
        )}

        {percentageDifference.length > 1 && (
          <CardHeader
            subheader={`(${activeCountry.map((item, idx) => {
              return `${item} : ${percentageDifference[idx]}%`;
            })}) since last month `}
            title={"Users By Country"}
          />
        )}

        <Stack direction="row" gap="12px">
          <Select value={activeCountry} multiple onChange={handleActiveCountry}>
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
          <Select value={projection} onChange={handleProjection}>
            <MenuItem value={"yearly"}>In months</MenuItem>
            <MenuItem value={"90-days"}>last 90 days</MenuItem>
            <MenuItem value={"30-days"}>last 30 days</MenuItem>
            <MenuItem value={"14-days"}>last 14 days</MenuItem>
            <MenuItem value={"7-days"}>last 7 days</MenuItem>
          </Select>
        </Stack>
      </Stack>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {chartDataState && (
          <ReactApexChart
            type="line"
            series={chartDataState}
            options={chartOptions}
            height={364}
            width={"100%"}
          />
        )}
      </Box>
    </Card>
  );
}

const dummyData = [
  {
    _id: "6535dac6f89ca30d8b205045",
    updatedAt: 1698028230170,
    createdAt: 1698028230170,
    countryAnalytics: [
      {
        country: "Nigeria",
        count: 1,
      },
      {
        country: "Sierra Leone",
        count: 9,
      },
    ],
    year: 2023,
    month: 11,
    day: 23,
    date: "11/23/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205054",
    updatedAt: 1698028230107,
    createdAt: 1698028230107,
    countryAnalytics: [
      {
        country: "Nigeria",
        count: 5,
      },
      {
        country: "Sierra Leone",
        count: 3,
      },
    ],
    year: 2023,
    month: 11,
    day: 24,
    date: "11/24/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205046",
    updatedAt: 1698028230171,
    createdAt: 1698028230171,
    countryAnalytics: [
      {
        country: "Nigeria",
        count: 2,
      },
      {
        country: "Sierra Leone",
        count: 1,
      },
    ],
    year: 2023,
    month: 10,
    day: 23,
    date: "10/23/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205047",
    updatedAt: 1698028230172,
    createdAt: 1698028230172,
    countryAnalytics: [
      {
        country: "Sierra Leone",
        count: 2,
      },
      {
        country: "Nigeria",
        count: 3,
      },
    ],
    year: 2023,
    month: 9,
    day: 23,
    date: "09/23/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205048",
    updatedAt: 1698028230173,
    createdAt: 1698028230173,
    countryAnalytics: [
      {
        country: "Nigeria",
        count: 4,
      },
      {
        country: "Sierra Leone",
        count: 5,
      },
    ],
    year: 2023,
    month: 8,
    day: 23,
    date: "08/23/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205049",
    updatedAt: 1698028230174,
    createdAt: 1698028230174,
    countryAnalytics: [
      {
        country: "Sierra Leone",
        count: 3,
      },
      {
        country: "Nigeria",
        count: 2,
      },
    ],
    year: 2023,
    month: 7,
    day: 23,
    date: "07/23/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205050",
    updatedAt: 1698028230175,
    createdAt: 1698028230175,
    countryAnalytics: [
      {
        country: "Nigeria",
        count: 1,
      },
      {
        country: "Sierra Leone",
        count: 4,
      },
    ],
    year: 2023,
    month: 6,
    day: 23,
    date: "06/23/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205051",
    updatedAt: 1698028230176,
    createdAt: 1698028230176,
    countryAnalytics: [
      {
        country: "Sierra Leone",
        count: 2,
      },
      {
        country: "Nigeria",
        count: 3,
      },
    ],
    year: 2023,
    month: 5,
    day: 23,
    date: "05/23/2023",
  },
];
