import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import DeleteModal from "./UserDelete";

// ----------------------------------------------------------------------

type Props = {
  userInfo: { id: string; name: string; email: string };
};

export default function UserMoreMenu({ userInfo }: Props) {
  const { id } = userInfo;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Iconify
              sx={{ color: "red", cursor: "initial" }}
              icon="eva:trash-2-outline"
              width={24}
              height={24}
            />
          </ListItemIcon>
          <DeleteModal userInfo={userInfo} />
        </MenuItem>

        <MenuItem component={RouterLink} to={`${id}`} sx={{ color: "#239F5E" }}>
          <ListItemIcon>
            <Iconify
              icon="ic:baseline-view-in-ar"
              sx={{ color: "#239F5E" }}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="View"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
