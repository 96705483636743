import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";

/*
------------------------------Country-----------------------------
*/

export const fetchCountries = createAsyncThunk("minor/getCountry", async () => {
  const res = await request.get(`/api/v1/country`);
  return res.data;
});

/*
------------------------------Frequency-----------------------------
*/
export const fetchFrequencies = createAsyncThunk("minor/getFrequency", async () => {
  const res = await request(`/api/v1/job-frequency`);
  return res.data;
});

/*
------------------------------Job Types-----------------------------
*/
export const fetchAllJobTypes = createAsyncThunk("minor/getType", async () => {
  const res = await request.get(`/api/v1/job-type`);
  return res.data;
});

/*
------------------------------Experience-----------------------------
*/
export const fetchExperiences = createAsyncThunk("minor/fetchExperiences", async () => {
  const res = await request.get(`/api/v1/experience`);
  return res.data;
});

/*
------------------------------Category-----------------------------
*/
export const fetchAllCategories = createAsyncThunk("minor/fetchAllCategories", async () => {
  const res = await request.get(`/api/v1/category`);
  return res.data;
});

/*
------------------------------Skill-----------------------------
*/
export const fetchAllSkills = createAsyncThunk("minor/fetchAllSkills", async () => {
  const res = await request.get(`/api/v1/skill`);
  return res.data;
});
