/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { selectAuthStatus } from "../core/redux/features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../core/redux/hooks";

type Props = {
  limit: number;
  skipConstant: number;
  status?: string;
  view?: string;
  slug?: string;
  // fetchAction: (options: {
  //   limit: number;
  //   skip: number;
  //   query?: string;
  //   status?: string;
  // }) => any; //thunk to fetch more data
  fetchAction: (options: any) => any; //thunk to fetch more data
  isEmpty: boolean;
};

export const useFetchMore = (props: Props) => {
  // const [skip, setSkip] = useState(0);
  const [skip, setSkip] = useState({
    regularJobs: 0,
    dynamicJobs: 0,
    pendingJobs: 0,
    rejectedJobs: 0,
    other: 0,
  });
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);

  const getCurrentSkipValue = () => {
    if (props.view === "jobs") {
      return skip.regularJobs;
    }
    if (props.view === "new") {
      return skip.dynamicJobs;
    }
    if (props.view === "pending") {
      return skip.pendingJobs;
    }
    if (props.view === "rejected") {
      return skip.rejectedJobs;
    } else {
      return skip.other;
    }
  };

  const initFetch = useCallback(async () => {
    if (!props.isEmpty) {
      props.status
        ? dispatch(
            props.fetchAction({
              limit: props.limit,
              skip: 0,
              status: props.status,
            })
          )
        : props.slug
        ? dispatch(
            props.fetchAction({
              limit: props.limit,
              skip: 0,
              slug: props.slug as string,
            })
          )
        : dispatch(props.fetchAction({ limit: props.limit, skip: 0 }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (authStatus === "FETCH_SUCCESS" || authStatus === "LOGIN_SUCCESS") {
      initFetch();
    }
  }, [initFetch, authStatus]);

  const handleFetchMore = async () => {
    props.status
      ? await dispatch(
          props.fetchAction({
            limit: props.limit,
            skip: getCurrentSkipValue() + props.skipConstant,
            status: props.status,
          })
        )
      : props.slug
      ? await dispatch(
          props.fetchAction({
            limit: props.limit,
            skip: getCurrentSkipValue() + props.skipConstant,
            slug: props.slug,
          })
        )
      : await dispatch(
          props.fetchAction({
            limit: props.limit,
            skip: getCurrentSkipValue() + props.skipConstant,
          })
        );

    setSkip((prev) => {
      if (!props.view) {
        return {
          ...prev,
          other: prev.other + props.skipConstant,
        };
      }
      if (props.view === "jobs") {
        return {
          ...prev,
          regularJobs: prev.regularJobs + props.skipConstant,
        };
      }
      if (props.view === "new") {
        return {
          ...prev,
          dynamicJobs: prev.dynamicJobs + props.skipConstant,
        };
      }
      if (props.view === "pending") {
        return {
          ...prev,
          pendingJobs: prev.pendingJobs + props.skipConstant,
        };
      }
      if (props.view === "rejected") {
        return {
          ...prev,
          rejectedJobs: prev.rejectedJobs + props.skipConstant,
        };
      } else {
        return prev;
      }
    });
  };

  return {
    skip,
    setSkip,
    handleFetchMore,
  };
};
