import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";

export const getJoinCategory = createAsyncThunk("join/getJoinCategory", async (): Promise<{ body: any }> => {
  const res = await request.get("/api/v1/admin/join-api/categories");
  return res.data;
});

export const getJoinEmployment = createAsyncThunk("join/getJoinEmployment", async (): Promise<{ body: any }> => {
  const res = await request.get("/api/v1/admin/join-api/employment-types");
  return res.data;
});

export const getJoinLevels = createAsyncThunk("join/getJoinLevels", async (): Promise<{ body: any }> => {
  const res = await request.get("/api/v1/admin/join-api/seniority-levels");
  return res.data;
});

export const getJoinoffices  = createAsyncThunk("join/getJoinoffices", async (): Promise<{ body: any }> => {
  const res = await request.get("/api/v1/admin/join-api/offices?page=2&pageSize=20");
  return res.data;
});
