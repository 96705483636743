/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from "@mui/material";
import { createContext, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectAdminData, selectAuthStatus } from "../core/redux/features/auth/authSlice";
import { getAdminData } from "../core/redux/features/auth/authThunk";
import { useAppDispatch, useAppSelector } from "../core/redux/hooks";

const AuthContext = createContext(false);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const admin = useAppSelector(selectAdminData);
  const [auth, setAuth] = useState(admin.isStaff);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectAuthStatus);

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (!admin._id && localToken) {
      dispatch(getAdminData()).then((res: any) => {
        if (res.payload.body.isStaff) {
          setAuth(true);
        } else {
          navigate("/login", { replace: true });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (admin.isStaff) {
      setAuth(true);
    }
    if (authStatus === "FETCH_SUCCESS" && !admin.isStaff) {
      navigate("/login", { replace: true });
    }
  }, [admin]);

  const data = auth;

  return (
    <AuthContext.Provider value={data}>
      {authStatus === "FETCH_LOADING" ? (
        <Box display={"flex"} alignItems="center" justifyContent="center" height={"100vh"}>
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
