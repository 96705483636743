import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async ({ skip, limit }: { skip: number; limit: number }) => {
    const res = await request.get(
      `/api/v1/admin/contact-us/applicants?skip=${skip}&limit=${limit}`
    );
    return res.data;
  }
);

export const fetchMessagesUnread = createAsyncThunk(
  "messages/fetchMessagesUnread",
  async ({ skip, limit }: { skip: number; limit: number }) => {
    const res = await request.get(
      `/api/v1/admin/contact-us/applicants?skip=${skip}&limit=${limit}&unreadOnly=${true}`
    );
    return res.data;
  }
);

export const patchMessages = createAsyncThunk(
  "messages/patchMessages",
  async (id: string[]) => {
    const res = await request.patch("/api/v1/admin/contact-us/applicants", {
      id,
    });
    return res.data;
  }
);

export const deleteMessages = createAsyncThunk(
  "messages/deleteMessages",
  async (id: string) => {
    const res = await request.delete(
      `/api/v1/admin/contact-us/applicant/${id}`
    );
    return res.data;
  }
);

export interface messageType {
  email: string;
  subject: string;
  message: string;
  name: string;
  secondName: string;
  createdAt: string;
  isRead: boolean;
  __v: number;
  _id: string;
}
