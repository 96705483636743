import { useRef, useState } from "react";
import { Link } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  Input,
  InputAdornment,
  Modal,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// component
import Iconify from "../../../components/Iconify";
import { CompanyData } from "../../../core/types/company.types";
import { useAppDispatch } from "../../../core/redux/hooks";
import { deleteCompany } from "../../../core/redux/features/companies/companyThunks";
// ----------------------------------------------------------------------

export default function CompanyMoreMenu(props: { slug: string; company: CompanyData; id: string; name: string }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [values, setValues] = useState<string>();

  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteCompany(props.id));
    handleClose();
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Iconify icon="ic:baseline-view-in-ar" sx={{ color: "#239F5E" }} width={24} height={24} />
          </ListItemIcon>
          <Link style={{ textDecoration: "none" }} to={`/dashboard/company/${props.slug}`}>
            <ListItemText
              sx={{ color: "#239F5E", fontWeight: "900" }}
              primary="View Company"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </Link>
        </MenuItem>
        <MenuItem sx={{ color: "text.secondary" }} onClick={() => setOpen(!open)}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "red", fontWeight: "900" }}
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <Modal open={open} onClose={handleClose}>
          <Stack p="20px" borderRadius={2} width={350} textAlign="center" spacing={2} sx={style}>
            <Typography variant="h6" lineHeight={1} color="GrayText">
              To delete a company, enter the names of the company
            </Typography>
            <Typography variant="h6" color="InfoText">
              {props.name}
            </Typography>
            <Stack>
              <Input
                placeholder="change name company"
                value={values}
                onChange={(e) => setValues(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled={values === props.name ? false : true} onClick={handleDelete}>
                      <DeleteIcon color={values === props.name ? "error" : "action"} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Stack>
          </Stack>
        </Modal>
      </Menu>
    </>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
