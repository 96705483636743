import { createSlice } from "@reduxjs/toolkit";
import {
  CategoryData,
  CountryData,
  ExperienceData,
  FrequencyData,
  JobTypeData,
  SkillData,
} from "../../../types/minor.types";
import { RootState } from "../../store";
import {
  fetchAllCategories,
  fetchAllJobTypes,
  fetchAllSkills,
  fetchCountries,
  fetchExperiences,
  fetchFrequencies,
} from "./minorThunks";

interface InitState {
  country: CountryData;
  frequency: FrequencyData;
  jobTypes: JobTypeData;
  experience: ExperienceData;
  category: CategoryData;
  skill: SkillData;
}

const initState: InitState = {
  country: {
    data: [],
    status: "idle",
  },
  frequency: {
    data: [],
    status: "idle",
  },
  jobTypes: {
    data: [],
    status: "idle",
  },
  experience: {
    data: [],
    status: "idle",
  },
  category: {
    data: [],
    status: "idle",
  },
  skill: {
    data: [],
    status: "idle",
  },
};

const minorSlice = createSlice({
  name: "minor",
  initialState: initState,
  reducers: {},
  extraReducers(builder) {
    //-----------------country-----------------//
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.country.status = "loading";
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.country.status = "success";
        state.country.data = action.payload.body;
      })
      .addCase(fetchCountries.rejected, (state) => {
        state.country.status = "fail";
      });
    //-----------------frequency-----------------//
    builder
      .addCase(fetchFrequencies.pending, (state) => {
        state.frequency.status = "loading";
      })
      .addCase(fetchFrequencies.fulfilled, (state, action) => {
        state.frequency.status = "success";
        state.frequency.data = action.payload.body;
      })
      .addCase(fetchFrequencies.rejected, (state) => {
        state.frequency.status = "fail";
      });
    //-----------------Job Types-----------------//
    builder
      .addCase(fetchAllJobTypes.pending, (state) => {
        state.jobTypes.status = "loading";
      })
      .addCase(fetchAllJobTypes.fulfilled, (state, action) => {
        state.jobTypes.status = "success";
        state.jobTypes.data = action.payload.body;
      })
      .addCase(fetchAllJobTypes.rejected, (state) => {
        state.jobTypes.status = "fail";
      });
    //-----------------experience-----------------//
    builder
      .addCase(fetchExperiences.pending, (state) => {
        state.experience.status = "loading";
      })
      .addCase(fetchExperiences.fulfilled, (state, action) => {
        state.experience.status = "success";
        state.experience.data = action.payload.body;
      })
      .addCase(fetchExperiences.rejected, (state) => {
        state.experience.status = "fail";
      });

    //-----------------category-----------------//
    builder
      .addCase(fetchAllCategories.pending, (state) => {
        state.category.status = "loading";
      })
      .addCase(fetchAllCategories.fulfilled, (state, action) => {
        state.category.status = "success";
        state.category.data = action.payload.body;
      })
      .addCase(fetchAllCategories.rejected, (state) => {
        state.category.status = "fail";
      });

    //-----------------skill-----------------//
    builder
      .addCase(fetchAllSkills.pending, (state) => {
        state.skill.status = "loading";
      })
      .addCase(fetchAllSkills.fulfilled, (state, action) => {
        state.skill.status = "success";
        state.skill.data = action.payload.body;
      })
      .addCase(fetchAllSkills.rejected, (state) => {
        state.skill.status = "fail";
      });
  },
});

export default minorSlice.reducer;

export const selectCountry = (state: RootState) => state.minor.country;
export const selectFrequency = (state: RootState) => state.minor.frequency;
export const selectJobType = (state: RootState) => state.minor.jobTypes;
export const selectExperience = (state: RootState) => state.minor.experience;
export const selectCategory = (state: RootState) => state.minor.category;
export const selectSkill = (state: RootState) => state.minor.skill;
