import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";
import {
  InsuranceFormType,
  InvestingFormType,
  LoanFormType,
  PaymentFormType,
} from "../../../types/payment.types";

export const fetchProvider = createAsyncThunk(
  "payment/fetchPayment",
  async () => {
    const res = await request.get(`/api/v1/payment-providers`);
    return res.data;
  }
);

export const CreateProvider = createAsyncThunk(
  "payment/CreatePayment",
  async (
    data: PaymentFormType | InsuranceFormType | InvestingFormType | LoanFormType
  ) => {
    const res = await request.post(`/api/v1/payment-providers`, data);
    return res.data;
  }
);

export const UpdateProvider = createAsyncThunk(
  "payment/UpdatePayment",
  async (data: {
    body:
      | PaymentFormType
      | InsuranceFormType
      | InvestingFormType
      | LoanFormType;
    id: string;
  }) => {
    const res = await request.patch(
      `/api/v1/payment-providers/${data.id}`,
      data.body
    );
    return res.data;
  }
);

export const DeleteProvider = createAsyncThunk(
  "payment/DeletPayment",
  async (id: string) => {
    const res = await request.delete(`/api/v1/payment-providers/${id}`);
    return res.data;
  }
);
