import React, { Suspense, useEffect, useState } from "react";
// material
import {
  AppBar,
  Button,
  CircularProgress,
  Container,
  Dialog,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
// components
import Page from "../../components/Page";
import { ProductList } from "../../sections/@dashboard/products";
// mock
import { useFetchMore } from "../../hooks/useFetchMore";
import { fetchAllJobs, fetchDynamicJobs, fetchPendingJobs, fetchPendingJobsByStatus } from "../../core/redux/features/jobs/jobThunks";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { selectActiveJobs, selectActiveJobsCount, selectActiveDynamicJobsCount, selectActiveStatusJobsCount, selectJobStatus, selectDynamicJobs, selectPendingJobs, selectPendingJobsByStatus } from "../../core/redux/features/jobs/jobSlice";
import { fShortenNumber } from "../../utils/formatNumber";
import Iconify from "../../components/Iconify";
import CloseIcon from "@mui/icons-material/Close";
// ----------------------------------------------------------------------

const JobFormCity = React.lazy(() => import("../../sections/@dashboard/Jobs/JobFormCity"));

const EcommerceShop = () => {
  // const [openFilter, setOpenFilter] = useState(false);
  const dispatch = useAppDispatch();
  const [view, setView] = useState('jobs')
  const [open, setOpen] = useState(false);
  const [newForm, setNewForm] = useState(false);

  const jobs = useAppSelector(selectActiveJobs);
  const dynamicJobs = useAppSelector(selectDynamicJobs);
  const pendingJobsByStatus = useAppSelector(selectPendingJobsByStatus);
  const jobStatus = useAppSelector(selectJobStatus)
  const [status, setStatus] = useState('jobs')
  const jobCount = useAppSelector(selectActiveJobsCount);
  const dynamicJobCount = useAppSelector(selectActiveDynamicJobsCount);
  const statusJobCount = useAppSelector(selectActiveStatusJobsCount);

  const { handleFetchMore } = useFetchMore({
    limit: view === 'jobs' ? 6 : 12,
    skipConstant: view === 'jobs' ? 6 : 12,
    fetchAction: view === 'jobs' ? fetchAllJobs : view === 'new' ? fetchDynamicJobs : fetchPendingJobsByStatus,
    isEmpty: jobs.length > 0,
    status: view !== 'jobs' && view !== 'new' ? status: undefined, 
    view: view, 
  });

  // const handleOpenFilter = () => {
  //   setOpenFilter(true);
  // };

  // const handleCloseFilter = () => {
  //   setOpenFilter(false);
  // };

  const handlePendingJobs = () => {
    setView('pending')
    setStatus('pending')
  }

  const handleRejectedJobs = () => {
    setView('rejected')
    setStatus('rejected')
  }

  const handleNewJobs = () => {
    setView('new')
    setStatus('new')
  }

  const handleNewForm = () => {
    setOpen(prev => !prev)
    setNewForm(prev => !prev)
  }


  const handleJobCenter = () => {
    setView('jobs')
    setStatus('jobs')
    // dispatch(fetchAllJobs({limit: 12, skip:0}))
  }

  
  const handleRegularForm = () => {
    setOpen(prev => !prev)
    setNewForm(false)
  }

  const getCurrentJobLength = () => {
    if (view === 'jobs') {
      return jobs.length
    }

    if (view === 'new') {
      return dynamicJobs.length
    }

    if (view !== 'jobs' && view !== 'new') {
      return pendingJobsByStatus.length
    }
  }

  const getCurrentActiveJobCount = () => {
    if (view === 'jobs') {
      return jobCount
    }

    if (view === 'new') {
      return dynamicJobCount
    } else {
      return statusJobCount
    }
  }

  useEffect(() => {
    if (status === 'new' && dynamicJobs.length === 0 ) {
      dispatch(fetchDynamicJobs({limit:12, skip: 0}))
    }

    if (status === 'pending' || status === 'rejected') {
      dispatch(fetchPendingJobsByStatus({limit:12, skip: 0, status}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <Page title="Jobs">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {view === 'jobs' ? 'Job Center' : null}
            {view === 'pending' ? 'Pending Jobs' : null}
            {view === 'rejected' ? 'Rejected Jobs' : null}
          </Typography>
          <Stack direction="row" gap={2}>
          {view !== 'jobs' ? <Button variant="contained" startIcon={<Iconify icon="ep:back" />} onClick={handleJobCenter}>
            Back to Job Center
          </Button> : null}

          <Button variant="contained" startIcon={<Iconify icon="ic:outline-pending-actions" />} onClick={handleNewJobs}>
            View Dynamic Jobs
          </Button>

          <Button variant="contained" startIcon={<Iconify icon="ic:outline-pending-actions" />} onClick={handlePendingJobs}>
            View Pending Jobs
          </Button>

          <Button variant="contained" startIcon={<Iconify icon="fluent:text-change-reject-20-filled" />} onClick={handleRejectedJobs}>
            View Rejected Jobs
          </Button>

          <Button variant="contained" startIcon={<Iconify icon="eva:edit-fill" />} onClick={handleRegularForm}>
            Add a New Job
          </Button>

          <Button variant="contained" startIcon={<Iconify icon="eva:edit-fill" />} onClick={handleNewForm}>
            Add New Dynamic Job
          </Button>
          </Stack>
          <Dialog
            fullScreen
            open={open}
            onClose={() => setOpen(!open)}
            TransitionComponent={Transition}
            disableEnforceFocus={true}
            sx={{ zIndex: 1000 }}
          >
            <AppBar sx={{ position: "fixed" }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => setOpen(!open)} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ flex: 1, textAlign: "center" }} variant="h6" component="div">
                  {newForm ? 'ADD DYNAMIC JOB' : 'ADD JOB'}
                </Typography>
              </Toolbar>
            </AppBar>
            <Suspense
              fallback={
                <Box width="100%" height="320px" display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress sx={{ color: "#0e2944" }} />
                </Box>
              }
            >
              <Box pb={4}>{open ? <JobFormCity setOpen={setOpen} newForm={newForm}/> : null}</Box>
            </Suspense>
          </Dialog>


         
        </Stack>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            {/* <ProductFilterSidebar
              isOpenFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            /> */}
            {/* <ProductSort /> */}
          </Stack>
        </Stack>


        {(  view === 'pending') || (  view === 'rejected') ? <ProductList jobs={pendingJobsByStatus} /> : null}
        {  view === 'jobs' ? <ProductList jobs={jobs} /> : null}
        {  view === 'new' ? <ProductList newIndicator jobs={dynamicJobs} /> : null}
        
        {
          jobStatus === 'loading' ? 
            (
              <Box width="100%" sx={{display: 'flex', justifyContent:'center'}}>
                <CircularProgress />
              </Box>
            )
          : 
            null
        }

        <Box display={"flex"} alignItems="center" justifyContent={"center"} height="70px">
          <Button
            variant="contained"
            onClick={handleFetchMore}
            sx={{ textTransform: "none" }}
            disabled={getCurrentActiveJobCount() <= getCurrentJobLength() ? true : false}
          >
            More ({`${getCurrentJobLength()} of ${fShortenNumber(getCurrentActiveJobCount())}`})
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

export default EcommerceShop;
