/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Box, Card, Chip, CircularProgress, Container, Divider, Grow, Stack, Typography } from "@mui/material";
import Page from "../../components/Page";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { request } from "../../utils/Axios";
import { UserByIdType } from "../../core/types/user.types";
import Iconify from "../../components/Iconify";

export default function UserProfile() {
  const { id } = useParams();
  const [user, setUser] = useState<UserByIdType>();

  const handleFetchCompanyJobs = useCallback(async () => {
    const res = await request.get(`api/v1/user/${id}`);
    setUser(res.data);
  }, [id]);

  useEffect(() => {
    id && handleFetchCompanyJobs();
  }, [id]);
  console.log(user);

  return (
    <Page title="User | Profile">
      <Container maxWidth="xl">
        <Typography variant="h4" mb={5}>
          User Profile
        </Typography>

        {user?._id ? (
          <Grow in timeout={500}>
            <Card sx={{ marginBottom: 5, height: "auto" }}>
              <Stack p="16px 23px" spacing={2}>
                <Box display={"flex"} gap="18px">
                  <Avatar sx={{ width: "115px", height: "115px", objectFit: "contain" }} src={user?.avatarUrl || ""} />
                  <Stack pt="5px">
                    <Typography lineHeight="115%" variant="h6">
                      {user?.name} {user?.secondName}
                    </Typography>
                    <Stack direction="row" alignItems="center">
                      <Typography color={user?.isVerrified ? "#239F5E" : "red"} fontWeight="600">
                        Verified
                      </Typography>
                      <Iconify
                        icon={user?.isVerrified ? "material-symbols:verified-outline-rounded" : "ic:round-close"}
                        width={20}
                        height={20}
                        sx={{ color: user?.isVerrified ? "#239F5E" : "red" }}
                      />
                    </Stack>
                    <Typography fontWeight="600" variant="body2">
                      Auth Type: {user?.authType}{" "}
                    </Typography>
                  </Stack>
                </Box>
                <Typography fontWeight="bold" variant="body1" color="#239F5E">
                  BIO
                  <Typography variant="body2" color="initial">
                    {user?.bio ? user.bio : "Biography not added"}
                  </Typography>
                </Typography>
                <Stack gap="5px">
                  <Typography fontWeight="bold" variant="body1" color="#239F5E">
                    Languages
                  </Typography>
                  {user?.languages.length ? (
                    <Stack gap="5px" direction="row" flexWrap="wrap">
                      {user?.languages.map((i) => (
                        <Chip label={i.title} key={i._id} variant="outlined" sx={{ color: "#000" }} />
                      ))}
                    </Stack>
                  ) : (
                    <Typography variant="body2" color="initial">
                      Languages not added
                    </Typography>
                  )}
                </Stack>

                <Stack gap="5px">
                  <Typography fontWeight="bold" variant="body1" color="#239F5E">
                    Skills
                  </Typography>
                  {user?.skills.length ? (
                    <Stack gap="5px" direction="row" flexWrap="wrap">
                      {user?.skills.map((i) => (
                        <Chip label={i.title} key={i._id} variant="outlined" sx={{ color: "#000" }} />
                      ))}
                    </Stack>
                  ) : (
                    <Typography variant="body2" color="initial">
                      Skills not added
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <hr style={{ marginTop: 18, borderColor: "#919eab3d", height: "0px" }} />
              <Stack p="15px 0px" direction="row" justifyContent="space-between" alignItems="center">
                <Stack alignItems="center" borderLeft="1px solid #919eab3d" justifyContent="center" width="100%">
                  <Box display="flex" gap="10px">
                    <Iconify icon="carbon:phone-filled" width={25} height={25} sx={{ color: "#239F5E" }} />
                    <Typography fontSize="17px" fontWeight="500" color="#239F5E">
                      Phone Number
                    </Typography>
                  </Box>
                  <Typography fontSize="15px">{user?.phone ? user.phone : "Phone number not added"}</Typography>
                </Stack>

                <Stack alignItems="center" justifyContent="center" borderLeft="1px solid #919eab3d" width="100%">
                  <Box display="flex" gap="10px">
                    <Iconify icon="tabler:live-view" width={26} height={26} sx={{ color: "#239F5E" }} />
                    <Typography fontSize="17px" fontWeight="500" color={"#239F5E"}>
                      Lives In
                    </Typography>
                  </Box>
                  <Typography fontSize="15px">
                    {user?.country}
                    {user?.state && `, ${user?.state}`}
                    {user?.city && `, ${user?.city}`}
                  </Typography>
                </Stack>

                <Stack alignItems="center" justifyContent="center" width="100%" borderLeft="1px solid #919eab3d">
                  <Box display="flex" gap="10px">
                    <Iconify icon="clarity:email-outline-badged" width={25} height={25} sx={{ color: "#239F5E" }} />
                    <Typography fontSize="17px" fontWeight="500" color="#239F5E">
                      Email Address
                    </Typography>
                  </Box>
                  <Typography fontSize="15px">{user?.email ? user.email : "Email not added"}</Typography>
                </Stack>
                <Divider orientation="vertical" flexItem />
              </Stack>
            </Card>
          </Grow>
        ) : (
          <Stack width="100%" justifyContent="center" alignItems="center" height={300}>
            <CircularProgress sx={{ color: "#089d40" }} size="50px" />
          </Stack>
        )}
      </Container>
    </Page>
  );
}
