import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Avatar, Stack } from "@mui/material";
import { CompanyMoreMenu } from ".";
import { CompanyData } from "../../../core/types/company.types";
import { fShortenNumber } from "../../../utils/formatNumber";
import { Link } from "react-router-dom";

function descendingComparator(
  a: { [x: string]: number | any },
  b: { [x: string]: number | any },
  orderBy: string | number
) {
  let valueA = a?.[orderBy];
  let valueB = b?.[orderBy];
  if (orderBy === "revenue") {
    valueA = a?.[orderBy]?.ammount;
    valueB = b?.[orderBy]?.ammount;
  }
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }

  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === "desc"
    ? (a: { [x: string]: number }, b: { [x: string]: number }) =>
        descendingComparator(a, b, orderBy)
    : (a: { [x: string]: number }, b: { [x: string]: number }) =>
        -descendingComparator(a, b, orderBy);
}

// function getComparator(order:string, orderBy:string) {
//   return order === "desc"
//     ? (a, b) =>
//         // Convert values to numbers before comparison
//         Number(a[orderBy]) < Number(b[orderBy]) ? -1 : 1
//     : (a, b) =>
//         Number(a[orderBy]) > Number(b[orderBy]) ? -1 : 1;
// }

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(
  array: any[],
  comparator: {
    (a: { [x: string]: number }, b: { [x: string]: number }): number;
    (arg0: any, arg1: any): any;
  }
) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "rank",
    numeric: true,
    disablePadding: false,
    label: "Rank",
  },
  {
    id: "clicks",
    numeric: true,
    disablePadding: false,
    label: "Clicks",
  },
  {
    id: "conversionRate",
    numeric: true,
    disablePadding: false,
    label: "Conversion",
  },
  {
    id: "numberOfJobs",
    numeric: true,
    disablePadding: false,
    label: "# of Jobs",
  },
  {
    id: "revenue",
    numeric: true,
    disablePadding: false,
    label: "Revenue",
  },
  {
    id: "more",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props: {
  order: any;
  orderBy: any;
  onRequestSort: any;
}) {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props: { numSelected: any }) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All Companies
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props: {
  rows: Array<CompanyData>;
  searchRows: Array<CompanyData>;
}) {
  const { rows, searchRows } = props;
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [dense] = React.useState(false);

  const handleRequestSort = (event: any, property: string) => {
    // console.log(property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      if (searchRows) {
        const newSelected = searchRows.map((n) => n.name);
        setSelected(newSelected);
      } else {
        const newSelected = rows.map((n) => n.name);
        setSelected(newSelected);
      }
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: any[] | ((prevState: string[]) => string[]) = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={searchRows.length ? searchRows.length : rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(
                searchRows.length ? searchRows : rows,
                getComparator(order, orderBy)
              ).map((row: CompanyData, index) => {
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ padding: "1px" }}
                    >
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/dashboard/company/${row.slug}`}
                      >
                        <Stack direction="row" alignItems="center" spacing={4}>
                          <Avatar
                            src={row.logo}
                            alt={row.name}
                            style={{ marginLeft: 29 }}
                          />
                          <Typography color="#212B36" variant="subtitle2">
                            {row.name}
                          </Typography>
                        </Stack>
                      </Link>
                    </TableCell>

                    <TableCell align="left" color="rgb(35, 159, 94)">
                      {row.location?.country || row?.country || "not set"}
                    </TableCell>

                    <TableCell align="right">
                      <Typography
                        fontSize="15px"
                        fontWeight="600"
                        color="rgb(35, 159, 94)"
                      >
                        {row.rank}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography
                        fontSize="15px"
                        fontWeight="600"
                        color="rgb(35, 159, 94)"
                      >
                        {fShortenNumber(row.clicks) || 0}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography
                        fontSize="15px"
                        fontWeight="600"
                        color="rgb(35, 159, 94)"
                        marginRight={2}
                      >
                        {row.conversionRate !== null ? row.conversionRate : 0}%
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Typography
                        fontSize="15px"
                        fontWeight="600"
                        color="rgb(35, 159, 94)"
                      >
                        {fShortenNumber(+row.numberOfJobs) || 0}
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      {row?.revenue?.ammount ? (
                        <Typography
                          fontSize="15px"
                          fontWeight="600"
                          color="rgb(35, 159, 94)"
                        >
                          {typeof +row.revenue.ammount === "number"
                            ? fShortenNumber(+row.revenue.ammount)
                            : row.revenue.ammount}
                          {row.revenue.currency}
                        </Typography>
                      ) : null}
                    </TableCell>

                    <TableCell align="right">
                      <CompanyMoreMenu
                        slug={row.slug}
                        company={row}
                        id={row._id}
                        name={row.name}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
