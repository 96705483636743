import { useState } from "react";
import { Input, IconButton, InputAdornment, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import {
  ResetSearchCompany,
  SearchCompany,
  serachDataCompany,
} from "../../core/redux/features/companies/companiesSlice";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CloseIcon from "@mui/icons-material/Close";

export default function SearchbarCompany() {
  const dispatch = useAppDispatch();

  const [stateInput, setStateInput] = useState<string>("");
  const { serachData, serachStatus } = useAppSelector(serachDataCompany);

  const onSubmitFunction = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(SearchCompany(stateInput));
  };

  const resetFunction = () => {
    dispatch(ResetSearchCompany());
    setStateInput("");
  };

  return (
    <Stack direction="row" component="form" onSubmit={(e) => onSubmitFunction(e)}>
      <Input
        onChange={(e) => setStateInput(e.target.value)}
        value={stateInput}
        autoFocus
        placeholder="Search Company..."
        required
        sx={{ mr: 1, fontWeight: "fontWeightBold" }}
        endAdornment={
          <InputAdornment position="end">
            <Stack direction="row">
              <IconButton
                color="error"
                disabled={serachStatus === "loading" || !serachData.length ? true : false}
                onClick={() => resetFunction()}
                size="small"
              >
                <CloseIcon sx={{ width: 15, height: 15 }} />
              </IconButton>
              <IconButton
                sx={{ borderRadius: 0, color: "#239f5e" }}
                type="submit"
                disabled={serachStatus === "loading" ? true : false}
                size="small"
              >
                <FindInPageIcon fontSize="medium" />
              </IconButton>
            </Stack>
          </InputAdornment>
        }
      />
    </Stack>
  );
}
