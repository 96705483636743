/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@mui/material";
import Page from "../../components/Page";
import ContainerCategory from "./components/ContainerCategory";
import ContainerExperience from "./components/ContainerExperience";
import ContainerFrequency from "./components/ContainerFrequency";
import ContainerJobType from "./components/ContainerJobType";
import ContainerSkill from "./components/ContainerSkills";

const Utilities = () => {
  return (
    <Page title="Utilities">
      <Stack maxWidth="xl">
        <ContainerExperience />
        <hr />
        <ContainerCategory />
        <hr />
        <ContainerFrequency />
        <hr />
        <ContainerJobType />
        <hr />
        <ContainerSkill />
      </Stack>
    </Page>
  );
};

export default Utilities;
