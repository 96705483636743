// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
import {
  Avatar,
  Button,
  Collapse,
  IconButton,
  Input,
  InputAdornment,
  Link,
  Popover,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  BaseProviderType,
  InsuranceType,
  InvestingType,
  LoanType,
  PaymentType,
} from "../../core/types/payment.types";
import PaymentForm from "../../sections/@dashboard/payment/PaymentForm";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import { useAppDispatch } from "../../core/redux/hooks";
import { DeleteProvider } from "../../core/redux/features/payment/paymentThunks";

type Props = {
  props: any;
  type: "payment" | "insurance" | "investing" | "loan";
};

const ProviderCard = ({ props, type }: Props) => {
  return (
    <>
      {type === "payment" && <PaymentCard {...(props as PaymentType)} />}
      {type === "investing" && <InvestingCard {...(props as InvestingType)} />}
      {type === "insurance" && <InsuranceCard {...(props as InsuranceType)} />}
      {type === "loan" && <LoanCard {...(props as LoanType)} />}
    </>
  );
};

const PaymentCard = (props: PaymentType) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const [values, setValues] = useState<string>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const openDelete = Boolean(anchorEl);
  const id = openDelete ? "simple-popover" : undefined;

  const handleDelete = () => {
    dispatch(DeleteProvider(props._id));
    handleClose();
  };

  return (
    <Stack
      bgcolor="#FFFFFF"
      borderRadius="12px"
      boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)"
    >
      <Stack spacing={{ xs: 0, md: 2 }} direction={{ xs: "column", md: "row" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={{ sm: 2, md: 0 }}
        >
          <Stack position="relative">
            <Avatar
              src={props.logo}
              sx={{
                width: { xs: "100%", sm: "70%", md: 250, lg: 270 },
                borderRadius: {
                  xs: "12px 12px 0px 0px",
                  sm: "12px 0px 0px 0px ",
                  md: open ? "12px 0px 0px 0px" : "12px 0px 0px 12px",
                },
                height: { xs: "auto", sm: 230, md: 250, lg: 270 },
              }}
            />
          </Stack>
          <Stack display={{ xs: "none", sm: "flex", md: "none" }}>
            <AppAndRating {...props} />
          </Stack>
        </Stack>

        <Stack
          p={{ xs: "10px", md: "16px" }}
          width="100%"
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack justifyContent="space-between">
            <Stack spacing={2}>
              <Typography color="primary" fontWeight="bold" fontSize={20}>
                {props.title}
              </Typography>

              <Stack
                justifyContent="flex-start"
                direction="row"
                width="100%"
                maxWidth="700px"
                flexWrap={{ xs: "wrap", md: "unset" }}
                gap={{ xs: 2, md: 3, lg: 8 }}
              >
                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Available in
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.numberOfCountries} countries
                      <Link
                        target={"_blank"}
                        href={props.availableCountriesUrl}
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        (learn more)
                      </Link>
                    </Typography>
                  </Stack>

                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Support for
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      Arround {props.numberOfCurrencies} currencies
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Fees/Commissions
                    </Typography>

                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.fee}
                      <Link
                        target={"_blank"}
                        href={props.feeUrl}
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        (learn more)
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Bonuses
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.bonus}{" "}
                      {props.bonusUrl && (
                        <Link
                          target={"_blank"}
                          href={props.bonusUrl}
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          (learn more)
                        </Link>
                      )}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Cards
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.availableCards?.masterCard && "Mastercard"}
                      {props.availableCards?.visa && ", Visa"}{" "}
                      {props.availableCards?.maestro && ", Maestro"}
                    </Typography>
                  </Stack>
                  <Button
                    LinkComponent={"a"}
                    href={props.websiteUrl}
                    target="_blank"
                    variant="contained"
                    sx={{ p: "5px 10px", display: { xs: "none", sm: "flex" } }}
                    fullWidth
                  >
                    <Typography fontSize={12}>Learn More</Typography>
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              gap={0.2}
              flexWrap="wrap"
              direction="row"
              display={{ xs: "flex", sm: "none" }}
              justifyContent="space-around"
            >
              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.iosUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.iosUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 20px 8px 12px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/apple.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          Download on the
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          App Store
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}

              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.androidUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.androidUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 20px 8px 15px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/google.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          GET IT ON
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          Google Play
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}

              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.huaWeiUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.huaWeiUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 12px 8px 15px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/huawei.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          EXPLORE IT ON
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          AppGallery
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}
            </Stack>

            <Stack direction="row">
              <Button
                onClick={() => setOpen(!open)}
                fullWidth
                sx={{ maxWidth: 150 }}
              >
                <Typography pl="15px" fontSize={12}>
                  Read More
                </Typography>
                {open ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{ maxWidth: 150 }}
                onClick={() => setOpenEdit(!openEdit)}
              >
                <EditIcon fontSize="small" />
                <Typography fontSize={12}>Edit</Typography>
                {openEdit ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Button>

              <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{ maxWidth: 150 }}
                onClick={handleClick}
                aria-describedby={id}
              >
                <DeleteIcon fontSize="small" />
                <Typography fontSize={12}>Delete</Typography>
              </Button>
              <Popover
                id={id}
                open={openDelete}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                <Stack
                  p="20px"
                  borderRadius={2}
                  width={350}
                  textAlign="center"
                  spacing={2}
                >
                  <Typography variant="h6" lineHeight={1} color="GrayText">
                    To delete a company, enter the names of the company
                  </Typography>
                  <Typography variant="h6" color="InfoText">
                    {props.title}
                  </Typography>
                  <Stack>
                    <Input
                      placeholder="change name company"
                      value={values}
                      onChange={(e) => setValues(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disabled={values === props.title ? false : true}
                            onClick={handleDelete}
                          >
                            <DeleteIcon
                              color={
                                values === props.title ? "error" : "action"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Stack>
                </Stack>
              </Popover>
            </Stack>
          </Stack>
          <Stack display={{ xs: "none", md: "flex" }}>
            <AppAndRating {...props} />
          </Stack>
        </Stack>
      </Stack>
      <Collapse in={open}>
        <Box bgcolor="#f7fcf9" p="20px 16px">
          <Typography color="#142B1E" component="p" fontSize={14}>
            {props.description}
          </Typography>
        </Box>
      </Collapse>
      <Collapse in={openEdit}>
        <Box p="30px 20px 20px 20px" bgcolor="#EFF6F3">
          <PaymentForm {...props} handleClose={handleCloseEdit} />
        </Box>
      </Collapse>
    </Stack>
  );
};

const AppAndRating = (
  props: PaymentType | InsuranceType | LoanType | InvestingType
) => (
  <Stack
    spacing={1}
    justifyContent="space-between"
    width={{ xs: "100%", md: "auto" }}
    pr={{ sm: 2, md: 0 }}
  >
    <Stack
      spacing={0.3}
      alignItems={{ sm: "center", md: "flex-end" }}
      direction={{ xs: "row", sm: "column" }}
      flexWrap={{ xs: "wrap", sm: "unset" }}
      component="a"
      target="_blank"
      href={props?.trustPilotRating?.url || "#"}
    >
      <Avatar
        src="/Partner/Trustpilot.svg"
        sx={{
          borderRadius: 0,
          width: "80%",
          height: "auto",
        }}
      />
      <Stack justifyContent="flex-end" direction="row">
        <Typography fontWeight={550} color="#142B1E">
          {props?.trustPilotRating?.rating || 0}
        </Typography>
        <Rating
          name="simple-controlled"
          value={props?.trustPilotRating?.rating || 0}
          precision={0.5}
          readOnly
          sx={{ color: "#239F5E" }}
          emptyIcon={<StarIcon fontSize="inherit" />}
          size="small"
        />
      </Stack>
    </Stack>

    <Stack
      spacing={0.5}
      direction={{ xs: "row", sm: "column" }}
      flexWrap={{ xs: "wrap", sm: "unset" }}
    >
      {props.mobileApp && props.mobileApp.exist && props.mobileApp.iosUrl && (
        <Link
          target={"_blank"}
          href={props.mobileApp.iosUrl}
          style={{ textDecoration: "none" }}
        >
          <Stack
            direction="row"
            p="3px 20px 8px 12px"
            spacing={0.8}
            borderRadius={2}
            bgcolor="#000"
            alignItems="center"
          >
            <Avatar
              src="/Partner/apple.svg"
              sx={{ borderRadius: 0, width: 23, height: 23 }}
            />
            <Stack pt="5px">
              <Typography color="white" fontWeight="600" fontSize={8}>
                Download on the
              </Typography>
              <Typography color="white" fontWeight="bold" fontSize={10}>
                App Store
              </Typography>
            </Stack>
          </Stack>
        </Link>
      )}

      {props.mobileApp &&
        props.mobileApp.exist &&
        props.mobileApp.androidUrl && (
          <Link
            target={"_blank"}
            href={props.mobileApp.androidUrl}
            style={{ textDecoration: "none" }}
          >
            <Stack
              direction="row"
              p="3px 20px 8px 15px"
              spacing={0.8}
              borderRadius={2}
              bgcolor="#000"
              alignItems="center"
            >
              <Avatar
                src="/Partner/google.svg"
                sx={{ borderRadius: 0, width: 20, height: 20 }}
              />
              <Stack pt="5px">
                <Typography color="white" fontWeight="600" fontSize={8}>
                  GET IT ON
                </Typography>
                <Typography color="white" fontWeight="bold" fontSize={10}>
                  Google Play
                </Typography>
              </Stack>
            </Stack>
          </Link>
        )}

      {props.mobileApp &&
        props.mobileApp.exist &&
        props.mobileApp.huaWeiUrl && (
          <Link
            target={"_blank"}
            href={props.mobileApp.huaWeiUrl}
            style={{ textDecoration: "none" }}
          >
            <Stack
              direction="row"
              p="3px 12px 8px 15px"
              spacing={0.8}
              borderRadius={2}
              bgcolor="#000"
              alignItems="center"
            >
              <Avatar
                src="/Partner/huawei.svg"
                sx={{ borderRadius: 0, width: 23, height: 23 }}
              />
              <Stack pt="5px">
                <Typography color="white" fontWeight="600" fontSize={8}>
                  EXPLORE IT ON
                </Typography>
                <Typography color="white" fontWeight="bold" fontSize={10}>
                  AppGallery
                </Typography>
              </Stack>
            </Stack>
          </Link>
        )}
    </Stack>
  </Stack>
);

const InsuranceCard = (props: InsuranceType) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const [values, setValues] = useState<string>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const openDelete = Boolean(anchorEl);
  const id = openDelete ? "simple-popover" : undefined;

  const handleDelete = () => {
    dispatch(DeleteProvider(props._id));
    handleClose();
  };

  return (
    <Stack
      bgcolor="#FFFFFF"
      borderRadius="12px"
      boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)"
    >
      <Stack spacing={{ xs: 0, md: 2 }} direction={{ xs: "column", md: "row" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={{ sm: 2, md: 0 }}
        >
          <Stack position="relative">
            <Avatar
              src={props.logo}
              sx={{
                width: { xs: "100%", sm: "70%", md: 250, lg: 270 },
                borderRadius: {
                  xs: "12px 12px 0px 0px",
                  sm: "12px 0px 0px 0px ",
                  md: open ? "12px 0px 0px 0px" : "12px 0px 0px 12px",
                },
                height: { xs: "auto", sm: 230, md: 250, lg: 270 },
              }}
            />
          </Stack>
          <Stack display={{ xs: "none", sm: "flex", md: "none" }}>
            <AppAndRating {...props} />
          </Stack>
        </Stack>

        <Stack
          p={{ xs: "10px", md: "16px" }}
          width="100%"
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack justifyContent="space-between">
            <Stack spacing={2}>
              <Typography color="primary" fontWeight="bold" fontSize={20}>
                {props.title}
              </Typography>

              <Stack
                justifyContent="flex-start"
                direction="row"
                width="100%"
                maxWidth="700px"
                flexWrap={{ xs: "wrap", md: "unset" }}
                gap={{ xs: 2, md: 3, lg: 8 }}
              >
                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Available in
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.numberOfCountries} countries
                      <Link
                        target={"_blank"}
                        href={props.availableCountriesUrl}
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        (learn more)
                      </Link>
                    </Typography>
                  </Stack>

                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Coverage
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      ${props.minimumCoverage} - ${props.maximumCoverage}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Restrictions
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.restrictions}
                    </Typography>
                  </Stack>
                  <Button
                    LinkComponent={"a"}
                    href={props.websiteUrl}
                    target="_blank"
                    variant="contained"
                    sx={{
                      p: "5px 10px",
                      display: { xs: "none", sm: "flex" },
                    }}
                    fullWidth
                  >
                    <Typography fontSize={12}>Learn More</Typography>
                  </Button>
                </Stack>

                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Term Lengths
                    </Typography>
                    {props.termLengths.map((item, idx) => (
                      <Typography
                        key={idx}
                        fontWeight={550}
                        color="#142B1E"
                        fontSize={12}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              gap={0.2}
              flexWrap="wrap"
              direction="row"
              display={{ xs: "flex", sm: "none" }}
              justifyContent="space-around"
            >
              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.iosUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.iosUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 20px 8px 12px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/apple.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          Download on the
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          App Store
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}

              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.androidUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.androidUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 20px 8px 15px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/google.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          GET IT ON
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          Google Play
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}

              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.huaWeiUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.huaWeiUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 12px 8px 15px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/huawei.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          EXPLORE IT ON
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          AppGallery
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}
            </Stack>

            <Stack direction="row">
              <Button
                onClick={() => setOpen(!open)}
                fullWidth
                sx={{ maxWidth: 150 }}
              >
                <Typography pl="15px" fontSize={12}>
                  Read More
                </Typography>
                {open ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{ maxWidth: 150 }}
                onClick={() => setOpenEdit(!openEdit)}
              >
                <EditIcon fontSize="small" />
                <Typography fontSize={12}>Edit</Typography>
                {openEdit ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Button>

              <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{ maxWidth: 150 }}
                onClick={handleClick}
                aria-describedby={id}
              >
                <DeleteIcon fontSize="small" />
                <Typography fontSize={12}>Delete</Typography>
              </Button>
              <Popover
                id={id}
                open={openDelete}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                <Stack
                  p="20px"
                  borderRadius={2}
                  width={350}
                  textAlign="center"
                  spacing={2}
                >
                  <Typography variant="h6" lineHeight={1} color="GrayText">
                    To delete a company, enter the names of the company
                  </Typography>
                  <Typography variant="h6" color="InfoText">
                    {props.title}
                  </Typography>
                  <Stack>
                    <Input
                      placeholder="change name company"
                      value={values}
                      onChange={(e) => setValues(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disabled={values === props.title ? false : true}
                            onClick={handleDelete}
                          >
                            <DeleteIcon
                              color={
                                values === props.title ? "error" : "action"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Stack>
                </Stack>
              </Popover>
            </Stack>
          </Stack>
          <Stack display={{ xs: "none", md: "flex" }}>
            <AppAndRating {...props} />
          </Stack>
        </Stack>
      </Stack>
      <Collapse in={open}>
        <Box bgcolor="#f7fcf9" p="20px 16px">
          <Typography color="#142B1E" component="p" fontSize={14}>
            {props.description}
          </Typography>
        </Box>
      </Collapse>
      <Collapse in={openEdit}>
        <Box p="30px 20px 20px 20px" bgcolor="#EFF6F3">
          <PaymentForm {...props} handleClose={handleCloseEdit} />
        </Box>
      </Collapse>
    </Stack>
  );
};

const InvestingCard = (props: InvestingType) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const [values, setValues] = useState<string>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const openDelete = Boolean(anchorEl);
  const id = openDelete ? "simple-popover" : undefined;

  const handleDelete = () => {
    dispatch(DeleteProvider(props._id));
    handleClose();
  };

  return (
    <Stack
      bgcolor="#FFFFFF"
      borderRadius="12px"
      boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)"
    >
      <Stack spacing={{ xs: 0, md: 2 }} direction={{ xs: "column", md: "row" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={{ sm: 2, md: 0 }}
        >
          <Stack position="relative">
            <Avatar
              src={props.logo}
              sx={{
                width: { xs: "100%", sm: "70%", md: 250, lg: 270 },
                borderRadius: {
                  xs: "12px 12px 0px 0px",
                  sm: "12px 0px 0px 0px ",
                  md: open ? "12px 0px 0px 0px" : "12px 0px 0px 12px",
                },
                height: { xs: "auto", sm: 230, md: 250, lg: 270 },
              }}
            />
          </Stack>
          <Stack display={{ xs: "none", sm: "flex", md: "none" }}>
            <AppAndRating {...props} />
          </Stack>
        </Stack>

        <Stack
          p={{ xs: "10px", md: "16px" }}
          width="100%"
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack justifyContent="space-between">
            <Stack spacing={2}>
              <Typography color="primary" fontWeight="bold" fontSize={20}>
                {props.title}
              </Typography>

              <Stack
                justifyContent="flex-start"
                direction="row"
                width="100%"
                maxWidth="700px"
                flexWrap={{ xs: "wrap", md: "unset" }}
                gap={{ xs: 2, md: 3, lg: 8 }}
              >
                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Available in
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.numberOfCountries} countries
                      <Link
                        target={"_blank"}
                        href={props.availableCountriesUrl}
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        (learn more)
                      </Link>
                    </Typography>
                  </Stack>

                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Minimum Deposit
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.minimumDeposit}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Fees/Commissions
                    </Typography>

                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.fee}
                      <Link
                        target={"_blank"}
                        href={props.feeUrl}
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        (learn more)
                      </Link>
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Bonuses
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.bonus}{" "}
                      {props.bonusUrl && (
                        <Link
                          target={"_blank"}
                          href={props.bonusUrl}
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          (learn more)
                        </Link>
                      )}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Available Options
                    </Typography>
                    {props.assetTypes.map((item, idx) => (
                      <Typography
                        key={idx}
                        fontWeight={550}
                        color="#142B1E"
                        fontSize={12}
                      >
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </Typography>
                    ))}
                  </Stack>
                  <Button
                    LinkComponent={"a"}
                    href={props.websiteUrl}
                    target="_blank"
                    variant="contained"
                    sx={{
                      p: "5px 10px",
                      display: { xs: "none", sm: "flex" },
                    }}
                    fullWidth
                  >
                    <Typography fontSize={12}>Learn More</Typography>
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              gap={0.2}
              flexWrap="wrap"
              direction="row"
              display={{ xs: "flex", sm: "none" }}
              justifyContent="space-around"
            >
              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.iosUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.iosUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 20px 8px 12px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/apple.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          Download on the
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          App Store
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}

              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.androidUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.androidUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 20px 8px 15px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/google.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          GET IT ON
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          Google Play
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}

              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.huaWeiUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.huaWeiUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 12px 8px 15px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/huawei.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          EXPLORE IT ON
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          AppGallery
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}
            </Stack>

            <Stack direction="row">
              <Button
                onClick={() => setOpen(!open)}
                fullWidth
                sx={{ maxWidth: 150 }}
              >
                <Typography pl="15px" fontSize={12}>
                  Read More
                </Typography>
                {open ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{ maxWidth: 150 }}
                onClick={() => setOpenEdit(!openEdit)}
              >
                <EditIcon fontSize="small" />
                <Typography fontSize={12}>Edit</Typography>
                {openEdit ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Button>

              <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{ maxWidth: 150 }}
                onClick={handleClick}
                aria-describedby={id}
              >
                <DeleteIcon fontSize="small" />
                <Typography fontSize={12}>Delete</Typography>
              </Button>
              <Popover
                id={id}
                open={openDelete}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                <Stack
                  p="20px"
                  borderRadius={2}
                  width={350}
                  textAlign="center"
                  spacing={2}
                >
                  <Typography variant="h6" lineHeight={1} color="GrayText">
                    To delete a company, enter the names of the company
                  </Typography>
                  <Typography variant="h6" color="InfoText">
                    {props.title}
                  </Typography>
                  <Stack>
                    <Input
                      placeholder="change name company"
                      value={values}
                      onChange={(e) => setValues(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disabled={values === props.title ? false : true}
                            onClick={handleDelete}
                          >
                            <DeleteIcon
                              color={
                                values === props.title ? "error" : "action"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Stack>
                </Stack>
              </Popover>
            </Stack>
          </Stack>
          <Stack display={{ xs: "none", md: "flex" }}>
            <AppAndRating {...props} />
          </Stack>
        </Stack>
      </Stack>
      <Collapse in={open}>
        <Box bgcolor="#f7fcf9" p="20px 16px">
          <Typography color="#142B1E" component="p" fontSize={14}>
            {props.description}
          </Typography>
        </Box>
      </Collapse>
      <Collapse in={openEdit}>
        <Box p="30px 20px 20px 20px" bgcolor="#EFF6F3">
          <PaymentForm {...props} handleClose={handleCloseEdit} />
        </Box>
      </Collapse>
    </Stack>
  );
};

const LoanCard = (props: LoanType) => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const handleCloseEdit = () => setOpenEdit(false);
  const [values, setValues] = useState<string>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const openDelete = Boolean(anchorEl);
  const id = openDelete ? "simple-popover" : undefined;

  const handleDelete = () => {
    dispatch(DeleteProvider(props._id));
    handleClose();
  };

  return (
    <Stack
      bgcolor="#FFFFFF"
      borderRadius="12px"
      boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1)"
    >
      <Stack spacing={{ xs: 0, md: 2 }} direction={{ xs: "column", md: "row" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={{ sm: 2, md: 0 }}
        >
          <Stack position="relative">
            <Avatar
              src={props.logo}
              sx={{
                width: { xs: "100%", sm: "70%", md: 250, lg: 270 },
                borderRadius: {
                  xs: "12px 12px 0px 0px",
                  sm: "12px 0px 0px 0px ",
                  md: open ? "12px 0px 0px 0px" : "12px 0px 0px 12px",
                },
                height: { xs: "auto", sm: 230, md: 250, lg: 270 },
              }}
            />
          </Stack>
          <Stack display={{ xs: "none", sm: "flex", md: "none" }}>
            <AppAndRating {...props} />
          </Stack>
        </Stack>

        <Stack
          p={{ xs: "10px", md: "16px" }}
          width="100%"
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack justifyContent="space-between">
            <Stack spacing={2}>
              <Typography color="primary" fontWeight="bold" fontSize={20}>
                {props.title}
              </Typography>

              <Stack
                justifyContent="flex-start"
                direction="row"
                width="100%"
                maxWidth="700px"
                flexWrap={{ xs: "wrap", md: "unset" }}
                gap={{ xs: 2, md: 3, lg: 8 }}
              >
                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Available in
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.numberOfCountries} countries
                      <Link
                        target={"_blank"}
                        href={props.availableCountriesUrl}
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        (learn more)
                      </Link>
                    </Typography>
                  </Stack>

                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Loan Amount
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      ${props.minimumLoanAmount} - ${props.maximumLoanAmount}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack spacing={1.5} minWidth={{ xs: 120, md: "auto" }}>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Minimum Credit Score
                    </Typography>

                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.minimumCreditScore}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography fontWeight={650} color="#239F5E" fontSize={12}>
                      Interest
                    </Typography>
                    <Typography fontWeight={550} color="#142B1E" fontSize={12}>
                      {props.interest}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              gap={0.2}
              flexWrap="wrap"
              direction="row"
              display={{ xs: "flex", sm: "none" }}
              justifyContent="space-around"
            >
              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.iosUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.iosUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 20px 8px 12px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/apple.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          Download on the
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          App Store
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}

              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.androidUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.androidUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 20px 8px 15px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/google.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          GET IT ON
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          Google Play
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}

              {props.mobileApp &&
                props.mobileApp.exist &&
                props.mobileApp.huaWeiUrl && (
                  <Link
                    target={"_blank"}
                    href={props.mobileApp.huaWeiUrl}
                    style={{ textDecoration: "none" }}
                  >
                    <Stack
                      direction="row"
                      p="3px 12px 8px 15px"
                      spacing={0.8}
                      borderRadius={2}
                      bgcolor="#000"
                      alignItems="center"
                    >
                      <Avatar
                        src="/Partner/huawei.svg"
                        sx={{ borderRadius: 0, width: 20, height: 20 }}
                      />
                      <Stack pt="5px">
                        <Typography color="white" fontWeight="600" fontSize={8}>
                          EXPLORE IT ON
                        </Typography>
                        <Typography
                          color="white"
                          fontWeight="bold"
                          fontSize={10}
                        >
                          AppGallery
                        </Typography>
                      </Stack>
                    </Stack>
                  </Link>
                )}
            </Stack>

            <Stack direction="row">
              <Button
                onClick={() => setOpen(!open)}
                fullWidth
                sx={{ maxWidth: 150 }}
              >
                <Typography pl="15px" fontSize={12}>
                  Read More
                </Typography>
                {open ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{ maxWidth: 150 }}
                onClick={() => setOpenEdit(!openEdit)}
              >
                <EditIcon fontSize="small" />
                <Typography fontSize={12}>Edit</Typography>
                {openEdit ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Button>

              <Button
                variant="contained"
                color="error"
                fullWidth
                sx={{ maxWidth: 150 }}
                onClick={handleClick}
                aria-describedby={id}
              >
                <DeleteIcon fontSize="small" />
                <Typography fontSize={12}>Delete</Typography>
              </Button>
              <Popover
                id={id}
                open={openDelete}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                <Stack
                  p="20px"
                  borderRadius={2}
                  width={350}
                  textAlign="center"
                  spacing={2}
                >
                  <Typography variant="h6" lineHeight={1} color="GrayText">
                    To delete a company, enter the names of the company
                  </Typography>
                  <Typography variant="h6" color="InfoText">
                    {props.title}
                  </Typography>
                  <Stack>
                    <Input
                      placeholder="change name company"
                      value={values}
                      onChange={(e) => setValues(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disabled={values === props.title ? false : true}
                            onClick={handleDelete}
                          >
                            <DeleteIcon
                              color={
                                values === props.title ? "error" : "action"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Stack>
                </Stack>
              </Popover>
            </Stack>
          </Stack>
          <Stack display={{ xs: "none", md: "flex" }}>
            <AppAndRating {...props} />
          </Stack>
        </Stack>
      </Stack>
      <Collapse in={open}>
        <Box bgcolor="#f7fcf9" p="20px 16px">
          <Typography color="#142B1E" component="p" fontSize={14}>
            {props.description}
          </Typography>
        </Box>
      </Collapse>
      <Collapse in={openEdit}>
        <Box p="30px 20px 20px 20px" bgcolor="#EFF6F3">
          <PaymentForm {...props} handleClose={handleCloseEdit} />
        </Box>
      </Collapse>
    </Stack>
  );
};

export default ProviderCard;
