/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Container } from "@mui/material";
import React, { useEffect, Suspense } from "react";
import Page from "../../components/Page";
import { knowledgeCategory } from "../../core/redux/features/knowledge/knowledgeSlice";
import { getCategoryFaq } from "../../core/redux/features/knowledge/knowledgeThunks";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";

const CategoryTable = React.lazy(() => import("./components/CategoryTable"));

const KnowledgeBase = () => {
  const dispatch = useAppDispatch();

  const category = useAppSelector(knowledgeCategory);
  useEffect(() => {
    if (category.statusFetch === "idle") {
      dispatch(getCategoryFaq());
    }
  }, []);

  return (
    <Page title="Knowledge Base">
      <Container maxWidth="xl">
        <Suspense
          fallback={
            <Box width="100%" height="320px" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress sx={{ color: "#0e2944" }} />
            </Box>
          }
        >
          {category.data.length ? (
            <CategoryTable category={category.data} />
          ) : (
            <Box width="100%" height="320px" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress sx={{ color: "#0e2944" }} />
            </Box>
          )}
        </Suspense>
      </Container>
    </Page>
  );
};

export default KnowledgeBase;
