/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Stack, Collapse, CircularProgress, Box, Tooltip, Grow, Paper } from "@mui/material";
import { selectUtilsJobType } from "../../../../core/redux/features/utilities/utilitiesSlice";
import { getJobType, postJobType } from "../../../../core/redux/features/utilities/utilitiesThuks";
import { useAppDispatch, useAppSelector } from "../../../../core/redux/hooks";
import IconButtonHidden from "../../../KnowledgeBase/components/IconButtonHidden";
import SourceIcon from "@mui/icons-material/Source";
import React, { useEffect, useState, Suspense } from "react";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import ButtonMenu from "../ButtonMenu";
import AddIcon from "@mui/icons-material/Add";
import FormUtils from "../FormUtils";

const ItemJobType = React.lazy(() => import("./ItemJobType"));

const ContainerJobType = () => {
  const dispatch = useAppDispatch();

  const jobType = useAppSelector(selectUtilsJobType);
  const [jobTypeRow, setJobTypeRow] = useState<boolean>(false);
  const [anchorElAdd, setAnchorElAdd] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (jobType.statusFetch === "idle" && jobTypeRow) {
      dispatch(getJobType());
    }
  }, [jobTypeRow]);

  const onSubmit = (data: { title: string }) => {
    dispatch(postJobType(data));
  };

  return (
    <Grow in={true} timeout={2000}>
      <Stack width="100%" p="10px 20px" component={Paper}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="blod" color="#239f5e">
            Job Type
          </Typography>

          <Stack spacing={4} direction="row">
            <IconButtonHidden
              icon={
                <>
                  <SourceIcon fontSize="small" />
                  &nbsp;
                  <Typography variant="body2" fontWeight="blod" component="span">
                    {jobType.data.length ? jobType.data.length : null}
                  </Typography>
                </>
              }
              open={jobTypeRow}
              setOpen={setJobTypeRow}
              title={"Сlick to open the form to change the details of this Job Type"}
            />
            <ButtonMenu
              colorButton="primary"
              colorButtonBorder="#239f5e"
              iconButton={<AddIcon fontSize="small" />}
              anchorEl={anchorElAdd}
              setAnchorEl={setAnchorElAdd}
            >
              <Stack width={{ xs: 250, md: 400 }} p="20px" spacing={4}>
                <Stack direction="row" width="100%" justifyContent="space-between">
                  <Typography variant="h6">Add Job Type</Typography>
                  <Tooltip title="Close">
                    <CloseFullscreenIcon
                      color="warning"
                      onClick={() => setAnchorElAdd(null)}
                      sx={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Stack>
                <FormUtils label="Job Type" onSubmit={onSubmit} setOpen={setAnchorElAdd} />
              </Stack>
            </ButtonMenu>
          </Stack>
        </Stack>

        <Collapse in={jobTypeRow} timeout="auto" unmountOnExit>
          <Suspense
            fallback={
              <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                <CircularProgress sx={{ color: "#0e2944" }} />
              </Box>
            }
          >
            <Stack direction="row" flexWrap="wrap" gap={1} p="20px 0px ">
              {jobType.data.length ? (
                jobType.data.map((i, idx) => <ItemJobType key={i._id} {...i} idx={idx} />)
              ) : (
                <Stack width="100%" alignItems="center">
                  <CircularProgress sx={{ color: "#0e2944" }} />
                </Stack>
              )}
            </Stack>
          </Suspense>
        </Collapse>
      </Stack>
    </Grow>
  );
};

export default ContainerJobType;
