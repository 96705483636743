import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, Select, MenuItem } from "@mui/material";
// components
import { BaseOptionChart } from "../../../components/chart";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../core/redux/hooks";
import {
  selectStatsDashboard2,
  selectUserAnalytics,
} from "../../../core/redux/features/stats/statsSlice";

// ----------------------------------------------------------------------

export default function AppWebsiteVisits() {
  const [projection, setProjection] = useState("yearly");
  const [chartLabelsState, setChartLabelsState] = useState<any>();
  const [chartDataState, setChartDataState] = useState<any>();

  const dashboard2 = useAppSelector(selectStatsDashboard2);
  const userAnalytics = useAppSelector(selectUserAnalytics);

  useEffect(() => {
    if (projection === "yearly") {
      setChartLabelsState(userAnalytics.map((i) => i.date));
      setChartDataState([
        {
          name: "Logins",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.logins),
        },
        {
          name: "Sign ups",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.signups),
        },
      ]);
    } else if (projection === "90-days") {
      setChartLabelsState(userAnalytics.map((i) => i.date).slice(-90));
      setChartDataState([
        {
          name: "Logins",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.logins).slice(-90),
        },
        {
          name: "Sign ups",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.signups).slice(-90),
        },
      ]);
    } else if (projection === "30-days") {
      setChartLabelsState(userAnalytics.map((i) => i.date).slice(-30));
      setChartDataState([
        {
          name: "Logins",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.logins).slice(-30),
        },
        {
          name: "Sign ups",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.signups).slice(-30),
        },
      ]);
    } else if (projection === "14-days") {
      setChartLabelsState(userAnalytics.map((i) => i.date).slice(-14));
      setChartDataState([
        {
          name: "Logins",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.logins).slice(-14),
        },
        {
          name: "Sign ups",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.signups).slice(-14),
        },
      ]);
    } else if (projection === "7-days") {
      setChartLabelsState(userAnalytics.map((i) => i.date).slice(-7));
      setChartDataState([
        {
          name: "Logins",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.logins).slice(-7),
        },
        {
          name: "Sign ups",
          type: "area",
          fill: "gradient",
          data: userAnalytics.map((i) => i.signups).slice(-7),
        },
      ]);
    }
  }, [projection, userAnalytics]);

  const chartOptions: any = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartDataState?.map((i: any) => i.fill) },
    labels: chartLabelsState,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} users`;
          }
          return y;
        },
      },
    },
  });

  const handleProjection = (e: any) => {
    setProjection(e.target.value);
  };

  return (
    <Card>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        padding="10px"
      >
        <CardHeader
          subheader={`(${dashboard2.signUps.monthly.change}) than last month (sign ups)`}
          title={"Sign Ups"}
        />
        <Select value={projection} onChange={handleProjection}>
          <MenuItem value={"yearly"}>In months</MenuItem>
          <MenuItem value={"90-days"}>last 90 days</MenuItem>
          <MenuItem value={"30-days"}>last 30 days</MenuItem>
          <MenuItem value={"14-days"}>last 14 days</MenuItem>
          <MenuItem value={"7-days"}>last 7 days</MenuItem>
        </Select>
      </Stack>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {chartDataState && (
          <ReactApexChart
            type="line"
            series={chartDataState}
            options={chartOptions}
            height={364}
            width={"100%"}
          />
        )}
      </Box>
    </Card>
  );
}

const dummyData = [
  {
    _id: "6535dac6f89ca30d8b205046",
    updatedAt: 1698028230171,
    createdAt: 1698028230171,
    countryAnalytics: [
      {
        country: "Nigeria",
        count: 2,
      },
      {
        country: "Sierra Leone",
        count: 1,
      },
    ],
    year: 2023,
    month: 10,
    day: 23,
    date: "10/23/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205047",
    updatedAt: 1698028230172,
    createdAt: 1698028230172,
    countryAnalytics: [
      {
        country: "Kenya",
        count: 5,
      },
      {
        country: "Tanzania",
        count: 3,
      },
    ],
    year: 2023,
    month: 10,
    day: 22,
    date: "10/22/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205048",
    updatedAt: 1698028230173,
    createdAt: 1698028230173,
    countryAnalytics: [
      {
        country: "Ghana",
        count: 8,
      },
      {
        country: "Ivory Coast",
        count: 6,
      },
    ],
    year: 2023,
    month: 10,
    day: 21,
    date: "10/21/2023",
  },
  {
    _id: "6535dac6f89ca30d8b205049",
    updatedAt: 1698028230174,
    createdAt: 1698028230174,
    countryAnalytics: [
      {
        country: "Uganda",
        count: 12,
      },
      {
        country: "Rwanda",
        count: 9,
      },
    ],
    year: 2023,
    month: 10,
    day: 20,
    date: "10/20/2023",
  },
  {
    _id: "6535dac6f89ca30d8b20504a",
    updatedAt: 1698028230175,
    createdAt: 1698028230175,
    countryAnalytics: [
      {
        country: "Cameroon",
        count: 17,
      },
      {
        country: "Niger",
        count: 13,
      },
    ],
    year: 2023,
    month: 10,
    day: 19,
    date: "10/19/2023",
  },
];
