import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";

export const createCompanyReview = createAsyncThunk(
  "company/createCompanyReviewThunk",

  async (data: { body: CreatePayload; company_id: string }) => {
    const res = await request.post(`api/v1/company/review/${data.company_id}`, data.body);
    return res.data;
  }
);

export const updateReview = createAsyncThunk(
  "company/updateReview",
  async (data: { body: UpdatePayload; company_id: string }) => {
    const res = await request.patch(`api/v1/company/review/${data.company_id}`, data.body);
    return res.data;
  }
);

export const deleteReview = createAsyncThunk(
  "company/deleteReview",
  async (data: { company_id: string; review_id: string }) => {
    const res = await request.delete(`api/v1/company/review/${data.company_id}/${data.review_id}`);
    return res.data;
  }
);

interface CreatePayload {
  _id: string;
  providerName: string;
  sourceUrl: string;
  logoUrl: string;
  scale: "stars" | "numeric" | "percentage";
  numOfRespondents: number;
  rating: number;
  outOf: number;
  description?: string;
}

interface UpdatePayload {
  providerName?: string;
  sourceUrl?: string;
  logoUrl?: string;
  scale?: "stars" | "numeric" | "percentage";
  numOfRespondents?: number;
  rating?: number;
  outOf?: number;
  description?: string;
}
