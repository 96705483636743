/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Container,
  Stack,
  Typography,
  Button,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
// Components
import Page from "../../components/Page";
import { selectAllProviders } from "../../core/redux/features/payment/paymentSlice";
import { fetchProvider } from "../../core/redux/features/payment/paymentThunks";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { Link } from "react-router-dom";
import ProviderCard from "../../components/cards/ProviderCard";

export default function PaymentProviders() {
  const dispatch = useAppDispatch();
  const { providerData, providerStatus } = useAppSelector(selectAllProviders);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (providerStatus === "idle") dispatch(fetchProvider());
  }, []);

  return (
    <Page title="Partner Offers">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4">Partner Offers</Typography>
          <Link to="/dashboard/new/payment">
            <Button variant="contained" sx={{ bgcolor: "#239f5e" }}>
              New Provider
            </Button>
          </Link>
        </Stack>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{ marginBottom: 2 }}
        >
          {typesArray.map((item, idx) => (
            <Tab key={idx} label={item} />
          ))}
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Stack spacing={3} m="0 auto" maxWidth={1100}>
            {providerData.length !== 0 && providerData !== undefined
              ? providerData.map(
                  (item) =>
                    item.providerType === "payment" && (
                      <ProviderCard
                        type={"payment"}
                        key={item._id}
                        props={item}
                      />
                    )
                )
              : null}
          </Stack>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Stack spacing={3} m="0 auto" maxWidth={1100}>
            {providerData.length !== 0 && providerData !== undefined
              ? providerData.map(
                  (item) =>
                    item.providerType === "insurance" && (
                      <ProviderCard
                        type={"insurance"}
                        key={item._id}
                        props={item}
                      />
                    )
                )
              : null}
          </Stack>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <Stack spacing={3} m="0 auto" maxWidth={1100}>
            {providerData.length !== 0 && providerData !== undefined
              ? providerData.map(
                  (item) =>
                    item.providerType === "investing" && (
                      <ProviderCard
                        type={"investing"}
                        key={item._id}
                        props={item}
                      />
                    )
                )
              : null}
          </Stack>
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          <Stack spacing={3} m="0 auto" maxWidth={1100}>
            {providerData.length !== 0 && providerData !== undefined
              ? providerData.map(
                  (item) =>
                    item.providerType === "loan" && (
                      <ProviderCard type={"loan"} key={item._id} props={item} />
                    )
                )
              : null}
          </Stack>
        </TabPanel>
      </Container>
    </Page>
  );
}

const typesArray = ["Payment", "Insurance", "Investing", "Loans"];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
