import { IconButton, Menu } from "@mui/material";
import React, { Dispatch, ReactNode, SetStateAction } from "react";

interface ButtonMenuProps {
  iconButton: ReactNode;
  children: ReactNode;
  colorButton: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  colorButtonBorder: string;
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
}

const ButtonMenu: React.FC<ButtonMenuProps> = ({
  anchorEl,
  children,
  colorButton,
  colorButtonBorder,
  iconButton,
  setAnchorEl,
}) => {
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        sx={{
          transition: "all 500ms",
          borderRadius: 0,
          borderBottom: anchorEl ? `2px solid ${colorButtonBorder}` : "2px solid #239f5f00",
          p: 0,
        }}
        color={anchorEl ? colorButton : "default"}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {iconButton}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onClose={() => setAnchorEl(null)}
      >
        {children}
      </Menu>
    </>
  );
};

export default ButtonMenu;
