import { Button, Modal, Stack, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/redux/hooks";
import { selectCompanies } from "../../../core/redux/features/companies/companiesSlice";
import {
  fetchCompanies,
  updateRank,
} from "../../../core/redux/features/companies/companyThunks";

type Props = {
  name: string;
  id: string;
};

const UpdateRank = (props: Props) => {
  const dispatch = useAppDispatch();
  const companies = useAppSelector(selectCompanies);
  const [open, setOpen] = useState(false);
  const [rank, setRank] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(updateRank({ rank: rank, companyId: props.id }));
  };

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  return (
    <>
      <Button onClick={handleOpen} variant="contained">
        Update Rank
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Stack
          p="20px"
          borderRadius={2}
          width={350}
          textAlign="center"
          spacing={2}
          sx={style}
          component="form"
          onSubmit={handleSubmit}
        >
          <Typography variant="h6" lineHeight={1} color="GrayText">
            Input the new rank for {props.name}
          </Typography>

          <TextField
            InputProps={{ inputProps: { min: 0, max: companies.length } }}
            value={rank}
            type="number"
            onChange={(e) => setRank(parseInt(e.target.value))}
            label="Rank"
            required
            size="small"
          />

          <Button type="submit" variant="contained" size="small">
            Update
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default UpdateRank;
