// @mui
import PropTypes from "prop-types";
import { styled, alpha } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";

// utils
import { fShortenNumber } from "../../../utils/formatNumber";
// components

import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8.2),
  height: theme.spacing(8.2),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
  percent: PropTypes.string,
};

export default function AppWidgetSummary({
  title,
  total,
  percent,
  icon,
  color = "primary",
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        py: 5,
        boxShadow: 2,
        textAlign: "center",
        color: (theme) => theme.palette[color].darker,
        bgcolor: "#fff",
        ...sx,
        ":hover": {
          boxShadow:
            "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;",
          cursor: "pointer",
          transform: " scale(105%)",
          transition: "1100ms",
        },
      }}
      {...other}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Typography fontSize="15px">{percent}</Typography>
        <Iconify icon={icon} width={33} height={33} />
      </IconWrapperStyle>

      <Typography variant="h3" color="#239F5E">
        {fShortenNumber(total)}
      </Typography>

      <Typography variant="subtitle2" fontSize="13px" color="#4B6657" sx={{ opacity: 0.91 }}>
        {title}
      </Typography>
    </Card>
  );
}
