// material
import React from "react";
import { Avatar, Box, Card, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';


// ----------------------------------------------------------------------

interface AllCardsProps {
  title: string,
  discription: string,
  compeNstation: string,
  jobType: string,
};

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const AllCards: React.FC<AllCardsProps> = (props) => {

  return (
    <Card sx={{ height: "180px", width: "190%" }}>
      <Box padding="15px 16px">
        <Box display="flex" justifyContent="space-between">
          <Box display={"flex"} gap="15px">
            <Avatar
              style={{ width: "60px", height: "60px", objectFit: "cover" }}
              src="https://company.gamehag.com/img/title-logo.png"
            />
            <Box display="flex" flexDirection={"column"} paddingTop='5px'>
              <Typography fontSize='16px' fontWeight='600' color='rgb(35, 159, 94)'>{props.title}</Typography>
              <Typography fontWeight='600'>{props.discription}</Typography>
            </Box>
          </Box>
          <Box>
            <Checkbox {...label} icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'} p='40px 10px'>
          <Box >
            <Typography fontSize='15px' mb={0.6} fontWeight='600' color='rgb(35, 159, 94)'>Compensation</Typography>
            <Typography fontSize='14px' fontWeight='600' >{props.compeNstation}</Typography>

          </Box>
          <Box>
            <Typography fontSize='15px' mb={0.6} fontWeight='600' color='rgb(35, 159, 94)'>Job Type</Typography>
            <Typography fontSize='14px' mb={0.6} fontWeight='600' >{props.jobType}</Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}


export default AllCards