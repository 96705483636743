import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./features/auth/authSlice";
import companiesSlice from "./features/companies/companiesSlice";
import jobSlice from "./features/jobs/jobSlice";
import joinSlice from "./features/join/joinSlice";
import knowledgeSlice from "./features/knowledge/knowledgeSlice";
import locationsSlice from "./features/locations/locationsSlice";
import messeagesSlice from "./features/messeages/messeagesSlice";
import minorSlice from "./features/minor/minorSlice";
import paymentSlice from "./features/payment/paymentSlice";
import statsSlice from "./features/stats/statsSlice";
import userSlice from "./features/users/userSlice";
import utilitiesSlice from "./features/utilities/utilitiesSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    stats: statsSlice,
    users: userSlice,
    jobs: jobSlice,
    company: companiesSlice,
    minor: minorSlice,
    payment: paymentSlice,
    location: locationsSlice,
    knowledge: knowledgeSlice,
    utilities: utilitiesSlice,
    messages: messeagesSlice,
    join: joinSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
