/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Container,
  Stack,
  Typography,
  Paper,
  Divider,
  Button,
  FormControlLabel,
  Switch,
  Breadcrumbs,
  Link as MUILink,
} from "@mui/material";
import Page from "../../components/Page";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { selectCountries } from "../../core/redux/features/locations/locationsSlice";
import { fetchCountries, toggleCountryEnabled } from "../../core/redux/features/locations/locationThunks";
import { Link } from "react-router-dom";
import { ICountry } from "../../core/types/locations.types";

export default function Locations() {
  const dispatch = useAppDispatch();
  const countries = useAppSelector(selectCountries);

  useEffect(() => {
    if (countries.status === "idle") {
      dispatch(fetchCountries());
    }
  }, []);

  const handleToggleEnabled = (countryCode: string, enabled: boolean) => {
    dispatch(toggleCountryEnabled({ countryCode, enabled }));
  };

  return (
    <Page title="Manage Countries">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <MUILink component={Link} underline="hover" color="inherit" to="/dashboard/app">
              Dashboard
            </MUILink>
            <Typography color="text.primary">Locations</Typography>
          </Breadcrumbs>
        </Stack>

        <Paper sx={{ backgroundColor: "#e0f2f1" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: "10px 20px" }}>
            <Typography variant="h4">Countries</Typography>
          </Stack>
          <Divider />
          <Stack mt={3}>
            {countries.data.map((country, index) => (
              <CountryItem
                key={country._id}
                country={country}
                index={index}
                handleToggleEnabled={handleToggleEnabled}
              />
            ))}
          </Stack>
        </Paper>
      </Container>
    </Page>
  );
}

function CountryItem(props: {
  country: ICountry;
  index: number;
  handleToggleEnabled: (countryCode: string, enabled: boolean) => void;
}) {
  const { country, index, handleToggleEnabled } = props;

  return (
    <Stack
      key={country._id}
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      sx={{
        backgroundColor: index % 2 ? "#e0f2f1" : "#b2dfdb",
        width: "100%",
      }}
    >
      <Stack direction="row" alignItems={"center"} gap="20px" p={"2px 10px"}>
        <Typography variant="h3">{country.flag}</Typography>
        <Typography variant="body1">{country.name}</Typography>
      </Stack>
      <Stack direction="row" alignItems={"center"} gap="20px" p={"2px 10px"}>
        <FormControlLabel
          control={
            <Switch checked={country.enabled} onChange={() => handleToggleEnabled(country.isoCode, !country.enabled)} />
          }
          label={country.enabled ? "Disable" : "Enable"}
        />

        {country.enabled ? (
          <Link to={`state/${country.isoCode}`}>
            <Button>Manage States</Button>
          </Link>
        ) : (
          ""
        )}
      </Stack>
    </Stack>
  );
}
