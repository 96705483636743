import { Flip, toast } from "react-toastify";

export const toastSuccess = (text: string) =>
  toast(text, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "success",
    transition: Flip,
  });

export const toastError = (text: string) =>
  toast(text, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "error",
    transition: Flip,
  });

export const toastWarning = (text: string) =>
  toast(text, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: "warning",
    transition: Flip,
  });
