/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, Suspense, lazy } from "react";
import {
  Breadcrumbs,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Typography,
  Link as MUILink,
  Box,
  CircularProgress,
} from "@mui/material";
import Page from "../../components/Page";
import { Link, useParams } from "react-router-dom";
import { selectStates } from "../../core/redux/features/locations/locationsSlice";
import { fetchStates, fetchStatesMore, toggleStateEnabled } from "../../core/redux/features/locations/locationThunks";
import { useAppDispatch, useAppSelector } from "../../core/redux/hooks";
import { IState } from "../../core/types/locations.types";
import Iconify from "../../components/Iconify";

const SearchCity = lazy(() => import("./SearchCity"));

export default function States() {
  const { countryCode } = useParams();
  const dispatch = useAppDispatch();
  const states = useAppSelector(selectStates);
  const [skip, setSkip] = useState(20);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchStates(countryCode as string));
  }, [countryCode]);

  const handleToggleEnabled = (countryCode: string, stateCode: string, enabled: boolean) => {
    dispatch(toggleStateEnabled({ countryCode, stateCode, enabled }));
  };

  const handleLoadMore = () => {
    setSkip(skip + 20);
    dispatch(fetchStatesMore({ countryCode: countryCode as string, skip, limit: 20 }));
  };

  return (
    <Page title="Manage States">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <MUILink component={Link} underline="hover" color="inherit" to="/dashboard/app">
              Dashboard
            </MUILink>
            <MUILink component={Link} underline="hover" color="inherit" to={"/dashboard/location"}>
              Locations
            </MUILink>
            <Typography color="text.primary">{countryCode}</Typography>
          </Breadcrumbs>
        </Stack>

        <Paper sx={{ backgroundColor: "#e0f2f1" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: "10px 20px" }}>
            <Typography variant="h4">
              States ({states.data.length}/{states.count})
            </Typography>
            <Button variant="contained" startIcon={<Iconify icon="eva:search-fill" />} onClick={() => setOpen(!open)}>
              Search Cities
            </Button>
          </Stack>
          <Divider />
          <Stack mt={3}>
            {states.data.map((state, index) => (
              <StateItem key={state._id} state={state} index={index} handleToggleEnabled={handleToggleEnabled} />
            ))}
          </Stack>
          <Divider />
          <Suspense
            fallback={
              <Box
                width="100vw"
                position="absolute"
                height="90vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress sx={{ color: "#0e2944" }} />
              </Box>
            }
          >
            {countryCode ? (
              <Stack>
                <SearchCity countryCode={countryCode} open={open} setOpen={setOpen} />
              </Stack>
            ) : null}
          </Suspense>
          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ padding: "10px 20px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLoadMore}
              disabled={states.data.length === states.count}
            >
              load more
            </Button>
          </Stack>
        </Paper>
      </Container>
    </Page>
  );
}

function StateItem(props: {
  state: IState;
  index: number;
  handleToggleEnabled: (countryCode: string, stateCode: string, enabled: boolean) => void;
}) {
  const { state, index, handleToggleEnabled } = props;

  return (
    <Stack
      key={state._id}
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      sx={{
        backgroundColor: index % 2 ? "#e0f2f1" : "#b2dfdb",
        width: "100%",
      }}
    >
      <Stack direction="row" alignItems={"center"} gap="20px" p={"2px 10px"}>
        <Typography variant="body2">{index + 1}</Typography>

        <Typography variant="body1">{state.name}</Typography>
      </Stack>
      <Stack direction="row" alignItems={"center"} gap="20px" p={"2px 10px"}>
        <FormControlLabel
          control={
            <Switch
              checked={state.enabled}
              onChange={() => handleToggleEnabled(state.countryCode, state.isoCode, !state.enabled)}
            />
          }
          label={state.enabled ? "Disable" : "Enable"}
        />

        {state.enabled ? (
          <Link to={`/dashboard/location/city/${state.countryCode}/${state.isoCode}`}>
            <Button>Manage Cities</Button>
          </Link>
        ) : (
          ""
        )}
      </Stack>
    </Stack>
  );
}
