import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";
import { JobCityForm } from "../../../types/job.types";

export const fetchDynamicJobs = createAsyncThunk(
  "jobs/fetchDynamicJobs",
  async (data: { limit: number; skip: number }) => {
    const res = await request.get(
      `/api/v1/new-job?limit=${data.limit}&skip=${data.skip}`
    );

    return res.data;
  }
);

export const fetchNewJobs = createAsyncThunk("jobs/fetchNewJobs", async () => {
  const res = await request.get(`/api/v1/job/new/global`);
  return res.data;
});

export const fetchAllJobs = createAsyncThunk(
  "jobs/fetchAllJobs",
  async (data: { token: string | null; limit: number; skip: number }) => {
    const res = await request.get(
      `/api/v1/job?limit=${data.limit}&skip=${data.skip}`
    );
    return res.data;
  }
);

export const fetchPendingJobs = createAsyncThunk(
  "jobs/fetchPendingJobs",
  async (data: { token: string | null; limit: number; skip: number }) => {
    const res = await request.get(
      `/api/v1/job/pending-jobs?limit=${data.limit}&skip=${data.skip}`
    );
    return res.data;
  }
);

export const fetchPendingJobsByStatus = createAsyncThunk(
  "jobs/fetchPendingJobsByStatus",
  async (data: {
    token: string | null;
    limit: number;
    skip: number;
    status: string;
  }) => {
    const res = await request.get(
      `/api/v1/job/pending-jobs/status?limit=${data.limit}&skip=${data.skip}&jobStatus=${data.status}`
    );
    return res.data;
  }
);

export const fetchJobsByCompany = createAsyncThunk(
  "jobs/fetchJobsByCompany",
  async (data: { slug: string | null; limit: number; skip: number }) => {
    const res = await request.get(
      `api/v1/company/jobs/${data.slug}?limit=${data.limit}&skip=${data.skip}`
    );
    return res.data;
  }
);

export const updateJobById = createAsyncThunk(
  "jobs/updateJobById",
  async ({ body, id }: { body: JobCityForm; id: string }) => {
    const res = await request.patch(`/api/v1/new-job/${id}`, body);
    // const res = await request.patch(`/api/v1/job/${id}`, body);

    return res.data;
  }
);

export const updateJobInstancesById = createAsyncThunk(
  "jobs/updateJobInstancesById",
  async ({ body, id }: { body: JobCityForm; id: string }) => {
    const res = await request.patch(
      `/api/v1/job/company/edit-all-instances/${id}`,
      body
    );

    return res.data;
  }
);

export const deleteJobById = createAsyncThunk(
  "jobs/deleteJobById",
  async (id: string) => {
    const res = await request.delete(`/api/v1/job/${id}`);
    return res.data;
  }
);

export const deleteNewJobById = createAsyncThunk(
  "jobs/deleteNewJobById",
  async (id: string) => {
    const res = await request.delete(`/api/v1/new-job/${id}`);
    return res.data;
  }
);

export const deletePendingJobById = createAsyncThunk(
  "jobs/deletePendingJobById",
  async (id: string) => {
    const res = await request.delete(`/api/v1/job/pending-jobs/${id}`);
    return res.data;
  }
);

export const rejectPendingJobById = createAsyncThunk(
  "jobs/rejectPendingJobById",
  async (req: {
    id: string;
    rejection: {
      reason: string;
      additionalInfo: string;
      templateBasedText: string;
    };
  }) => {
    const res = await request.patch(
      `/api/v1/job/pending-jobs/reject/${req.id}`,
      req.rejection
    );
    return res.data;
  }
);

export const approvePendingJobById = createAsyncThunk(
  "jobs/approvePendingJobById",
  async ({ body, id }: { body: JobCityForm; id: string }) => {
    const res = await request.post(
      `/api/v1/job/pending-jobs/approve/${id}`,
      body
    );
    return res.data;
  }
);

export const deleteJobsByCompany = createAsyncThunk(
  "jobs/deleteJobsByCompany",
  async (id: string) => {
    const res = await request.delete(`/api/v1/job/company/delete/${id}`);
    return res.data;
  }
);

// export const deleteMultipleJobs = createAsyncThunk("jobs/deleteMultipleJobs", async (jobIds: string[]) => {
//   const res = await request.delete(`/api/v1/job/deleteMultiple`, jobIds);
//   return res.data;
// });

export const createJobCites = createAsyncThunk(
  "jobs/createJobCites",
  async (data: {
    body: JobCityForm;
    many: boolean;
    manyInCountry: boolean;
  }) => {
    let url = "/api/v1/job/city/many";
    if (data.many) {
      url = "/api/v1/job/many";
    }
    if (data.manyInCountry) {
      url = "/api/v1/job/country/many";
    }

    const res = await request.post(url, data.body);

    return res.data;
  }
);

export const createOptimizedJobs = createAsyncThunk(
  "jobs/createOptimizedJobs",
  async (data: JobCityForm) => {
    let url = "/api/v1/new-job";
    const res = await request.post(url, data);
    return res.data;
  }
);
