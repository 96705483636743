import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PaymentForm from "../../sections/@dashboard/payment/PaymentForm";
import { Container, Stack, Tab, Tabs, Box, Grid, Icon } from "@mui/material";
import Page from "../../components/Page";
import { Link } from "react-router-dom";

import ShowChartIcon from "@mui/icons-material/ShowChart";
import PaymentIcon from "@mui/icons-material/Payment";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ShieldIcon from "@mui/icons-material/Shield";
import InsuranceForm from "../../sections/@dashboard/payment/InsuranceForm";
import InvestingForm from "../../sections/@dashboard/payment/InvestingForm";
import LoanForm from "../../sections/@dashboard/payment/LoanForm";

interface NewProviderProps {}

const NewProvider: React.FC<NewProviderProps> = () => {
  const [providerType, setProviderType] = useState("");
  return (
    <Page title="New Payment Provider">
      <Container maxWidth="xl">
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">New Provider</Typography>
            <Stack direction="row" gap={2}>
              {providerType !== "" && (
                <Button
                  variant="contained"
                  onClick={() => setProviderType("")}
                  sx={{ bgcolor: "#239f5e" }}
                >
                  Back
                </Button>
              )}
              <Link to="/dashboard/payment">
                <Button variant="contained" sx={{ bgcolor: "#239f5e" }}>
                  All Providers
                </Button>
              </Link>
            </Stack>
          </Stack>
          {providerType === "" && (
            <SelectProviderType setProviderType={setProviderType} />
          )}
          {providerType === "Payment" && <PaymentForm />}
          {providerType === "Investing" && <InvestingForm />}
          {providerType === "Insurance" && <InsuranceForm />}
          {providerType === "Loans" && <LoanForm />}
        </Stack>
      </Container>
    </Page>
  );
};

export default NewProvider;

type ProviderProps = {
  setProviderType: React.Dispatch<React.SetStateAction<string>>;
};

const SelectProviderType = (props: ProviderProps) => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"} gap={3}>
      <Typography variant="h5">Select Provider Type</Typography>
      <Grid container width="50%" rowSpacing={2}>
        {typesArray.map((item, idx) => (
          <Grid
            key={idx}
            item
            xs={12}
            md={6}
            display="flex"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <Button
              onClick={() => props.setProviderType(item.title)}
              sx={{ display: "flex", gap: "8px", flexDirection: "column" }}
            >
              <Icon component={item.icon} fontSize="large" />
              <Typography>{item.title}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

const typesArray = [
  { title: "Payment", icon: PaymentIcon },
  { title: "Insurance", icon: ShieldIcon },
  { title: "Investing", icon: ShowChartIcon },
  { title: "Loans", icon: CreditScoreIcon },
];
