/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Button,
  Rating,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  CreateProvider,
  UpdateProvider,
} from "../../../core/redux/features/payment/paymentThunks";
import { useAppDispatch } from "../../../core/redux/hooks";
import {
  InsuranceFormType,
  InsuranceType,
} from "../../../core/types/payment.types";

interface InsuranceFormProps extends Partial<InsuranceType> {
  handleClose?: () => void;
}

const InsuranceForm: React.FC<InsuranceFormProps> = (props) => {
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm<InsuranceFormType>({ mode: "all" });

  useEffect(() => {
    setValue("mobileApp", props?.mobileApp || undefined);
    setValue("trustPilotRating", props?.trustPilotRating || undefined);
    props?.availableCountriesUrl &&
      setValue("availableCountriesUrl", props?.availableCountriesUrl);
    setValue("numberOfCountries", props?.numberOfCountries || undefined);
    setValue("position", props?.position || undefined);
    setValue("websiteUrl", props?.websiteUrl || "");
    props?.maximumCoverage &&
      setValue("maximumCoverage", props?.maximumCoverage);
    props?.minimumCoverage &&
      setValue("minimumCoverage", props?.minimumCoverage);
    setValue("description", props?.description || "");
    setValue("logo", props?.logo || "");
    setValue("title", props?.title || "");
  }, [props]);

  const onSubmit: SubmitHandler<InsuranceFormType> = (data) => {
    if (props.handleClose) {
      props.handleClose();
      dispatch(
        UpdateProvider({
          body: {
            ...data,
            position: data.position,
            minimumCoverage: parseInt(data.minimumCoverage.toString()),
            maximumCoverage: parseInt(data.maximumCoverage.toString()),
            numberOfCountries: Number(data.numberOfCountries),
          },
          id: String(props._id),
        })
      );
      reset();
    } else {
      dispatch(
        CreateProvider({
          ...data,
          providerType: "insurance",
          position: Number(data.position),
          minimumCoverage: parseInt(data.minimumCoverage.toString()),
          maximumCoverage: parseInt(data.maximumCoverage.toString()),
          numberOfCountries: Number(data.numberOfCountries),
        })
      );
      reset();
    }
  };
  return (
    <Stack
      width="100%"
      component="form"
      spacing={2}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack width="100%" spacing={2} direction="row">
        <TextField
          size="small"
          label={errors.title?.message ? errors.title?.message : "Title"}
          error={!!errors.title?.message}
          {...register("title", { required: "Title is reqired" })}
          fullWidth
        />
        <TextField
          size="small"
          label={
            errors.websiteUrl?.message
              ? errors.websiteUrl?.message
              : "Website Url"
          }
          error={!!errors.websiteUrl?.message}
          {...register("websiteUrl", { required: "Website Url is reqired" })}
          fullWidth
          type="url"
        />
      </Stack>

      <TextField
        size="small"
        label={errors.logo?.message ? errors.logo?.message : "Logo Url"}
        error={!!errors.logo?.message}
        {...register("logo", { required: "Logo Url is reqired" })}
        fullWidth
        type="url"
      />

      <Stack width="100%" spacing={2} direction="row" alignItems="center">
        <TextField
          size="small"
          label="TrustPilot Rating"
          {...register("trustPilotRating.rating")}
          fullWidth
        />
        <Controller
          name="trustPilotRating.rating"
          control={control}
          render={({ field }) => (
            <Rating
              {...field}
              defaultValue={props?.trustPilotRating?.rating || 0}
              precision={0.1}
              color="red"
              size="large"
              readOnly
            />
          )}
        />

        <TextField
          size="small"
          label="TrustPilot Rating Url"
          {...register("trustPilotRating.url")}
          fullWidth
          type="url"
        />
      </Stack>

      <Stack width="100%" spacing={2} direction="row">
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              {...register("mobileApp.exist")}
              defaultChecked={props?.mobileApp?.exist || false}
            />
          }
          label="Exist"
          labelPlacement="start"
        />
        <TextField
          size="small"
          label="GooglePlay URL"
          {...register("mobileApp.androidUrl")}
          type="url"
          fullWidth
        />
        <TextField
          size="small"
          label="AppStore URL"
          {...register("mobileApp.iosUrl")}
          type="url"
          fullWidth
        />
        <TextField
          size="small"
          label="AppGallery URL"
          {...register("mobileApp.huaWeiUrl")}
          type="url"
          fullWidth
        />
      </Stack>

      <Stack width="100%" spacing={2} direction="row">
        <TextField
          label="Available Countries Url"
          size="small"
          {...register("availableCountriesUrl")}
          fullWidth
          type="url"
        />
      </Stack>

      <Stack width="100%" spacing={2} direction="row">
        <TextField
          size="small"
          label="Position"
          {...register("position")}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          size="small"
          label="Number Of Countries"
          {...register("numberOfCountries")}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>

      <Stack width="100%" spacing={2} direction="row">
        <TextField
          size="small"
          label="Minimum Coverage"
          {...register("minimumCoverage")}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          size="small"
          label="Maximum Coverage"
          {...register("maximumCoverage")}
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          size="small"
          label="Restrictions"
          {...register("restrictions")}
          // type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />

        <FormControl size="small" fullWidth>
          <InputLabel>Term Lengths</InputLabel>
          <Controller
            name="termLengths"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Select size="small" label="Available Assets" multiple {...field}>
                {availableTermLengths.map((item, idx) => (
                  <MenuItem key={idx} value={item.value}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Stack>

      <TextField
        label={
          errors.description?.message
            ? errors.description?.message
            : "Description"
        }
        error={!!errors.description?.message}
        {...register("description", { required: "Description is reqired" })}
        fullWidth
        multiline
        rows={6}
      />
      <Button variant="contained" sx={{ bgcolor: "#239f5e" }} type="submit">
        {props._id ? "Save" : "Create"}
      </Button>
    </Stack>
  );
};

export default InsuranceForm;

const availableTermLengths = [
  { title: "10 years", value: "10" },
  { title: "15 years", value: "15" },
  { title: "20 years", value: "20" },
  { title: "25 years", value: "25" },
  { title: "30 years", value: "30" },
  { title: "Lifetime", value: "Lifetime" },
];
