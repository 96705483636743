import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Paper,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import moment from "moment";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "../core/redux/hooks";
import { removeBlogPost } from "../core/redux/features/companies/companyThunks";
import { toastSuccess } from "../components/CustomToastify";
import { CompanyData } from "../core/types/company.types";
export interface BlogItemProps {
  id: string;
  title: string;
  preview: string;
  mainImage: {
    asset: {
      _ref: string;
    };
  };
  length: string;
  author: {
    name: string;
    image: {
      asset: {
        _ref: string;
      };
    };
  };
  publishedAt: string;
}
const MiniBlogCard = ({
  link,
  companyId,
  setCompany,
}: {
  link: string;
  companyId: string;
  setCompany: React.Dispatch<React.SetStateAction<CompanyData | null>>;
}) => {
  const dispatch = useAppDispatch();
  const [blogPost, setBlogPost] = useState<BlogItemProps>({
    id: "",
    title: "",
    preview: "",
    mainImage: {
      asset: {
        _ref: "",
      },
    },
    length: "",
    author: {
      name: "",
      image: {
        asset: {
          _ref: "",
        },
      },
    },
    publishedAt: "",
  });

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const linkParts = link.split("/");
    const blogPosts = async () => {
      try {
        const query =
          encodeURIComponent(`*[ _type == "post" && slug.current == "${
            linkParts[linkParts.length - 1]
          }" ][0]{
                author->, 
                mainImage, 
                postType->, 
                publishedAt,
                length, 
                body, 
                title, 
                slug, 
                imageUrl,
                preview,
                "categories": categories[]->title,
              }`);
        const url = `https://dktu0a78.api.sanity.io/v1/data/query/production?query=${query}`;
        const result = await fetch(url).then((res) => res.json());
        setBlogPost(result.result);
      } catch (error) {
        console.log("Error", error);
      }
    };
    blogPosts();
  }, [link]);

  const previewImage = blogPost?.mainImage?.asset?._ref
    .slice(6)
    .replace(/-jpg/, ".jpg")
    .replace(/-png/, ".png");
  const authorImg = blogPost?.author?.image?.asset?._ref
    .slice(6)
    .replace(/-jpg/, ".jpg")
    .replace(/-png/, ".png");

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(removeBlogPost({ link, companyId })).then((res) => {
      setCompany(res.payload.body);
      toastSuccess("Post added successfully");
    });
    setModalOpen(false);
  };

  return (
    <Stack>
      <Dialog open={modalOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Remove Blog Post</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to detach this post from this company?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button type="submit">Yes</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Stack
        maxWidth={400}
        component={Paper}
        width="100%"
        borderRadius="12px"
        sx={{ cursor: "pointer" }}
        height="100%"
        justifyContent="space-between"
        spacing={1}
      >
        <Stack height="100%" justifyContent="space-between">
          <Stack
            gap={1}
            height={{
              xs: "87px",
              sm: "160px",
              md: "150px",
              lg: "250px",
              xl: "250px",
            }}
          >
            <Box
              width="100%"
              height="100%"
              borderRadius="12px 12px 0 0"
              sx={{
                background: `url(https://cdn.sanity.io/images/dktu0a78/production/${previewImage})`,
                backgroundSize: "cover",
                objectFit: "cover",
              }}
              justifyContent="center"
              alignItems="center"
              bgcolor="#B5D3C3"
            />
            <Stack padding={1}>
              <Typography variant={"h14"} color="primary" fontWeight={700}>
                {blogPost?.title}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Stack
              padding="4px 0px"
              // gap="4px"
              width="100%"
              alignItems="center"
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              }}
              justifyContent="space-between"
            >
              <img
                alt={blogPost?.title}
                width={55}
                height={50}
                src={`https://cdn.sanity.io/images/dktu0a78/production/${authorImg}`}
              />
              <Stack
                // justifyContent="center"
                gap="4px"
                width="100%"
                height="100%"
              >
                <Typography color="#155634" fontWeight={600} variant="h12">
                  {blogPost?.author.name}
                </Typography>
                <Stack width="100%" direction="row" gap={1} alignItems="center">
                  <Typography color="#4B6657" fontSize="10px" fontWeight="500">
                    {moment(blogPost?.publishedAt).format("MMM Do YYYY")}
                  </Typography>
                  <Box
                    width="5px"
                    height="5px"
                    bgcolor="#2AAD68"
                    sx={{ borderRadius: "50%" }}
                  ></Box>
                  <Typography
                    variant={"h10"}
                    color="#155634"
                    alignSelf={{ xs: "center", sm: "flex-end" }}
                    fontWeight={600}
                  >
                    {blogPost.length} mins
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <IconButton
              // sx={{ zIndex: 999 }}
              title="Remove Post"
              onClick={(e) => {
                e.stopPropagation();
                setModalOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Link
          href={link}
          style={{ textDecoration: "none" }}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          <Stack
            p="5px"
            justifyContent="flex-end"
            bgcolor="#239F5E"
            direction="row"
            borderRadius="0px 0px 12px 12px"
          >
            <Typography
              variant="h14"
              sx={{ textAlign: "center" }}
              lineHeight="18px"
              alignItems="center"
              fontWeight={450}
              color="#FFFF"
            >
              Continue to reading
            </Typography>
            <IconButton
              aria-label="Menu"
              sx={{ height: "21px", width: "19px", color: "#239f5e" }}
            >
              <ArrowForwardIosRoundedIcon
                sx={{ height: "14px", width: "19px", color: "#FFF" }}
              />
            </IconButton>
          </Stack>
        </Link>
      </Stack>
    </Stack>
  );
};

export default MiniBlogCard;
