import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";

export const fetchCountries = createAsyncThunk("locations/fetchCountries", async () => {
  const response = await request("/api/v1/manage-location");
  return response.data;
});

export const fetchStates = createAsyncThunk("locations/fetchStates", async (countryCode: string) => {
  const response = await request(`/api/v1/manage-location/states/${countryCode}?limit=20&skip=0`);
  return response.data;
});

export const fetchStatesMore = createAsyncThunk(
  "locations/fetchStatesMore",
  async ({ countryCode, limit, skip }: { countryCode: string; limit: number; skip: number }) => {
    const response = await request(`/api/v1/manage-location/states/${countryCode}?limit=${limit}&skip=${skip}`);
    return response.data;
  }
);

export const fetchCities = createAsyncThunk(
  "locations/fetchCities",
  async (payload: { countryCode: string; stateCode: string }) => {
    const response = await request(
      `/api/v1/manage-location/cities/${payload.countryCode}/${payload.stateCode}?limit=20&skip=0`
    );
    return response.data;
  }
);

export const fetchCitiesMore = createAsyncThunk(
  "locations/fetchCitiesMore",
  async (payload: { countryCode: string; stateCode: string; limit: number; skip: number }) => {
    const response = await request(
      `/api/v1/manage-location/cities/${payload.countryCode}/${payload.stateCode}?limit=${payload.limit}&skip=${payload.skip}`
    );
    return response.data;
  }
);

export const toggleCountryEnabled = createAsyncThunk(
  "locations/toggleCountryEnabled",
  async (payload: { countryCode: string; enabled: boolean }) => {
    const response = await request.patch(`/api/v1/manage-location/${payload.countryCode}`, {
      enabled: payload.enabled,
    });
    return response.data;
  }
);

export const toggleStateEnabled = createAsyncThunk(
  "locations/toggleStateEnabled",
  async (payload: { countryCode: string; stateCode: string; enabled: boolean }) => {
    const response = await request.patch(`/api/v1/manage-location/state/${payload.countryCode}/${payload.stateCode}`, {
      enabled: payload.enabled,
    });
    return response.data;
  }
);

export const toggleCityEnabled = createAsyncThunk(
  "locations/toggleCityEnabled",
  async (payload: { countryCode: string; stateCode: string; cityName: string; enabled: boolean }) => {
    const response = await request.patch(
      `/api/v1/manage-location/city/${payload.countryCode}/${payload.stateCode}/${payload.cityName}`,
      {
        enabled: payload.enabled,
      }
    );
    return response.data;
  }
);

export const fetchConfirmedCountries = createAsyncThunk("locations/fetchConfirmedCountries", async () => {
  const response = await request("/api/v1/manage-location?enabled=true");
  return response.data;
});

export const fetchConfirmedStates = createAsyncThunk("locations/fetchConfirmedStates", async (countryCode: string) => {
  const response = await request(`/api/v1/manage-location/states/${countryCode}?enabled=true`);
  return response.data;
});
export const fetchConfirmedCities = createAsyncThunk(
  "locations/fetchConfirmedCities",
  async (payload: { countryCode: string; stateCode: string }) => {
    const response = await request(
      `/api/v1/manage-location/cities/${payload.countryCode}/${payload.stateCode}?enabled=true`
    );
    return response.data;
  }
);
