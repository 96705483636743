import { request } from "./Axios";

export const uploadToSpaces = async (formData: FormData) => {
  const { data } = await request({
    method: "POST",
    url: "/api/v1/uploads/spaces",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return data.body;
};
