import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Button, Divider, FormControl, Grid, InputLabel, Rating, Select, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
// Hook-form
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { CompanyData, Review } from "../../../core/types/company.types";
import { useAppDispatch } from "../../../core/redux/hooks";
import { v4 as uuidv4 } from "uuid";
import { createCompanyReview, deleteReview, updateReview } from "../../../core/redux/features/companies/reviewThunks";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ConfirmPopper from "../../../components/ConfirmPopper";

type Inputs = {
  providerName: string;
  sourceUrl: string;
  logoUrl: string;
  scale: "stars" | "numeric" | "percentage";
  numOfRespondents: number;
  rating: number;
  outOf: number;
  description?: string;
};

type Props = {
  company: CompanyData;
};

const ReviewContainer: React.FC<Props> = ({ company }) => {
  const dispatch = useAppDispatch();

  const handleCreate = (payload: { body: Review; company_id: string }, review_id: any) => {
    const _id = uuidv4();
    const data = { body: { ...payload.body, _id }, company_id: payload.company_id };
    dispatch(createCompanyReview(data));
  };

  const handleEdit = (
    payload: {
      body: {
        providerName?: string;
        sourceUrl?: string;
        logoUrl?: string;
        scale?: "stars" | "numeric" | "percentage";
        numOfRespondents?: number;
        rating?: number;
        outOf?: number;
        description?: string;
      };
      company_id: string;
    },
    review_id: string
  ) => {
    const data = { body: { ...payload.body, _id: review_id }, company_id: payload.company_id };
    dispatch(updateReview(data));
  };

  const handleDelete = (review_id: string) => {
    dispatch(deleteReview({ company_id: company._id, review_id }));
  };

  return (
    <Box
      mt={"20px"}
      sx={{
        padding: {
          xs: "40px 22px 20px 20px",
          md: "40px 22px 40px 20px",
        },
        width: "100%",
        bgcolor: "#fff",
      }}
    >
      <Typography component={"h3"} variant="h6">
        Reviews
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Add a new review</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ReviewForm
            company_id={company._id}
            review={{
              _id: "",
              providerName: "",
              sourceUrl: "",
              logoUrl: "",
              scale: "stars",
              numOfRespondents: 0,
              rating: 0,
              outOf: 0,
              description: "",
            }}
            submitAction={handleCreate}
          />
        </AccordionDetails>
      </Accordion>

      <Divider sx={{ m: "20px 0" }} />

      <Typography component={"h3"} variant="h6">
        All Reviews
      </Typography>

      {company.reviews?.length
        ? company.reviews.map((c) => (
            <Accordion key={uuidv4()}>
              <AccordionSummary expandIcon={<Edit />}>
                <Box display={"flex"} alignItems="center" justifyContent="space-between">
                  <Box display={"flex"} alignItems="center" gap="20px">
                    <Avatar src={c.logoUrl} alt={c.providerName} />
                    <Typography variant="h6">
                      <Link to={c.sourceUrl} target="_blank" color="inherit">
                        {c.providerName}
                      </Link>
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems="center" gap="10px" ml={"20px"}>
                    {c.scale === "stars" ? (
                      <>
                        {c.rating} <Rating readOnly value={c.rating} max={c.outOf} precision={0.1} />{" "}
                        {`(${c.numOfRespondents})`}
                      </>
                    ) : c.scale === "numeric" ? (
                      <Typography>
                        {c.rating}/{c.outOf} ({c.numOfRespondents})
                      </Typography>
                    ) : (
                      <Typography>
                        {c.rating}% ({c.numOfRespondents})
                      </Typography>
                    )}
                  </Box>
                  <ConfirmPopper onSubmit={() => handleDelete(c._id)}>
                    <Delete />
                  </ConfirmPopper>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ReviewForm company_id={company._id} review={c} submitAction={handleEdit} />
              </AccordionDetails>
            </Accordion>
          ))
        : ""}
    </Box>
  );
};

export default ReviewContainer;

const ReviewForm: React.FC<{
  company_id: string;
  review: Review;
  // eslint-disable-next-line no-unused-vars
  submitAction: (payload: any, review_id?: string | any) => void;
}> = ({ company_id, review, submitAction }) => {
  const { handleSubmit, control, watch } = useForm<Inputs>({
    defaultValues: {
      providerName: review.providerName,
      sourceUrl: review.sourceUrl,
      logoUrl: review.logoUrl,
      scale: review.scale,
      numOfRespondents: review.numOfRespondents,
      rating: review.rating,
      outOf: review.outOf,
      description: review.description,
    },
  });

  const watchScale = watch("scale", "stars");

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    submitAction(
      {
        body: {
          ...data,
          numOfRespondents: +data.numOfRespondents,
          outOf: +data.outOf,
          rating: +data.rating,
        },
        company_id: company_id,
      },
      review._id
    );
  };

  return (
    <Box component={"form"} mt={"20px"} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} mt={"15px"}>
        <Grid item xs={12} md={4}>
          <Controller
            name="providerName"
            control={control}
            render={({ field }) => <TextField label="Reviewer service name" fullWidth {...field} required />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="sourceUrl"
            control={control}
            render={({ field }) => <TextField label="Source link" fullWidth type="url" {...field} required />}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Controller
            name="logoUrl"
            control={control}
            render={({ field }) => <TextField label="logo url" fullWidth type="url" {...field} required />}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth required>
            <InputLabel>Review Scale</InputLabel>
            <Controller
              name="scale"
              control={control}
              render={({ field }) => (
                <Select label="Review Scale" {...field} required>
                  <MenuItem value="stars">stars - ⭐️ ⭐️ ⭐️ ⭐️ ⭐️</MenuItem>
                  <MenuItem value="numeric">numeric - 8.9/10</MenuItem>
                  <MenuItem value="percentage">based on percent - 90%</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="numOfRespondents"
            render={({ field }) => <TextField type="number" label="number of respondents" fullWidth {...field} />}
          />
        </Grid>
        <Grid item xs={2}>
          <Controller
            control={control}
            name="rating"
            render={({ field }) => <TextField label="rating" fullWidth type="number" {...field} />}
          />
        </Grid>
        <Grid item xs={2}>
          {watchScale !== "percentage" ? (
            <Controller
              control={control}
              name="outOf"
              render={({ field }) => <TextField label="out of" fullWidth type="number" {...field} />}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            render={({ field }) => <TextField multiline fullWidth rows={4} label="Description" {...field} />}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
