import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton, TextField, Grow } from "@mui/material";
import { CopyAll } from "@mui/icons-material";
import { useAppDispatch } from "../../../core/redux/hooks";
import { deleteUser } from "../../../core/redux/features/users/userSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  p: 4,
  height: "230px",
  borderRadius: "5px",
};

export default function DeleteModal(props) {
  const [open, setOpen] = React.useState(false);
  const [copiedName, setCopiedName] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useAppDispatch();

  const handleDelete = (e) => {
    e.preventDefault();

    if (copiedName === props.userInfo.name) {
      console.log(props.userInfo);
      dispatch(deleteUser({ email: props.userInfo.email }));
    } else {
      console.log("Wrong Name");
    }
  };

  return (
    <>
      <Typography onClick={handleOpen} color="error" fontSize="14px" zIndex="9">
        Delete
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              pt="4px"
            >
              <Box display={"flex"} gap="4px">
                <Grow in={true} {...{ timeout: 800 }}>
                  <Typography fontSize="17px" fontWeight="600">
                    User name: {props.userInfo.name}
                  </Typography>
                </Grow>
                <Grow in={true} {...{ timeout: 800 }}>
                  <Box mt={-1.1}>
                    <IconButton>
                      <CopyAll />
                    </IconButton>
                  </Box>
                </Grow>
              </Box>
              <Box mt={1.9}>
                <form style={FormStyle} onSubmit={handleDelete}>
                  <TextField
                    label="Сopy and paste the company names here"
                    variant="outlined"
                    value={copiedName}
                    onChange={(e) => setCopiedName(e.target.value)}
                    style={{ width: "430px" }}
                    required
                  />
                  <Button
                    sx={{
                      width: "430px",
                      bgcolor: "red",
                      "&:hover": {
                        background: "#f00",
                      },
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Delete
                  </Button>
                </form>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export const FormStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "22px",
};
