import axios from "axios";
import { API } from "./urls";

export const request = axios.create({
  baseURL: API,
  headers: {
    "Content-Type": "application/json",
  },
});

request.interceptors.request.use((config) => {
  if (typeof localStorage !== "undefined") {
    const userToken = localStorage.getItem("token");
    const token = userToken ? `Bearer ${userToken}` : false;
    if (token && config.headers) {
      config.headers.Authorization = token;
    }
    return config;
  }
  return config;
});
