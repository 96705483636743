import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Paper,
  Typography,
  Stack,
  Button,
  Grid,
  Box,
  ImageListItem,
  ImageList,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import theme from "src/theme/theme";

const ImageDropZone = ({ onDrop, previewImages, setPreviewImages }) => {
  const onDropHandler = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        onDrop(acceptedFiles);
      }

      const previewFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setPreviewImages(previewFiles);
    },
    [onDrop]
  );

  const removeImage = (index) => {
    const updatedPreviews = [...previewImages];
    updatedPreviews.splice(index, 1);
    setPreviewImages(updatedPreviews);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHandler,
    accept: "image/*", // Specify accepted file types
  });

  return (
    <Stack {...getRootProps()} sx={{ cursor: "pointer", maxWidth: "480px" }}>
      <Stack gap="4px">
        <Typography
          // fontWeight={theme.typography.fontWeightMedium}
          variant="h16"
        >
          Logo*
        </Typography>
        <Typography
          // fontWeight={theme.typography.fontWeightRegular}
          sx={{ color: "#6E917E" }}
          variant="h12"
        >
          Minimum image size 72 x 72 pixels
        </Typography>
      </Stack>
      {typeof previewImages === "string" ? (
        <Grid container cols={1} rows={1}>
          <Grid item>
            <Stack gap={1}>
              <Box position={"relative"} width="220px" height="200px">
                <img
                  src={previewImages}
                  alt={`Preview Image`}
                  fill
                  // width={180}
                  // height={180}
                />
              </Box>
              <button>Change Image</button>
            </Stack>
          </Grid>
        </Grid>
      ) : previewImages.length !== 0 ? (
        <Grid container cols={1} rows={1}>
          {previewImages.map((image, index) => (
            <Grid item key={index}>
              <Stack gap={1}>
                <Box position={"relative"} width="220px" height="200px">
                  <img
                    src={image.preview}
                    alt={`Preview ${index}`}
                    fill
                    // width={180}
                    // height={180}
                  />
                </Box>
                <button onClick={() => removeImage(index)}>Remove</button>
              </Stack>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <input {...getInputProps()} />
          <Stack mt={2} spacing={1} alignItems="center">
            <Button
              startIcon={<CloudUploadIcon />}
              variant="contained"
              sx={{ width: "200px", textTransform: "none" }}
            >
              Upload a logo
            </Button>
            <Typography
              // fontWeight={theme.typography.fontWeightRegular}
              sx={{ color: "#6E917E" }}
              variant="h16"
            >
              or drag it in
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default ImageDropZone;
