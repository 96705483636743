/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Stack, Collapse, CircularProgress, Box, Tooltip, Grow, Paper } from "@mui/material";
import { selectUtilsExperience } from "../../../../core/redux/features/utilities/utilitiesSlice";
import { getExperience, postExperience } from "../../../../core/redux/features/utilities/utilitiesThuks";
import { useAppDispatch, useAppSelector } from "../../../../core/redux/hooks";
import IconButtonHidden from "../../../KnowledgeBase/components/IconButtonHidden";
import SourceIcon from "@mui/icons-material/Source";
import React, { useEffect, useState, Suspense } from "react";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import ButtonMenu from "../ButtonMenu";
import AddIcon from "@mui/icons-material/Add";
import FormUtils from "../FormUtils";

const ItemExperience = React.lazy(() => import("./ItemExperience"));

const ContainerExperience = () => {
  const dispatch = useAppDispatch();

  const experience = useAppSelector(selectUtilsExperience);
  const [experienceRow, setExperienceRow] = useState<boolean>(false);
  const [anchorElAdd, setAnchorElAdd] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (experience.statusFetch === "idle" && experienceRow) {
      dispatch(getExperience());
    }
  }, [experienceRow]);

  const onSubmit = (data: { title: string }) => {
    dispatch(postExperience(data));
  };


  return (
    <Grow in={true} timeout={500}>
      <Stack width="100%" p="10px 20px" component={Paper}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="blod" color="#239f5e">
            Experience
          </Typography>

          <Stack spacing={4} direction="row">
            <IconButtonHidden
              icon={
                <>
                  <SourceIcon fontSize="small" />
                  &nbsp;
                  <Typography variant="body2" fontWeight="blod" component="span">
                    {experience.data.length ? experience.data.length : null}
                  </Typography>
                </>
              }
              open={experienceRow}
              setOpen={setExperienceRow}
              title={"Сlick to open the form to change the details of this Category"}
            />
            <ButtonMenu
              colorButton="primary"
              colorButtonBorder="#239f5e"
              iconButton={<AddIcon fontSize="small" />}
              anchorEl={anchorElAdd}
              setAnchorEl={setAnchorElAdd}
            >
              <Stack width={{ xs: 250, md: 400 }} p="20px" spacing={4}>
                <Stack direction="row" width="100%" justifyContent="space-between">
                  <Typography variant="h6">Add Experience</Typography>
                  <Tooltip title="Close">
                    <CloseFullscreenIcon
                      color="warning"
                      onClick={() => setAnchorElAdd(null)}
                      sx={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Stack>
                <FormUtils label="Experience" onSubmit={onSubmit} setOpen={setAnchorElAdd} />
              </Stack>
            </ButtonMenu>
          </Stack>
        </Stack>

        <Collapse in={experienceRow} timeout="auto" unmountOnExit>
          <Suspense
            fallback={
              <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                <CircularProgress sx={{ color: "#0e2944" }} />
              </Box>
            }
          >
            <Stack direction="row" flexWrap="wrap" gap={1} p="20px 0px ">
              {experience.data.length ? (
                experience.data.map((i, idx) => <ItemExperience key={i._id} {...i} idx={idx} />)
              ) : (
                <Stack width="100%" alignItems="center">
                  <CircularProgress sx={{ color: "#0e2944" }} />
                </Stack>
              )}
            </Stack>
          </Suspense>
        </Collapse>
      </Stack>
    </Grow>
  );
};

export default ContainerExperience;
