import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const RHFSelect = ({ name, label, children, onChange, ...other }: any) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          onChange={onChange ? onChange : field.onChange}
          select
          fullWidth
          label={label}
          error={!!error}
          helperText={error?.message}
          value={typeof field.value === "number" && field.value === 0 ? "" : field.value}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
};
export default RHFSelect;
