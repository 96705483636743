import { Stack, IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
  title?: string;
  setOpen: Dispatch<SetStateAction<HTMLElement | null>>;
  onSubmit: Function;
  label: string;
}

const FormUtils: React.FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ title: string }>({ mode: "onSubmit" });

  const onSubmit: SubmitHandler<{ title: string }> = (data) => {
    props.onSubmit(data);
    reset();
    props.setOpen(null);
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} width="100%">
      <FormControl variant="outlined" size="small">
        <InputLabel error={errors.title ? true : false}>
          {errors.title?.message ? errors.title?.message : `Enter ${props.label}`}
        </InputLabel>
        <OutlinedInput
          error={errors.title ? true : false}
          label={errors.title?.message ? errors.title?.message : `Enter ${props.label}`}
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="submit" edge="end" color="primary" sx={{ borderRadius: 0 }}>
                {props.title ? <EditIcon fontSize="small" /> : <AddIcon fontSize="small" />}
              </IconButton>
            </InputAdornment>
          }
          defaultValue={props.title}
          {...register("title", { required: "Title is reqired" })}
          fullWidth
        />
      </FormControl>
    </Stack>
  );
};

export default FormUtils;
