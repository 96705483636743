/* eslint-disable react-hooks/exhaustive-deps */
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
// sections
import {
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../sections/@dashboard/app";
import AppCompanyTimeline from "../sections/@dashboard/app/AppCompanyTimeline";
import { useAppDispatch, useAppSelector } from "../core/redux/hooks";
import {
  selectJobCategoriesStats,
  selectJobsByCompanies,
  selectStatsDashboard1,
  selectStatsDashboard2,
  selectJobTypeApplyStats,
  selectUserLocationStats,
  selectJobTypeViewStats,
} from "../core/redux/features/stats/statsSlice";
import { useCallback, useEffect } from "react";
import {
  fetchDashboard1Stats,
  fetchDashboard2Stats,
  fetchJobByCompaniesStats,
  fetchJobCategoriesStats,
  fetchUserLocationStats,
  fetchApplyByJobTypeStats,
  fetchYearlyUserStats,
  fetchViewStatsByCountryAndJobType,
} from "../core/redux/features/stats/statsThunks";
import { selectAuthStatus } from "../core/redux/features/auth/authSlice";
import { fetchNewJobs } from "../core/redux/features/jobs/jobThunks";
import { selectNewJobs } from "../core/redux/features/jobs/jobSlice";
import { selectNewCompanies } from "../core/redux/features/companies/companiesSlice";
import { ApexChart } from "../sections/@dashboard/company/CompanyStatsChart";
import { useQuery } from "@tanstack/react-query";
import { fetchNewUsers } from "../core/react-query/newUsers.api";
import { fDate } from "../utils/formatTime";
import { fetchNewCompanies } from "../core/redux/features/companies/companyThunks";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const dashboard1 = useAppSelector(selectStatsDashboard1);
  const dashboard2 = useAppSelector(selectStatsDashboard2);
  const authStatus = useAppSelector(selectAuthStatus);
  const jobCategorystats = useAppSelector(selectJobCategoriesStats);
  const userLocationsStats = useAppSelector(selectUserLocationStats);
  const jobTypeApplyStats = useAppSelector(selectJobTypeApplyStats);
  const jobTypeViewStats = useAppSelector(selectJobTypeViewStats);

  const jobsByCompanies = useAppSelector(selectJobsByCompanies);
  const newJobs = useAppSelector(selectNewJobs);
  const newCompanies = useAppSelector(selectNewCompanies);
  const newUsers = useQuery(["users-query"], () => fetchNewUsers(0, 5));

  const handleFetchStats = useCallback(async () => {
    dispatch(fetchDashboard1Stats());
    dispatch(fetchDashboard2Stats());
    dispatch(fetchYearlyUserStats());
    dispatch(fetchJobCategoriesStats());
    dispatch(fetchUserLocationStats("30-days"));
    dispatch(fetchJobByCompaniesStats());
    dispatch(fetchApplyByJobTypeStats());
    dispatch(
      fetchViewStatsByCountryAndJobType({
        timePeriod: "30-days",
        category: "62490bfff9c26249edf80664",
      })
    );
    dispatch(fetchNewJobs());
    dispatch(fetchNewCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (authStatus === "FETCH_SUCCESS" || authStatus === "LOGIN_SUCCESS") {
      handleFetchStats();
    }
  }, [handleFetchStats, authStatus]);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Dashboard Overview
        </Typography>

        <Grid container spacing={3} mb={4}>
          <Grid item xs={12}>
            <Typography variant="caption">
              Last updated:{" "}
              {dashboard1.updatedAt
                ? fDate(dashboard1.updatedAt)
                : "not available at the moment"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="USERS"
              total={dashboard1.totalUsers}
              icon={"gis:globe-users"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="AVAILABLE JOBS"
              total={dashboard1.totalJobs}
              icon={"eos-icons:job"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="COMPANIES"
              total={dashboard1.totalCompanies}
              icon={"carbon:location-company-filled"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="VERIFIED USERS"
              total={dashboard1.verifiedUsers}
              icon={"clarity:users-solid-badged"}
            />
          </Grid>

          {/* Block With Percent */}

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="DAILY SIGN UPS"
              total={dashboard2.signUps.daily.current}
              percent={dashboard2.signUps.daily.change}
              color={
                dashboard2.signUps.daily.current < dashboard2.signUps.daily.last
                  ? "error"
                  : "success"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="WEEKLY SIGN UPS"
              total={dashboard2.signUps.weekly.current}
              percent={dashboard2.signUps.weekly.change}
              color={
                dashboard2.signUps.weekly.current <
                dashboard2.signUps.weekly.last
                  ? "error"
                  : "success"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="MONTHLY SIGN UPS"
              total={dashboard2.signUps.monthly.current}
              percent={dashboard2.signUps.monthly.change}
              color={
                dashboard2.signUps.monthly.current <
                dashboard2.signUps.monthly.last
                  ? "error"
                  : "success"
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="DAILY ACTIVE USER"
              total={dashboard2.logins.daily.current}
              percent={dashboard2.logins.daily.change}
              color={
                dashboard2.logins.daily.current < dashboard2.logins.daily.last
                  ? "error"
                  : "success"
              }
            />
          </Grid>

          <Grid item xs={12}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppCurrentVisits
              title="Jobs by Category"
              chartData={jobCategorystats.map((stat) => ({
                label: stat.category,
                value: stat.numbOfJobs,
              }))}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppConversionRates
              title="Users by countries"
              subheader="Top 10 countries by user"
              normalHeight
              fetchData={fetchUserLocationStats}
              periods
              // defaultProjection={'all'}
              chartData={userLocationsStats.totalUsers
                .map((stat) => ({
                  label: stat._id,
                  value: stat.count,
                }))
                .slice(0, 10)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppConversionRates
              title="Countries with the most number of jobs"
              subheader="10 countries with the most number of jobs"
              fetchData={fetchUserLocationStats}
              periods
              // defaultProjection={'all'}
              normalHeight
              chartData={userLocationsStats.totalJobs
                .slice(0, 10)
                .map((stat) => ({ label: stat._id, value: stat.count }))}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppConversionRates
              periods
              normalHeight
              fetchData={fetchApplyByJobTypeStats}
              title={`Applications by job type`}
              subheader="Job types with their application rates"
              chartData={jobTypeApplyStats.map((stat) => ({
                label: stat.jobtype,
                value: stat.count,
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AppCurrentSubject
              title="Jobs by Companies"
              chartLabels={jobsByCompanies
                .map((stat) => stat.company)
                .slice(0, 8)}
              chartData={[
                {
                  name: "Series 1",
                  data: jobsByCompanies
                    .map((stat) => stat.numbOfJobs)
                    .slice(0, 8),
                },
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <ApexChart title={""} stats={jobTypeViewStats} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="New Users"
              list={
                newUsers.isLoading
                  ? []
                  : newUsers.data.body.data.map((user, index) => ({
                      _id: user._id,
                      name: `${user.name} ${user.secondName}`,
                      country: `${user.country} ${
                        user.city ? `, ${user.city}` : ""
                      }`,
                      avatar: user.avatarUrl,
                      lastLogin: user.lastLogin,
                      isVerrified: user.isVerrified,
                    }))
              }
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="New Jobs"
              list={newJobs.map((job, index) => ({
                id: job._id,
                title: job.title,
                type: `order${index + 1}`,
                time: job.createdAt,
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <AppCompanyTimeline title="New Companies" list={newCompanies} />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: "FaceBook",
                  value: 323234,
                  icon: <Iconify icon={"eva:facebook-fill"} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: "Google",
                  value: 341212,
                  icon: <Iconify icon={"eva:google-fill"} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: "Linkedin",
                  value: 411213,
                  icon: <Iconify icon={"eva:linkedin-fill"} color="#006097" width={32} height={32} />,
                },
                {
                  name: "Twitter",
                  value: 443232,
                  icon: <Iconify icon={"eva:twitter-fill"} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: "1", label: "Create FireStone Logo" },
                { id: "2", label: "Add SCSS and JS files if required" },
                { id: "3", label: "Stakeholder Meeting" },
                { id: "4", label: "Scoping & Estimations" },
                { id: "5", label: "Sprint Showcase" },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
