import { request } from "../../utils/Axios";
import { User } from "../types/auth.types";

export const fetchNewUsers = async (
  skip: number,
  limit: number
): Promise<{ body: { data: Array<User>; count: number } }> => {
  const { data } = await request.get(`/api/v1/user?skip=${skip}&limit=${limit}`);
  return data;
};
