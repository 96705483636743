import {
  Button,
  Modal,
  Stack,
  Typography,
  Input,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../../core/redux/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteJobsByCompany } from "../../../core/redux/features/jobs/jobThunks";

type Props = {
  name: string;
  id: string;
};

const ClearJobs = (props: Props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    dispatch(deleteJobsByCompany(props.id));
    console.log(props.id);
    handleClose();
  };

  return (
    <>
      <Button onClick={handleOpen} color="error" variant="contained">
        Clear Jobs
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Stack
          p="20px"
          borderRadius={2}
          width={350}
          textAlign="center"
          spacing={2}
          sx={style}
        >
          <Typography variant="h6" lineHeight={1} color="GrayText">
            To delete all jobs, enter the name of the company
          </Typography>
          <Typography variant="h6" color="InfoText">
            {props.name}
          </Typography>
          <Stack>
            <Input
              placeholder="change name company"
              value={values}
              onChange={(e) => setValues(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={values === props.name ? false : true}
                    onClick={handleDelete}
                  >
                    <DeleteIcon
                      color={values === props.name ? "error" : "action"}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default ClearJobs;
