// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "user",
    path: "/dashboard/user",
    icon: getIcon("eva:people-fill"),
  },
  {
    title: "Job Center",
    path: "/dashboard/job-center",
    icon: getIcon("eos-icons:job"),
  },
  {
    title: "company",
    path: "/dashboard/company",
    icon: getIcon("carbon:location-company-filled"),
  },
  {
    title: "analytics",
    path: "/dashboard/analytics",
    icon: getIcon("mdi:bar-chart"),
  },
  // {
  //   title: "manager",
  //   path: "/dashboard/blog",
  //   icon: getIcon("eva:file-text-fill"),
  // },
  {
    title: "partner offers",
    path: "/dashboard/payment",
    icon: getIcon("material-symbols:payments-outline-rounded"),
  },
  {
    title: "knowledge base",
    path: "/dashboard/knowledge",
    icon: getIcon("carbon:share-knowledge"),
  },

  {
    title: "Utils",
    path: "/dashboard/utils",
    icon: getIcon("grommet-icons:vm-maintenance"),
  },

  {
    title: "location",
    path: "/dashboard/location",
    icon: getIcon("carbon:location-filled"),
  },
];

export default navConfig;
