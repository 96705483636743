import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
// import { CompanyData } from "../../../core/types/company.types";
import PropTypes from "prop-types";
import { fDateTime } from "../../../utils/formatTime";

AppCompanyTimeline.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppCompanyTimeline({
  list,
  title,
  subheader,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent
        sx={{
          "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
          },
        }}
      >
        <Stack gap={2}>
          {list.map((item) => (
            <Stack key={item._id} gap={2} direction="row" alignItems="center">
              <Box sx={{width: '72px'}}>
              <img alt="Company Logo" src={item.logo} />
              </Box>

              <Stack>
                <Typography variant="h3" sx={{ color: "text.secondary" }}>
                  {item.name}
                </Typography>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  {fDateTime(item.createdAt)}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};
