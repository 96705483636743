import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../../../utils/Axios";
import { CategorieFaqType, FaqType } from "../../../types/knowlegeBade";

export const getCategoryFaq = createAsyncThunk(
  "faq/getCategoryFaq",
  async (): Promise<{ body: CategorieFaqType[] }> => {
    const res = await request.get("/api/v1/faq/category");

    return res.data;
  }
);

export const postCategoryFaq = createAsyncThunk(
  "faq/postCategoryFaq",
  async (title: string): Promise<{ body: CategorieFaqType }> => {
    const res = await request.post("/api/v1/faq/category/new", { title });

    return res.data;
  }
);

export const patchCategoryFaq = createAsyncThunk(
  "faq/patchCategoryFaq",
  async ({ title, id }: { title: string; id: string }): Promise<{ body: CategorieFaqType }> => {
    const res = await request.patch(`/api/v1/faq/category/${id}`, { title });

    return res.data;
  }
);

export const deleteCategoryFaq = createAsyncThunk(
  "faq/deleteCategoryFaq",
  async (id: string): Promise<{ body: string }> => {
    const res = await request.delete(`/api/v1/faq/category/${id}`);

    return res.data;
  }
);

// Faq
export const postFaq = createAsyncThunk(
  "faq/postFaq",
  async (data: FaqPostType): Promise<{ body: FaqType; meta: FaqPostType }> => {
    const res = await request.post("/api/v1/faq", data);

    return res.data;
  }
);

export const patchFaq = createAsyncThunk(
  "faq/patchFaq",
  async ({ data, id }: { data: FaqPostType; id: string }): Promise<{ body: FaqType }> => {
    const res = await request.patch(`/api/v1/faq/item/${id}`, data);

    return res.data;
  }
);

export const deleteFaq = createAsyncThunk("faq/deleteFaq", async (id: string): Promise<{ body: string }> => {
  const res = await request.delete(`/api/v1/faq/item/${id}`);

  return res.data;
});

export interface FaqPostType {
  question: string;
  answer: string;
  category: string;
  isPublic: boolean;
}
