import { Container, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import { StatByJobTypeObject } from "../../core/types/stats.types";
import {
  CompanyStatsDatesChart,
  CompanyStatsCountyChart,
  CompanyStatsJobClicksByCountyChart,
  CompanyStatsForSpecificCategoryChart,
} from "../../sections/@dashboard/company/CompanyStatsChart";
import { request } from "../../utils/Axios";

export interface CompanyStaticsType {
  dates: { _id: string; count: number }[];
  events: { _id: string; count: number }[];
  sources: { _id: string; count: number }[];
  users: { _id: string; count: number }[];
  applicationsByCountry: { _id: string; country: string; count: number }[];
  applicationsForSpecificCategoryByCountry: StatByJobTypeObject[];
}

export default function AnalyticsSlug() {
  const { slug } = useParams();
  const [companyId, setCompanyId] = useState<string>("");

  const handleFetchCompanyJobs = async () => {
    const { data } = await request.get(`api/v1/company/slug/${slug}`);
    setCompanyId(data.body._id);
  };

  useEffect(() => {
    if (slug) {
      handleFetchCompanyJobs();
    }
  }, [slug]);

  return (
    <Page title="Job Center">
      <Container maxWidth="xl">
        {/* <ProductList /> */}

        <Stack spacing={3} mb={3}>
          <CompanyStatsDatesChart
            tagName="Events by date"
            title="Events by date"
            companyId={companyId}
          />
          <CompanyStatsCountyChart
            title="User Events by Country"
            companyId={companyId}
          />

          <CompanyStatsJobClicksByCountyChart
            title="Job Clicks by Country"
            companyId={companyId}
          />

          <CompanyStatsForSpecificCategoryChart
            title="Clicks on specific group by Country"
            companyId={companyId}
          />
        </Stack>
      </Container>
    </Page>
  );
}
