import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box, Select, MenuItem } from "@mui/material";
// components
import { BaseOptionChart } from "../../../components/chart";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../core/redux/hooks";
import { selectUserApplyStats } from "../../../core/redux/features/stats/statsSlice";
// import { removeDuplicates } from "../../../utils/removeDuplicates";

// ----------------------------------------------------------------------

export default function ClicksOnApplyNowByCountryChart() {
  const userApplyStats = useAppSelector(selectUserApplyStats);
  const countries = userApplyStats.map((i) => i.country).sort();

  const [projection, setProjection] = useState("yearly");
  const [activeCountry, setActiveCountry] = useState<string[]>(
    userApplyStats.map((i) => i.country)
  );
  const [chartLabelsState, setChartLabelsState] = useState<any>();
  const [chartDataState, setChartDataState] = useState<any>();
  const [percentageDifference, setPercentageDifference] = useState([0]);

  const countryData = userApplyStats.filter((i) =>
    activeCountry.includes(i.country)
  );

  const sortFunc = (a: string | number | Date, b: string | number | Date) => {
    let dateA = new Date(a).getTime();
    let dateB = new Date(b).getTime();

    return dateA - dateB;
  };

  useEffect(() => {
    if (userApplyStats.length !== 0) {
      setActiveCountry([userApplyStats[0].country]);
    }
  }, [userApplyStats]);

  useEffect(() => {
    if (projection === "yearly") {
      const labels: any[] = [];
      const data = countryData.map((selectedCountry) => {
        labels.push(...selectedCountry.data.map((i) => i.date).sort(sortFunc));

        return {
          name: selectedCountry.country,
          type: "area",
          fill: "gradient",
          data: selectedCountry.data.map((i) => i.count),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    } else if (projection === "90-days") {
      const labels: string[] = [];
      const data = countryData.map((selectedCountry) => {
        labels.push(
          ...selectedCountry.data
            .map((i) => i.date)
            .sort(sortFunc)
            .slice(-90)
        );
        return {
          name: selectedCountry.country,
          type: "area",
          fill: "gradient",
          data: selectedCountry.data.map((i) => i.count).slice(-90),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    } else if (projection === "30-days") {
      const labels: string[] = [];
      const data = countryData.map((selectedCountry) => {
        labels.push(
          ...selectedCountry.data
            .map((i) => i.date)
            .sort(sortFunc)
            .slice(-30)
        );
        return {
          name: selectedCountry.country,
          type: "area",
          fill: "gradient",
          data: selectedCountry.data.map((i) => i.count).slice(-30),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    } else if (projection === "14-days") {
      const labels: string[] = [];
      const data = countryData.map((selectedCountry) => {
        labels.push(
          ...selectedCountry.data
            .map((i) => i.date)
            .sort(sortFunc)
            .slice(-14)
        );
        return {
          name: selectedCountry.country,
          type: "area",
          fill: "gradient",
          data: selectedCountry.data.map((i) => i.count).slice(-14),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    } else if (projection === "7-days") {
      const labels: string[] = [];
      const data = countryData.map((selectedCountry) => {
        labels.push(
          ...selectedCountry.data
            .map((i) => i.date)
            .sort(sortFunc)
            .slice(-7)
        );
        return {
          name: selectedCountry.country,
          type: "area",
          fill: "gradient",
          data: selectedCountry.data.map((i) => i.count).slice(-7),
        };
      });
      setChartLabelsState(labels);
      setChartDataState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projection, activeCountry]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-based in JavaScript
    const lastMonth = currentDate.getMonth(); // Months are 0-based in JavaScript
    const currentYear = currentDate.getFullYear();

    const currentMonthItems = countryData.map((item) =>
      item.data.filter((item) => {
        const itemDate = new Date(item.date);
        return (
          itemDate.getMonth() + 1 === currentMonth &&
          itemDate.getFullYear() === currentYear
        );
      })
    );

    const lastMonthItems = countryData.map((item) =>
      item.data.filter((item) => {
        const itemDate = new Date(item.date);
        return (
          itemDate.getMonth() + 1 === lastMonth &&
          itemDate.getFullYear() === currentYear
        );
      })
    );

    const currentReducedCountValue = currentMonthItems.map((item) =>
      item.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
      }, 0)
    );

    const lastReducedCountValue = lastMonthItems.map((item) =>
      item.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
      }, 0)
    );

    const reducedPercentageDifference = [];

    for (let index = 0; index < activeCountry.length; index++) {
      const current = currentReducedCountValue[index];
      const last = lastReducedCountValue[index];

      const result = ((current - last) / last) * 100;
      reducedPercentageDifference.push(result);
    }

    setPercentageDifference(reducedPercentageDifference);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCountry]);

  const chartOptions: any = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartDataState?.map((i: any) => i.fill) },
    labels: chartLabelsState,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} users`;
          }
          return y;
        },
      },
    },
  });

  const handleProjection = (e: any) => {
    setProjection(e.target.value);
  };

  const handleActiveCountry = (e: any) => {
    setActiveCountry(e.target.value);
  };

  return (
    <Card>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        padding="10px"
      >
        {percentageDifference.length === 1 && (
          <CardHeader
            subheader={`(${percentageDifference[0]}%) since last month `}
            title={`Clicks on "Apply Now" By Country`}
          />
        )}

        {percentageDifference.length > 1 && (
          <CardHeader
            subheader={`(${activeCountry.map((item, idx) => {
              return `${item} : ${percentageDifference[idx]}%`;
            })}) since last month `}
            title={`Clicks on "Apply Now" By Country`}
          />
        )}

        <Stack direction="row" gap="12px">
          <Select value={activeCountry} multiple onChange={handleActiveCountry}>
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
          <Select value={projection} onChange={handleProjection}>
            <MenuItem value={"yearly"}>In months</MenuItem>
            <MenuItem value={"90-days"}>last 90 days</MenuItem>
            <MenuItem value={"30-days"}>last 30 days</MenuItem>
            <MenuItem value={"14-days"}>last 14 days</MenuItem>
            <MenuItem value={"7-days"}>last 7 days</MenuItem>
          </Select>
        </Stack>
      </Stack>

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {chartDataState && (
          <ReactApexChart
            type="line"
            series={chartDataState}
            options={chartOptions}
            height={364}
            width={"100%"}
          />
        )}
      </Box>
    </Card>
  );
}

// const dummyData = [
//   {
//     _id: "France",
//     country: "France",
//     data: [
//       {
//         count: 2,
//         date: "08/23/2023",
//       },
//       {
//         count: 10,
//         date: "09/23/2023",
//       },
//       {
//         count: 1,
//         date: "10/06/2023",
//       },
//       {
//         count: 14,
//         date: "11/06/2023",
//       },
//       {
//         count: 7,
//         date: "11/07/2023",
//       },
//     ],
//   },
//   {
//     _id: "Germany",
//     country: "Germany",
//     data: [
//       {
//         count: 8,
//         date: "08/20/2023",
//       },
//       {
//         count: 6,
//         date: "09/20/2023",
//       },
//       {
//         count: 4,
//         date: "10/06/2023",
//       },
//       {
//         count: 14,
//         date: "11/06/2023",
//       },
//       {
//         count: 21,
//         date: "11/07/2023",
//       },
//     ],
//   },
// ];
