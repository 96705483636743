import { Box, Button, CircularProgress } from "@mui/material";
import { JobData } from "../core/types/job.types";
import { JobList } from "../sections/@dashboard/products";
import { fetchJobsByCompany } from "../core/redux/features/jobs/jobThunks";
import { useFetchMore } from "../hooks/useFetchMore";

type Props = {
  jobs: JobData[];
  slug: string;
  jobsStatus: string;
};

const PAGE_SIZE = 12;

const ViewCompanyJobs = (props: Props) => {
  const { handleFetchMore } = useFetchMore({
    limit: PAGE_SIZE,
    skipConstant: PAGE_SIZE,
    fetchAction: fetchJobsByCompany,
    slug: props.slug,
    isEmpty: props.jobs.length > 0,
  });

  return (
    <Box>
      <JobList jobs={props.jobs} />

      {props.jobsStatus === "loading" ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : null}

      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"center"}
        height="70px"
      >
        <Button
          variant="contained"
          onClick={handleFetchMore}
          sx={{ textTransform: "none" }}
        >
          More
        </Button>
      </Box>
    </Box>
  );
};

export default ViewCompanyJobs;
