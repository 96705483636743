/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Typography,
  Avatar,
  IconButton,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Box,
  TextField,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  fetchAllMessages,
  messagesUnread,
} from "../core/redux/features/messeages/messeagesSlice";
import {
  deleteMessages,
  fetchMessages,
  fetchMessagesUnread,
  messageType,
  patchMessages,
} from "../core/redux/features/messeages/messeagesThunks";
import { useAppDispatch, useAppSelector } from "../core/redux/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import { DELETE_STATUS, PATCH_STATUS } from "../core/types/knowlegeBade";
import InfiniteScroll from "react-infinite-scroll-component";
import copy from "copy-to-clipboard";
import ButtonMenu from "./Utilities/components/ButtonMenu";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import TelegramIcon from "@mui/icons-material/Telegram";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { request } from "../utils/Axios";
import { toastSuccess } from "../components/CustomToastify";
import moment from "moment";

const Messega = () => {
  const dispatch = useAppDispatch();
  const messageUnread = useAppSelector(messagesUnread);
  const message = useAppSelector(fetchAllMessages);

  const [messageOpen, setMessageOpen] = useState<messageType | null>(null);

  useEffect(() => {
    if (message.statusPatch === "idle")
      dispatch(fetchMessages({ limit: 12, skip: message.data.length }));
  }, []);

  const handleReadMessage = (id: string) => {
    const messagePreview = [...message.data, ...messageUnread.data].filter(
      (i) => i._id === id
    )[0];
    setMessageOpen(messagePreview);
    if (!messagePreview.isRead) {
      dispatch(patchMessages([id]));
    }
  };
  const handleDeletedMessage = (id: string) => {
    dispatch(deleteMessages(id));
    setMessageOpen(null);
  };

  const FetchMoreAllMessage = () => {
    if (messageUnread.data.length < messageUnread.count) {
      dispatch(
        fetchMessagesUnread({ limit: 12, skip: messageUnread.data.length })
      );
    } else if (message.data.length < message.count) {
      dispatch(fetchMessages({ limit: 12, skip: message.data.length }));
    }
  };

  const [dataSendMessage, setDataSendMessage] = useState<{
    applicantId: string;
    subject: string;
    message: string;
  }>({
    applicantId: "",
    subject: "",
    message: "",
  });
  const onSubmitMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (messageOpen?._id) {
      await request.post("/api/v1/email/reply", {
        ...dataSendMessage,
        applicantId: messageOpen?._id,
      });
      setDataSendMessage({ applicantId: "", subject: "", message: "" });
      toastSuccess("messages sent 👌");
    }
  };

  return (
    <Stack
      spacing={2}
      direction="row"
      maxWidth="xl"
      height="calc(100% + 25px)"
      width="100%"
      mt="-25px"
    >
      <InfiniteScroll
        dataLength={messageUnread.data.length + message.data.length}
        next={FetchMoreAllMessage}
        hasMore={
          messageUnread.data.length + message.data.length <
          message.count + messageUnread.count
        }
        loader={null}
        scrollableTarget="scrollableDiv"
        style={{ width: "100%", overflow: "hidden" }}
      >
        <Stack
          height={{ xs: "calc(100vh - 65px)", lg: "calc(100vh - 91px)" }}
          className="MessageNavbar"
          id="scrollableDiv"
        >
          {messageUnread.data.map((i) => (
            <MessageItem
              key={i._id}
              {...i}
              handleReadMessage={handleReadMessage}
              handleDeletedMessage={handleDeletedMessage}
              statusPatch={message.statusPatch}
              statusDelete={message.statusDelete}
            />
          ))}
          {message.data.map((i) => (
            <MessageItem
              key={i._id}
              {...i}
              handleReadMessage={handleReadMessage}
              handleDeletedMessage={handleDeletedMessage}
              statusPatch={message.statusPatch}
              statusDelete={message.statusDelete}
            />
          ))}
        </Stack>
      </InfiniteScroll>

      <Stack
        justifyContent="space-between"
        width="100%"
        height={{ xs: "calc(100vh - 65px)", lg: "calc(100vh - 91px)" }}
        p="20px"
      >
        <Stack spacing="10px" alignItems="flex-start" width="100%">
          <Stack
            direction="row"
            borderBottom="1px solid gray"
            width="100%"
            justifyContent="space-between"
          >
            <Stack spacing={4} direction="row">
              <Typography variant="body2" fontWeight={800}>
                All messages: {message.count}
              </Typography>
              <Typography variant="body2" fontWeight={800}>
                Unread messages: {messageUnread.count}
              </Typography>
            </Stack>
            <Tooltip
              title={`double-click to mark ${
                messageUnread.data.filter((i) => !i.isRead).length +
                message.data.filter((i) => !i.isRead).length
              } messages as read`}
            >
              <IconButton
                disabled={
                  messageUnread.data.filter((i) => !i.isRead).length +
                  message.data.filter((i) => !i.isRead).length
                    ? false
                    : true
                }
                color="success"
                sx={{ borderRadius: 0 }}
                onClick={() =>
                  dispatch(
                    patchMessages(
                      [
                        ...messageUnread.data.filter((i) => !i.isRead),
                        ...message.data.filter((i) => !i.isRead),
                      ].map((i) => i._id)
                    )
                  )
                }
              >
                <Typography variant="subtitle2">
                  Mark as read{" "}
                  {messageUnread.data.filter((i) => !i.isRead).length +
                    message.data.filter((i) => !i.isRead).length}
                </Typography>
                {"  "}
                <DoneAllIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack
            direction="row"
            borderBottom="1px solid gray"
            width="100%"
            pb="10px"
            justifyContent="space-between"
          >
            <Typography variant="h5" color="initial" fontWeight={800}>
              {messageOpen?.name || "No messages selected"}{" "}
              {messageOpen?.secondName}
            </Typography>
            <Typography variant="h6" color="initial" fontWeight={600}>
              {messageOpen?.subject}
            </Typography>
            <Typography variant="h6" color="initial" fontWeight={600}>
              {moment(messageOpen?.createdAt).format("MMM Do YYYY")}
            </Typography>
          </Stack>
          <Box bgcolor="#fdf1e2" borderRadius={2} p={2}>
            <Typography variant="body1" color="initial">
              {messageOpen?.message || "There was no message"}
            </Typography>
          </Box>
        </Stack>

        <Stack
          spacing={2}
          width="100%"
          component="form"
          onSubmit={(e) => onSubmitMessage(e)}
        >
          {/* <Stack direction="row" spacing={2}> */}
          {/* <TextField
              fullWidth
              label="Subject"
              size="small"
              required
              onChange={(e) => setDataSendMessage({ ...dataSendMessage, subject: e.target.value })}
              value={dataSendMessage.subject}
            /> */}
          {/* <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
            >
              <Typography variant="subtitle2">Send</Typography>
              <TelegramIcon fontSize="small" />
            </Button> */}
          {/* </Stack> */}
          <TextField
            required
            label="Message"
            multiline
            rows={5}
            size="small"
            fullWidth
            onChange={(e) =>
              setDataSendMessage({
                ...dataSendMessage,
                message: e.target.value,
              })
            }
            value={dataSendMessage.message}
          />
          <Button
            size="small"
            variant="contained"
            type="submit"
            color="secondary"
          >
            <Typography variant="subtitle2">Send</Typography>
            <TelegramIcon fontSize="small" />
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Messega;

interface MessageItemProps extends Partial<messageType> {
  handleReadMessage: (id: string) => void;
  handleDeletedMessage: (id: string) => void;
  statusPatch: "idle" | PATCH_STATUS;
  statusDelete: "idle" | DELETE_STATUS;
}

const MessageItem = (props: MessageItemProps) => {
  const [anchorElDelete, setAnchorElDelete] = useState<null | HTMLElement>(
    null
  );
  const [delText, setDelText] = useState<string>("");

  const onCloseDel = () => {
    setAnchorElDelete(null);
    setDelText("");
  };
  const deleteFunction = () => {
    props.handleDeletedMessage(String(props._id));
    onCloseDel();
  };

  return (
    <Stack
      p="15px 5px 15px 2px"
      boxShadow={"1px 1px 3px 2px #cecece"}
      borderBottom={props.isRead ? "4px solid gray" : "4px solid #3baa70"}
      borderLeft="1px solid #cecece"
      borderRadius="0px 10px 10px 0px"
      zIndex={1}
      spacing={1}
    >
      <Stack justifyContent="space-between" direction="row">
        <Stack
          direction="row"
          onClick={() => props.handleReadMessage(String(props._id))}
          sx={{ cursor: "pointer" }}
          width="100%"
        >
          <Avatar sx={{ bgcolor: "background.neutral" }}>
            <img alt={props._id} src="/static/icons/ic_notification_chat.svg" />
          </Avatar>

          <Stack spacing={-0.2}>
            <Typography variant="subtitle1">
              {props.name} {props.secondName}
            </Typography>
            <Typography variant="body2">
              {props.email && props.email.length > 27
                ? `${props.email.slice(0, 27)}...`
                : props.email}
            </Typography>
            <Typography variant="body2">
              {props.createdAt && moment(props.createdAt).format("MMM Do YYYY")}
            </Typography>
          </Stack>
        </Stack>

        <ButtonMenu
          colorButton="error"
          colorButtonBorder="red"
          iconButton={<DeleteIcon fontSize="small" sx={{ zIndex: 3 }} />}
          anchorEl={anchorElDelete}
          setAnchorEl={setAnchorElDelete}
        >
          <Stack width={{ xs: 250, md: 400 }} p="20px">
            <Stack direction="row" width="100%" justifyContent="space-between">
              <div />
              <Tooltip title="Close">
                <CloseFullscreenIcon
                  color="warning"
                  onClick={onCloseDel}
                  sx={{ cursor: "pointer" }}
                />
              </Tooltip>
            </Stack>

            <Stack spacing={4}>
              <Typography variant="h4" color="error" textAlign="center">
                Are you serious
              </Typography>
              <Stack>
                <Typography variant="body1">
                  are you sure you want to delete the
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Typography variant="h6">{props.name}</Typography>
                  <IconButton onClick={() => copy(String(props.name))}>
                    <CopyAllIcon color="success" />
                  </IconButton>
                </Stack>
              </Stack>

              <FormControl variant="outlined" size="small">
                <InputLabel>Write the name</InputLabel>
                <OutlinedInput
                  label="Write the name"
                  onChange={(e) => setDelText(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={deleteFunction}
                        edge="end"
                        color="error"
                        disabled={delText === props.name ? false : true}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Stack>
          </Stack>
        </ButtonMenu>
      </Stack>
      <Typography fontSize="12px" p="0px 0px 0px 10px">
        {props.message && props.message.length > 120
          ? `${props.message?.slice(0, 120)}...`
          : props.message}
      </Typography>
    </Stack>
  );
};
