// @mui
import PropTypes from "prop-types";
import { Box, Stack, Card, Divider, Typography, CardHeader, Avatar } from "@mui/material";
import { Link } from "react-router-dom";

// utils
import { fToNow } from "../../../utils/formatTime";
// components
import Scrollbar from "../../../components/Scrollbar";

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {list.map((user) => (
            <NewsItem key={user._id} user={user} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: "right" }}>
        <Link
          to={`/dashboard/user`}
          variant="subtitle2"
          underline="hover"
          style={{ textDecoration: "none", color: "rgb(35, 159, 94)" }}
        >
          View all
        </Link>
      </Box>
    </Card>
  );
}

//
// ----------------------------------------------------------------------

NewsItem.propTypes = {
  user: PropTypes.shape({
    country: PropTypes.string,
    avatar: PropTypes.string,
    lastLogin: PropTypes.number,
    name: PropTypes.string,
    _id: PropTypes.string,
    isVerrified: PropTypes.bool,
  }),
};

function NewsItem({ user, id }) {
  const { country, name, avatar, lastLogin, isVerrified } = user;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar alt={name} src={avatar} sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0 }} />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link to={`/dashboard/user/${id}`} variant="subtitle2" underline="hover" style={titleStyle}>
          {name}
        </Link>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {country}
        </Typography>
      </Box>

      <Box width="78px" height="26px" borderRadius="5px" bgcolor={isVerrified ? "#7ad05a" : "red"}>
        <Typography textAlign="center" color="#fff" fontSize="14px" pt="2px">
          {isVerrified ? "verified" : "unverified"}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: "text.secondary" }}>
        {lastLogin ? fToNow(lastLogin) : "never"}
      </Typography>
    </Stack>
  );
}

export const titleStyle = {
  textDecoration: "none",
  color: "#212B36",
  fontWeight: "500",
};
